<template>
  <div ref="footer">
    <component :is="currentTicketType.component" :isA="isA" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BetslipFooterCombo from './BetslipFooterCombo';
import BetslipFooterSingle from './BetslipFooterSingle';

export default {
  name: 'BetslipFooter',
  props: {
    isA: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BetslipFooterCombo,
    BetslipFooterSingle,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketType']),
    ticketTypes() {
      return [
        {
          value: 'single',
          component: BetslipFooterSingle,
        },
        {
          value: 'combo',
          component: BetslipFooterCombo,
        },
      ];
    },
    currentTicketType() {
      return this.ticketTypes.find(ticketType => ticketType.value === this.activeTicketType.value);
    },
  },
  methods: {
    setFooterHeightToBody() {
      this.$nextTick(() => document.body.style.setProperty('--footer-height', `${this.$refs.footer?.clientHeight}px`));
    },
  },
  updated() {
    this.setFooterHeightToBody();
  },
  mounted() {
    this.setFooterHeightToBody();
  },
};
</script>
