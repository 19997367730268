<template>
  <button v-interactionStates
          @click="onClick"
          @contextmenu="handleContextMenu($event)"
          class="button"
          :class="[variation, { active: active }, typeClass]"
          :style="setPropColor"
          :disabled="disabled">{{ label }}</button>
</template>

<script>
import { interactionStates } from '../directives';
import { typeable } from '../mixins';
import Button from './Button';
import convert from '../utility/convert';

export default {
  name: 'BingoGridButton',
  extends: Button,
  mixins: [typeable],
  directives: {
    interactionStates,
  },
  props: {
    color: {
      type: String,
      default: '#3f4144',
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: 'square',
    },
  },
  computed: {
    setPropColor() {
      return {
        '--square-color-active': convert.hexToRgba(this.color, 100),
        '--circle-color-active': convert.hexToRgba(this.color, 30), // TODO: Change opacity to use CSS variable
        '--circle-border-color-active': convert.hexToRgba(this.color, 100),
      };
    },
  },
  methods: {
    onClick() {
      this.$emit('active');
    },
    handleContextMenu(e) {
      e.preventDefault();
      this.onClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 40px;
  height: 40px;
  font-size: 16px;
  background-color: var(--button);
  color: var(--text-primary-1);
  border: 2px solid var(--button);
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Roboto, 'sans-serif';

  &.square {
    width: 100%;
    font-size: 14px;
    color: var(--text-primary-1);
    border-radius: 2px;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::after {
      background-color: var(--button);
    }

    &.active {
      background-color: var(--primary);
      transition: all 0.3s ease;
      color: var(--custom-text-color, var(--text-primary-1));

      &::after {
        background-color: var(--primary);
      }
    }

    &:active {
      background-color: var(--active-state);
    }

    &:disabled {
      background-color: var(--active-state);
    }

    &.type-terminal {
      width: 55px;
      height: 54px;
      font-size: 22px;
    }
  }

  &.circle {
    border-radius: 100%;

    &::after {
      background-color: var(--button);
    }

    &:active {
      background-color: var(--active-state);
      border: 2px solid var(--active-state);
    }

    &:disabled {
      background-color: var(--active-state);
    }

    &.active {
      background-color: var(--circle-color-active);
      border: 2px solid var(--circle-border-color-active);
      color: var(--text-primary-1);
      transition: all 0.3s ease;
      &::after {
        background-color: var(--circle-color-active);
      }
    }
  }

  &.type-terminal {
    width: 54px;
    height: 54px;
    font-size: 22px;
  }

  &.type-terminal-large {
    width: 72px;
    height: 72px;
    font-size: 28px;
  }
}

@media screen and (min-width: 660px) {
  .button {
    &.square {
      width: 45px;
    }
  }
}

@media screen and (min-width: 800px) {
  .button {
    &.square {
      width: 48px;
    }
  }
}

@media screen and (min-width: 960px) {
  .button {
    &.square {
      width: 45px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .button {
    &.square {
      width: 48px;
    }
  }
}

@media screen and (min-width: 1268px) {
  .button {
    &.square {
      width: 45px;
    }
  }
}

@media screen and (min-width: 1484px) {
  .button {
    &.square {
      width: 56px;
    }
  }
}

@media screen and (min-width: 1910px) {
  .button  {
    &.square {
      &.type-terminal {
        width: 90px;
        height: 72px;
        font-size: 24px;
      }
    }
  }
}

@media screen and (min-height: 1790px) {
  .button  {
    &.square {
      &.type-terminal {
        width: 101px;
        height: 72px;
        font-size: 24px;
      }
    }
  }
}

@media (hover:hover) and (pointer: fine) {
  .button {
    &.circle:not(.active):not(.disabled) {
      &:hover {
        background-color: var(--hover-state);
        border: 2px solid var(--hover-state);
      }
      &:active {
        background-color: var(--active-state);
        border: 2px solid var(--active-state);
      }
    }
    &.square {
      &:hover:not(:disabled):not(.disabled) {
        background-color: var(--hover-state);
      }

      &:active:not(:disabled):not(.disabled) {
        background-color: var(--active-state);
      }
    }
  }
}
</style>
