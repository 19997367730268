<template>
  <div class="stake-box"
       :class="typeClass"
       v-clickout="close">
    <div class="header">
      <div v-if="!isPaymentPerBet">{{ translations.general_stake }}</div>
      <div v-if="isPaymentPerBet">{{ translations.general_bet }}</div>
      <div class="total-payment" v-if="!isDesktop">
        <GamesBetslipInput class="stake"
                           v-if="!isPaymentPerBet"
                           :isFromDispatchEvent="isFromDispatchEvent"
                           :label="translations.general_stake"
                           :parentValue="tempStake"
                           @fieldFocus="openKeyboard"
                           @valueChanged="handleChange"
                           @click="handleClick"
                           @change="setTempStakeOnBlur"
                           :disableRounding="true"
                           :showDelete="false"
                           @contextmenu.native="handler"
                           ref="betslipInput" />
        <GamesBetslipStakePerBetInput class="stake"
                           v-if="isPaymentPerBet"
                           :isFromDispatchEvent="isFromDispatchEvent"
                           :label="translations.general_bet"
                           :parentValue="tempStake"
                           @fieldFocus="openKeyboard"
                           @valueChanged="handleChange"
                           @click="handleClick"
                           :disableRounding="true"
                           :showDelete="false"
                           @contextmenu.native="handler"
                           ref="betslipInput" />
        <Button :label="``"
                :class="typeClass"
                @change="resetPayment">
          <i class="icon icon-backspace-a"></i>
        </Button>
      </div>
      <Keyboard v-if="!isDesktop" @keyboardButton="setTempStake" />
    </div>
    <div class="predefined-stakes">
      <Button v-for="(value, index) in predefinedStakes"
              :key="index"
              :class="typeClass"
              @change="selectPredefined($event, value)"
              :label="`+ ${value}`">
      </Button>
    </div>
    <div class="actions"
         v-if="isActionVisible">
      <button @click="close">{{ translations.general_cancel }}</button>
      <button @click="accept">{{ translations.general_accept }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GamesBetslipInput from '@/components/BetslipArea/GamesBetslipInput';
import GamesBetslipStakePerBetInput from '@/components/BetslipArea/GamesBetslipStakePerBetInput';
import { inputUtils } from '@/utility';
import { typeable } from '@/mixins';
import Button from './Button';
import Keyboard from './Keyboard';
import { clickout } from '../directives';

export default {
  name: 'StakeBox',
  mixins: [typeable],
  directives: {
    clickout,
  },
  components: {
    Button,
    Keyboard,
    GamesBetslipInput,
    GamesBetslipStakePerBetInput,
  },
  props: {
    predefinedStakes: {
      type: Array,
      default: () => [0.5, 1, 2, 5, 10, 20],
    },
    showKeyboard: {
      type: Boolean,
      default: false,
    },
    isTerminal: {
      type: Boolean,
      default: false,
    },
    isPaymentPerBet: {
      type: Boolean,
      default: false,
    },
    isSingleBetEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      payment: 'betslip/payment',
      paymentPerBet: 'betslip/paymentPerBet',
      minPayment: 'betslip/minPayment',
      minPaymentPerBet: 'betslip/minPaymentPerBet',
      translations: 'translations',
      mobileVisualizationHeight: 'betslip/mobileVisualizationHeight',
      rules: 'rules',
      isDesktop: 'isDesktop',
    }),
    isActionVisible() {
      return !this.isDesktop || this.isTerminal;
    },
  },
  data() {
    return {
      tempStake: '',
      focus: false,
      keyboardVisible: this.showKeyboard,
      manualEntryAllowed: true,
      inputRawRef: null,
      isFromDispatchEvent: false,
    };
  },
  methods: {
    handler(event) {
      const e = event;
      e.returnValue = false;
      e.preventDefault();
    },
    resetPayment() {
      if (this.isSingleBetEdit) {
        this.tempStake = this.rules.minBetAmount.value;
        this.manualEntryAllowed = true;
      } else {
        this.tempStake = this.isPaymentPerBet ? this.minPaymentPerBet : this.minPayment;
        this.manualEntryAllowed = true;
      }
    },
    initTempStake() {
      this.manualEntryAllowed = true;
      this.tempStake = this.isPaymentPerBet
        ? Number(this.paymentPerBet).toFixed(2) : Number(this.payment).toFixed(2);
    },
    openKeyboard() {
      this.keyboardVisible = true;
    },
    selectPredefined(event, value) {
      this.manualEntryAllowed = true;
      this.tempStake = Number(Number(this.tempStake) + Number(value)).toFixed(2);
      if (this.isDesktop) {
        this.$emit('click', this.tempStake);
      }
    },
    sendEventsToBetslipInput(value) {
      this.$nextTick(() => {
        inputUtils.dispatchKeyDownEvent(this.inputRawRef, String(value));
        inputUtils.dispatchKeyUpEvent(this.inputRawRef, String(value));
      });
    },
    focusBetslipInput() {
      this.$refs.betslipInput.$el.children[1].focus();
    },
    setTempStakeOnBlur(value) {
      this.tempStake = value;
    },
    setTempStake(value) {
      this.focusBetslipInput();
      this.isFromDispatchEvent = true;

      if (this.manualEntryAllowed) {
        this.tempStake = '';
      }

      this.sendEventsToBetslipInput(value);
      this.manualEntryAllowed = false;

      // We want to disallow dispatch events as soon as possible
      // So user can manipulate input normally
      this.$nextTick(() => {
        this.isFromDispatchEvent = false;
      });
    },
    accept() {
      this.$emit('click', this.tempStake);
      this.close();
    },
    close() {
      this.keyboardVisible = false;
      this.$emit('close');
      this.initTempStake();
    },
    handleChange(value) {
      this.tempStake = value;
    },
    handleClick() {
      this.isFromDispatchEvent = false;
    },
  },
  created() {
    this.initTempStake();
  },
  mounted() {
    this.inputRawRef = document.querySelector('.betslip-input-wrapper .betslip-input');
    this.$root.$on('setZeroStake', () => {
      this.tempStake = '0.00';
    });
  },
};
</script>

<style lang="scss" scoped>
.stake-box {
  width: 100%;
  background: var(--card);
  position: absolute;
  border-radius: 2px;
  padding: 17px 16px;
  z-index: 999;
  pointer-events: all;
  box-shadow: 3px 4px 15px 3px rgba(40, 40, 40, 1);

  .header {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--text-primary-1);
    margin-bottom: 8px;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .total-payment {
      height: 40px;
      margin: 14px 4px 16px 4px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .stake {
        margin-right: 16px;
      }

      .input {
        border-radius: 2px;
        background-color: var(--input);
        border: none;
        outline: 0;
        position: relative;
        text-indent: 8px;
        padding: 0;
        height: 100%;
        font-size: 14px;
        color: var(--text-primary-1);
        float: left;
        width: calc(100% - 70px);

        &.disabled {
          opacity: 0.5;
        }

        &.highlight {
          color: var(--secondary);
        }
      }

      input {
        font-family: "Roboto", sans-serif;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      .button {
        width: 50px;
        float: right;
      }

      label {
        position: absolute;
        left: 8px;
        top: 3px;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-primary-2);
      }

      .value {
        position: absolute;
        top: 18px;
        left: 9px;
        font-weight: 400;
        font-size: 14px;
        color: var(--text-primary-1);

        &.highlight {
          background: var(--system-info);
        }
      }
    }
  }

  .predefined-stakes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;

    .button {
      flex-grow: 1;
      font-family: "Roboto", sans-serif;
      flex-basis: 70px;
    }
  }

  .actions {
    float: right;
    margin-top: 16px;
    padding: 0 5px;
    button {
      border: none;
      outline: none;
      background: transparent;
      color: var(--system-neutral);
      text-transform: uppercase;
      margin-left: 43px;
    }
  }

  &.type-terminal {
    .header {
      margin-bottom: 0;
      .total-payment {
        margin: 14px 0 0 0;
        height: 72px;
        .betslip-input-wrapper {
          width: calc((100% - 112px) - 16px);
          ::v-deep {
            .betslip-input {
              height: 100%;
              font-size: 24px;
            }
            label {
              font-size: 18px;
              top: 7px;
              font-weight: normal;
            }
          }
        }
        .value {
          font-size: 24px;
          top: 30px;
        }
        .button {
          width: 112px;
        }
      }
      .virtual-keyboard {
        ::v-deep {
          .keyboard-button {
            padding: 35px 0;
            font-size: 24px;
          }
        }
      }
    }
    .predefined-stakes {
      grid-gap: 16px;
    }
    .actions {
      margin-top: 35px;
      button {
        font-size: 24px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 659px) {
  .stake-box {
    position: absolute;
    bottom: 0px;
    box-shadow: none;

    .predefined-stakes {
      button {
        flex-basis: 87px;
      }
    }
  }
}
</style>
