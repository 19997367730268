<template>
  <div v-if="isOpen"
       class="quickpick-mobile-wrapper">
    <Modal :width="isTablet ? '400px' : '100%'"
           height="100%"
           showFooter
           @closeModal="handleCloseModal"
           @closeModalOutside="handleCloseModal"
    >
      <template v-slot:header>
        <h1 class="quickpick-title">
          {{ title }}
        </h1>
      </template>
      <template v-slot:body>

        <div class="quickpick-items">
          <div class="quickpick-item"
               v-for="(item, index) in items"
               :key="index">
            <input :id="item.value"
                   v-model="selectedItem"
                   type="radio"
                   :value="item.value">
            <label :for="item.value">
              <i class="icon quickpick-icon" />
              <span class="quickpick-radio-button">
                {{ itemLabel(item.label) }}
              </span>
            </label>
          </div>
        </div>

      </template>
      <template v-slot:footer>
        <button class="quickpick-cancel"
                @click="handleCloseButton">{{ cancelButtonLabel }}</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import Modal from '../Modal';
import { bodyScroll, eventBus } from '../../utility';

export default {
  name: 'QuickpickMobile',
  components: {
    Modal,
  },
  emits: ['change'],
  props: {
    cancelButtonLabel: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
      default: 'Quick',
    },
    modalDefaultItem: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      required: true,
      default: 'Quick pick',
    },
    resetable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: this.modalDefaultItem,
    };
  },
  computed: {
    ...mapGetters(['isTablet']),
    itemLabel() {
      return value => `${this.label} ${value}`;
    },
  },
  methods: {
    findLabel() {
      const label = this.items.find(item => item.value === this.selectedItem)?.label;

      return !isNil(label) ? label : '';
    },
    handleCloseModal() {
      this.$emit('closeModal');
    },
    handleCloseButton() {
      this.$emit('closeModal');
    },
  },
  mounted() {
    eventBus.$on('resetSystemSelection', () => {
      if (this.resetable) this.selectedItem = this.modalDefaultItem;
    });
  },
  watch: {
    isOpen(value) {
      if (value) {
        bodyScroll.lock();
      } else {
        bodyScroll.unlock();
      }
    },
    modalDefaultItem(value) {
      this.selectedItem = value;
    },
    selectedItem(value) {
      const label = this.findLabel();

      this.$emit('change', {
        value,
        label,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.quickpick-mobile-wrapper {
  .quickpick-title {
    font-size: 18px;
    color: var(--text-primary-1);
    margin: 0;
  }

  .quickpick-items {
    margin-top: 38px;

    .quickpick-item {
      color: var(--text-primary-1);
      font-size: 14px;
      margin-bottom: 38px;

      .quickpick-icon::before {
        content: "\e9a7";
        font-size: 20px;
      }

      label {
        display: flex;
        align-items: center;
        width: 100%;

        span {
          margin-left: 10px;
        }
      }

      input[type="radio"] {
        visibility: hidden;
        position: absolute;
      }

      input[type="radio"]:checked {
        + label .quickpick-icon::before {
          content: "\e6b2";
          color: var(--secondary);
        }
      }
    }

    .quickpick-item:last-child {
      margin-bottom: 0;
    }
  }

  .quickpick-cancel {
    font-size: 14px;
    border: none;
    text-transform: uppercase;
    background-color: transparent;
    color: var(--secondary);
    cursor: pointer;
  }

  .modal-back::v-deep {
    padding: 36px 8px;
  }

  .modal-back::v-deep {
    .modal {
      overflow: overlay;

      .modal-header {
        padding: 24px 24px 0;
      }

      .modal-body {
        height: auto;
        padding-left: 26px;
      }

      .modal-footer {
        position: static;
        margin-top: auto;
        padding: 42px 29px 27px 0;
      }
    }
  }
}
</style>
