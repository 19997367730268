<template>
  <div class="betslip-footer-wrap" ref="betslipFooter">
    <div class="betslip-footer"
         :class="{'layout': hidePayinArea, 'no-future-bet' : !isFutureActive,
          'per-bet': isPaymentPerBetActive}">
      <!-- STAKE  -->
      <div class="input-wrapper"
           :class="{'no-future-bet' : !isFutureActive,
                    'per-bet': isPaymentPerBetActive,
                    'payin-hidden': hidePayinArea}">
        <Select v-if="isFutureActive
        && !ticketTypeIsCombo
        && !hidePayinArea
        && !isFuturePerBetActive"
                id="future"
                :label="translations.general_future_bet"
                @change="updateFuture"
                :value="future"
                showFirst
                :orientation="futureOrientation"
                :options="futureRounds" />
        <Select v-if="isFutureActive && !ticketTypeIsCombo
          && hidePayinArea && !isPaymentPerBetActive && !isFuturePerBetActive"
                id="future"
                :label="translations.general_future_bet"
                @change="updateFuture"
                :value="future"
                showFirst
                :orientation="futureOrientation"
                :options="futureRounds" />
        <GamesBetslipStakePerBetInput v-if="isPaymentPerBetActive
              && !hidePayinArea && !ticketTypeIsCombo"
               class="stake"
               :parent-value="paymentPerBet"
               @change="updateStake($event)"
               @fieldFocus="toggleStakeBox"
               @delete="clearStakePerBet"
               :label="translations.general_bet" />
        <div class="total-stake-wrapper"
             v-if="isPaymentPerBetActive && !hidePayinArea && !ticketTypeIsCombo"
             :class="{'no-future-bet' : !isFutureActive}">
          <div class="label">{{ translations.general_total_stake }}</div>
          <Input class="total-stake per-bet"
            :value="totalPayment"
            :disabled="true"
            :round="toRound"
            :allowPaste="false"
            orientation="right" />
        </div>
        <GamesBetslipInput v-if="!isPaymentPerBetActive && !hidePayinArea && !ticketTypeIsCombo"
                           class="stake"
                           :parent-value="payment"
                           @change="updateStake"
                           @fieldFocus="toggleStakeBox"
                           @delete="clearStake"
                           :label="translations.general_stake" />
        <Input v-if="ticketTypeIsCombo"
               class="stake"
               :value="payment"
               :round="toRound"
               :allowPaste="false"
               :label="translations.general_stake"
               @change="updateStake($event)" />

        <fade-transition>
          <StakeBox @click="updateStake($event)"
                    :class="{
                      'payment-per-bet': isPaymentPerBetActive,
                      'mobile': !isDesktop,
                      'payin-hidden': hidePayinArea}"
                    @close="closeStakeBox"
                    :isPaymentPerBet="isPaymentPerBetActive"
                    v-if="stakeBoxVisible" />
        </fade-transition>
      </div>
      <div class="stake-per-bet-wrap"
      v-if="isPaymentPerBetActive && hidePayinArea && !ticketTypeIsCombo">
      <div class="label">{{ translations.general_bet }}</div>
      <GamesBetslipStakePerBetInput v-if="isPaymentPerBetActive
              && hidePayinArea && !ticketTypeIsCombo"
               class="stake"
               :parent-value="paymentPerBet"
               @change="updateStake($event)"
               @fieldFocus="toggleStakeBox"
               @delete="clearStakePerBet"
               label="" />

    </div>
      <!-- REMOVE BET BUTTON -->
      <div class="remove-bet-wrapper">
        <Button v-if="hidePayinArea && !isPaymentPerBetActive"
                class="remove-bet"
                @change="clearBets()"
                :disabled="isTicketEmpty"
                label="">
          <i class="icon icon-trash"></i>
        </Button>
      </div>
    </div>
    <!-- TOTAL ODDS -->
    <TotalOdds />
    <!--  POSSIBLE WIN -->
    <PossibleWin :isPossibleWinActive="isPossibleWinActive" />
    <div v-if="hidePayinArea && isPaymentPerBetActive"
      class="per-bet-footer-wrapper"
      :class="{'no-future': !isFutureActive}">
      <Select v-if="isFutureActive && !ticketTypeIsCombo
          && hidePayinArea && isPaymentPerBetActive"
                id="future"
                :label="translations.general_future_bet"
                @change="updateFuture"
                :value="future"
                showFirst
                :orientation="futureOrientation"
                :options="futureRounds" />
      <div class="remove-bet-wrapper">
        <Button
                class="remove-bet"
                @change="clearBets()"
                :disabled="isTicketEmpty"
                label="">
          <i class="icon icon-trash"></i>
        </Button>
      </div>
    </div>
    <!-- PAYIN -->
    <div v-if="!hidePayinArea"
         class="payin-wrapper">
      <Button class="remove-bet"
              @change="clearBets()"
              variation="tertiary"
              :disabled="isTicketEmpty"
              label="">
        <i class="icon icon-trash"></i>
      </Button>
      <LoginPayinButton class="payin" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { range, each } from 'lodash';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Select from '@/components/Select/Select';
import LoginPayinButton from '@/components/BetslipArea/BetslipLoginPayinButton';
import helpers from '@/mixins/helpers';
import ticket from '@/mixins/betslip/ticket';
import FadeTransition from '@/components/FadeTransition';
import StakeBox from '@/components/StakeBox';
import GamesBetslipInput from '@/components/BetslipArea/GamesBetslipInput';
import GamesBetslipStakePerBetInput from '@/components/BetslipArea/GamesBetslipStakePerBetInput';
import TotalOdds from './BetslipTotalOdds';
import PossibleWin from './BetslipPossibleWin';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipFooter',
  mixins: [helpers, ticket],
  props: [
    'isFutureActive', 'futureOrientation', 'hidePayinArea', 'isPossibleWinActive',
  ],
  components: {
    Button,
    Input,
    LoginPayinButton,
    PossibleWin,
    Select,
    TotalOdds,
    FadeTransition,
    StakeBox,
    GamesBetslipInput,
    GamesBetslipStakePerBetInput,
  },
  data() {
    return {
      stakeBoxVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      futureBets: 'betslip/futureBets',
      future: 'betslip/future',
      payment: 'betslip/payment',
      paymentPerBet: 'betslip/paymentPerBet',
      minPaymentPerBet: 'betslip/minPaymentPerBet',
      totalPayment: 'betslip/totalPayment',
      translations: 'translations',
      toRound: 'betslip/toRound',
      ticket: 'betslip/tickets',
      isFuturePerBetActive: 'betslip/isFuturePerBetActive',
      isTerminalUser: 'user/isTerminalUser',
      isDesktop: 'isDesktop',
    }),
    futureRounds() {
      return range(1, this.futureBets + 1);
    },
    isTicketEmpty() {
      return !this.ticket.length;
    },
    footerHeight() {
      return this.$refs.betslipFooter.clientHeight;
    },
  },
  methods: {
    ...mapActions({
      clearBetslip: 'betslip/clearBetslip',
      paymentModified: 'betslip/paymentModified',
      setStakeValue: 'betslip/setStakeValue',
      setTotalStakeValue: 'betslip/setTotalStakeValue',
      updateFuture: 'betslip/updateFuture',
      setBetslipFooterHeight: 'betslip/setBetslipFooterHeight',
      setPaymentPerBetValue: 'betslip/setPaymentPerBetValue',
      setNotification: 'notifications/setNotification',
    }),
    clearBets() {
      this.$root.$emit('ClearBets');
      eventBus.$emit('ClearBets');
      setTimeout(() => {
        this.clearBetslip();
      }, 300);
    },
    closeStakeBox() {
      this.stakeBoxVisible = false;
    },
    observeHeight() {
      const resizeObserver = new ResizeObserver(() => {
        this.setBetslipFooterHeight(this.$refs.betslipFooter?.clientHeight);
      });
      resizeObserver.observe(this.$refs.betslipFooter);
    },
    toggleStakeBox() {
      if (!this.stakeBoxVisible) {
        setTimeout(() => {
          this.stakeBoxVisible = !this.stakeBoxVisible;
        }, 200);
      }
    },
    updateStake(payment) {
      if (this.isTerminalUser) {
        this.$root.$emit('TerminalStakeUpdate', payment);
      }
      this.paymentModified(true);
      this.setStakeValue(Number(payment)).then(() => {
        if (this.isPaymentPerBetActive) {
          if (Number(payment) === 0) {
            this.setStakeValue(Number(this.minPaymentPerBet));
          }
          if (this.ticket.length > 0) {
            each(this.ticket, (singleBet) => {
              const message = {
                message: this.translations.general_min_bet_amount_rule.supplant({
                  value: singleBet.minPayment.toFixed(2),
                }),
                notificationTimeout: 500,
              };
              if (singleBet.payment < singleBet.minPayment) {
                this.setNotification(message);
              }
            });
          }
          this.setTotalStakeValue();
        }
      });
    },
  },
  beforeMount() {
    if (this.isPaymentPerBetActive) {
      this.setPaymentPerBetValue(this.minPaymentPerBet);
    }
  },
  mounted() {
    this.observeHeight();
  },
};
</script>

<style lang="scss" scoped>
.betslip-footer-wrap {
  padding: 8px;
  background-color: var(--background-2);

  .betslip-footer {
    .input-wrapper {
      position: relative;
      &.per-bet {
        .stake-box {
          bottom: 97px;
        }
      }
      &.per-bet.payin-hidden {
        .stake-box {
          bottom: -38px;
          width: 284px;
        }
      }
      &.per-bet.no-future-bet {
        .stake-box {
          bottom: 47px;
        }
      }
      .stake-box {
        bottom: 47px;
        left: 0;
      }
    }
  }

  .betslip-footer {
    .input-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      &.per-bet {
        grid-template-columns: 60% 40%;
        padding-right: 8px;
      }
      &.payin-hidden {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        padding-right: 0;
      }
      &.no-future-bet {
        .stake {
          order: 2;
        }
        .total-stake {
          order: 1;
        }
      }
      ::v-deep .stake-box {
        &.payment-per-bet.mobile {
          position: fixed;
          bottom: 0;
          width: 100%;
        }
      }

      .select,
      .input-container {
        width: auto;
      }
      .total-stake-wrapper {
        grid-column: 1 / -1;
        display: flex;
        &.no-future-bet {
          grid-column: unset;
        }
        .label {
          line-height: 40px;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          width: 60%;
          padding-left: 8px;
          background-color: var(--card-section);
        }
        .input-container {
          width: 40%;
          ::v-deep .right {
            padding-top: 0;
            background-color: var(--card-section);
            font-weight: bold;
          }
        }
      }
    }
    .remove-bet-wrapper {
      display: flex;
      flex-direction: row;

      .future,
      .input-container {
        --input-width: 100%;
        flex: 1;
        cursor: pointer;
      }
      .future {
        margin-right: 8px;
      }
      .total-stake {
        margin-left: 8px;
      }
    }
  }

  .betslip-footer.layout {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 8px;

    .remove-bet-wrapper {
      flex: 1;
    }
  }
  .betslip-footer.layout.no-future-bet {
    grid-template-columns: 1fr;
  }
  .betslip-footer.layout.per-bet {
    grid-template-columns: 1fr;
  }

  .stake-per-bet-wrap {
    display: flex;
    .label {
      background-color: var(--input);
      width: 70%;
      height: 40px;
      line-height: 40px;
      padding-left: 8px;
      user-select: none;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      text-align: left;
    }
    .betslip-input-wrapper {
      width: 30%;
      padding-top: 0px;
      ::v-deep .betslip-input {
        padding-top: 0;
        font-weight: bold;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        text-align: right;
        padding-right: 30px;
      }
    }
  }
  .per-bet-footer-wrapper {
    display: flex;
    justify-content: space-between;
    &.no-future {
      .remove-bet-wrapper {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    .select {
      width: 60%;
    }
    .remove-bet-wrapper {
      width: 38%;
      button {
        width: 100%;
      }
    }
  }

  .payin-wrapper {
    display: flex;
    padding-top: 8px;

    .payin {
      min-width: 150px;
      flex: 8;
      border-radius: var(--button-border-radius, var(--border-radius));
      margin-left: 8px;

      .button {
        width: 100%;
      }
    }
  }

  .remove-bet {
    min-width: 40px;
    height: 40px;
    flex: 1;
    border-radius: var(--button-rounded, 2px);

    &.disabled {
      color: var(--text-tertiary-2, var(--text-primary-2));
    }

    .icon {
      font-size: 18px;
    }
  }
}
</style>
