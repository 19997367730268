<template>
  <div class="betslip-header"
      ref="betslipheader"
      v-swipeDown="swipeHandler">
    <Tabs :tabs="betslipComponents"
          :activeTab="betslipActiveComponent"
          @change="updateTab"></Tabs>
    <button v-interactionStates
            class="betslip-minimize"
            @click="handleMinimizeClick"
            v-if="isMinimizeVisible">
      <i class="icon icon-minimize" />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { interactionStates } from '@/directives';
import Tabs from '../../Tabs';
import swipeDown from '../../../directives/swipeDown';

export default {
  name: 'BetslipHeader',
  components: {
    Tabs,
  },
  directives: {
    interactionStates,
    swipeDown,
  },
  data() {
    return {
      activeTab: {},
    };
  },
  props: {
    isMinimizeVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      betslipComponents: 'betslip/betslipComponents',
      betslipActiveComponent: 'betslip/betslipActiveComponent',
      isDesktop: 'isDesktop',
    }),
  },
  methods: {
    ...mapActions({
      toogleBetslip: 'betslip/showBetslip',
    }),
    swipeHandler() {
      this.swipeDown();
    },
    handleMinimizeClick() {
      this.$emit('minimizeClick');
    },
    updateTab(tab) {
      this.activeTab = tab;
      this.$emit('change', tab);
    },
    swipeDown() {
      if (!this.isDesktop) {
        this.toogleBetslip();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.betslip-header {
  display: flex;

  .betslip-minimize {
    font-size: 18px;
    color: var(--text-primary-1);
    border: 0;
    background-color: var(--card);
    padding: 0 8px;
    cursor: pointer;

    &::after {
      background-color: var(--card);
    }

    &:hover {
      background-color: var(--hover-state);
    }

    &:active {
      background-color: var(--active-state);
    }
  }

  ::v-deep .tabs {
    width: 100%;
  }
}

::v-deep .tab-button {
  &.disabled {
    button {
      color: var(--text-primary-3);
      pointer-events: none;
    }
  }
}
</style>
