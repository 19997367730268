<template>
  <Modal class="ticket-details-modal"
         :showFooter="true"
         max-width="846px"
         :width= "modalWidth"
         height="740px"
         @closeModal="closeDetails"
         @closeModalOutside="closeDetails('outside')"
         v-if="isModalVisible && ticket"
         ref="modal">
    <template #header>
      <LastTicketsDetailsHeader :ticket="ticket" :productNameLabel="productNameLabel" />
    </template>
    <template #body>
      <LastTicketsDetailsBody :ticket="ticket"
                              :clickDisabled="true"
                              :isLastTicketsData="isLastTicketsData"
                              :isBallDrawn="isBallDrawn"
      />
    </template>
    <template #footer>
      <LastTicketsDetailsFooter :ticket="ticket"
                                @closeDetails="closeDetails" />
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../Modal';
import LastTicketsDetailsHeader from './LastTicketsDetailsHeader';
import LastTicketsDetailsBody from './LastTicketsDetailsBody';
import LastTicketsDetailsFooter from './LastTicketsDetailsFooter';

export default {
  name: 'TicketDetails',
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    isLastTicketsData: {
      type: Boolean,
      default: true,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
  },
  components: {
    Modal,
    LastTicketsDetailsHeader,
    LastTicketsDetailsBody,
    LastTicketsDetailsFooter,
  },
  methods: {
    closeDetails(option) {
      if (option === 'outside') {
        this.$emit('closeDetails');
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.$emit('closeDetails');
        }, 500);
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
    modalWidth() {
      // eslint-disable-next-line no-nested-ternary
      return this.isMobile ? '100%' : this.isTablet ? 'calc(100% - 48px)' : '846px';
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-details-modal {
  ::v-deep {
    .modal {
    background-color: var(--card);
    }
  }
}
@media (max-width: 660px) {
  .ticket-details-modal {
    ::v-deep {
      .modal {
      background-color: var(--background-1);
      .modal-header {
        flex: 0 1 auto;
        background-color: var(--card-section);
        margin-bottom: 1px;
      }
      .modal-body {
        flex: 1 1 auto;
        overflow-y: auto;
        padding: 0;
        .body {
          display: flex;
          flex-flow: column;
          flex: 1 1 auto;
          overflow-y: auto;
          .ticket-details-table {
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-track {
              background: var(--card);
            }
            &::-webkit-scrollbar-thumb {
              background: var(--button);
              border-radius: 3px;
            }
            max-height: none !important;
            flex: 0 1 auto;
            overflow-y: auto;
          }
          .bet-value.status-right .label.bet-status {
            width: 100%;
          }
          .payment-info {
            flex: 0 1 auto;
          }
        }
      }
      .modal-footer {
        position: static !important;
        border-top: 1px solid var(--input);
        flex: 0 1 auto;
        background-color: var(--card);
      }
    }
    }
  }
}
</style>
