import { currentApp } from '../utility';

const longPressStop = new CustomEvent('long-press-stop');
const longPressStart = new CustomEvent('long-press-start');

const directiveOptionVue2 = {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.dataset.longPressTimeoutId = '0';

    const onpointerup = () => {
      // eslint-disable-next-line radix
      clearTimeout(parseInt(el.dataset.longPressTimeoutId));

      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('long-press-stop');
      } else {
        el.dispatchEvent(longPressStop);
      }

      document.removeEventListener('pointerup', onpointerup);
    };

    const onpointerdown = () => {
      document.addEventListener('pointerup', onpointerup);

      const timeout = setTimeout(() => {
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit('long-press-start');
        } else {
          el.dispatchEvent(longPressStart);
        }
      }, binding.value);
      // eslint-disable-next-line no-param-reassign
      el.dataset.longPressTimeoutId = timeout.toString();
    };
    // eslint-disable-next-line no-param-reassign
    el.$_long_press_pointerdown_handler = onpointerdown;
    el.addEventListener('pointerdown', onpointerdown);
  },
  unbind(el) {
    // eslint-disable-next-line radix
    clearTimeout(parseInt(el.dataset.longPressTimeoutId));
    el.removeEventListener('pointerdown', el.$_long_press_pointerdown_handler);
  },
};
const directiveOptionVue3 = {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.dataset.longPressTimeoutId = '0';

    const onpointerup = () => {
      // eslint-disable-next-line radix
      clearTimeout(parseInt(el.dataset.longPressTimeoutId));

      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('long-press-stop');
      } else {
        el.dispatchEvent(longPressStop);
      }

      document.removeEventListener('pointerup', onpointerup);
    };

    const onpointerdown = () => {
      document.addEventListener('pointerup', onpointerup);

      const timeout = setTimeout(() => {
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit('long-press-start');
        } else {
          el.dispatchEvent(longPressStart);
        }
      }, binding.value);
      // eslint-disable-next-line no-param-reassign
      el.dataset.longPressTimeoutId = timeout.toString();
    };
    // eslint-disable-next-line no-param-reassign
    el.$_long_press_pointerdown_handler = onpointerdown;
    el.addEventListener('pointerdown', onpointerdown);
  },
  unbind(el) {
    // eslint-disable-next-line radix
    clearTimeout(parseInt(el.dataset.longPressTimeoutId));
    el.removeEventListener('pointerdown', el.$_long_press_pointerdown_handler);
  },
};

const directiveOption = currentApp.isVue2() ? directiveOptionVue2 : directiveOptionVue3;
export default directiveOption;
