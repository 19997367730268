<template>
  <div class="header-wrapper">
    <div class="game-title">{{ gameTitle }}</div>
    <div class="round-info">
      <div v-if="eventInProgress" class="round-in-progress">
        {{ translations.general_round }}
        {{ eventId }}
        {{ translations.general_in_progress }}
      </div>
      <div v-else class="next-round-info">
        <div class="title">
          {{ translations.general_next_round_in }}
        </div>
        <span>
          {{ eventTime }}
          <span v-if="bettingDisabled"> - {{ translations.general_betting_disabled }}</span>
        </span>
      </div>
    </div>
    <i v-if="showInfoButton" class="icon icon-info" @click="$emit('ShowInfo')"></i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TerminalHeader',
  emits: ['Showmodal', 'ReturnToLobby'],
  props: {
    showInfoButton: {
      type: Boolean,
      default: false,
    },
    gameTitle: {
      type: String,
      default: '',
    },
    eventId: {
      type: Number,
      default: 0,
    },
    eventInProgress: {
      type: Boolean,
      default: false,
    },
    eventTime: {
      type: String,
      default: '',
    },
    bettingDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['translations']),
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: var(--card);
  color: var(--text-primary-1);
  display: flex;
  font-size: 20px;
  align-items: center;
  .game-title,
  .round-info {
    padding: 18px 0;
    .round-in-progress,
    .next-round-info {
      padding: 0 8px;
      display: flex;
      gap: 8px;
      .title {
        color: var(--text-secondary);
      }
      span {
        color: var(--secondary);
      }
    }
    .round-in-progress {
      color: var(--text-secondary);
    }
  }
  .icon {
    padding: 16px 28px;
    cursor: pointer;
  }
  .game-title {
    font-weight: 500;
    padding-left: 1rem;
  }
  :last-child {
    margin-left: auto;
  }
}
@media screen and (min-width: 1914px) {
  .games-header {
    font-size: 24px;
    .icon {
      padding: 24px 28px;
    }
    .game-title,
    .round-info {
      padding: 1.68rem 0;
    }
    .game-title {
      padding-left: 16px;
    }
  }
}
@media screen and (min-width: 1070px) and (min-height: 1700px) {
  .games-header {
    font-size: 24px;
    .icon {
      padding: 24px 28px;
    }
    .game-title,
    .round-info {
      padding: 1.68rem 0;
    }
    .game-title {
      padding-left: 16px;
    }
  }
}
</style>
