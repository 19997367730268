<template>
  <iframe class="helpWrapper" :src="helpPage"></iframe>
</template>

<script>
export default {
  name: 'Help',
  props: {
    helpPage: String,
  },
};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
