
<template>
  <div ref="betslipbet">
    <StakeBox
      :isSingleBetEdit="true"
      @click="updateBetPayment($event, bet)"
      :class="{
        'payment-per-bet': isPaymentPerBetActive,
        'mobile': !isDesktop}"
      @close="closeStakeBox"
      v-if="stakeBoxVisible"
    />
    <div class="betslip-bet"
         :class="{ multilineBet: isMultilineBet,
          'per-bet': isPaymentPerBetActive,
          swipedLeftAnimation: swipedLeft,
          swipedRightAnimation: swipedRight,
          closeAnimation: closeAnimation,
          scale: betAppear }"
          v-horizontalSwipe="swipeHandlers"
        >
      <div :class="{ overlay: betAppear, 'per-bet': isPaymentPerBetActive}"></div>

      <div class="header">
        <div class="market info"> {{ convertMarketValue(bet) }} </div>

        <div class="remove icon icon-close info"
            :class="{ disabled: ticketPayinInProgress }"
             @click="removeOnClose"></div>
      </div>
      <div class="outcome info"
           v-html="convertOutcomeValue(bet)" />
      <div class="footer flex">
        <div class="round info background"
             :class="{'full-width': ticketTypeIsCombo, 'payment-per-bet': isPaymentPerBetActive}"
             v-if="bet.round">
          <span>{{translations.general_round}}: {{getRoundRange(bet)}}</span>
        </div>
        <div class="stake info background"
             v-if="!ticketTypeIsCombo"
             :class="{'payment-per-bet': isPaymentPerBetActive }">
          <span>{{ translations.general_stake }}</span>
          <Input v-if="isPaymentPerBetActive"
                 class="stake-value"
                 orientation="right"
                 :round="toRound"
                 type="web"
                 inputType="float"
                 :min="String(minPaymentPerBet)"
                 :value="bet.payment"
                 @contextmenu="handler"
                 @contextmenu.native="handler"
                 @change="updateBetPayment($event, bet)"
                 @focus="toggleStakeBox"
                 :readonly="!isDesktop"
                 inputmode="none" />
          <span v-else
                class="stake-value">{{bet.payment && bet.payment.toFixed(2)}} </span>
        </div>
      </div>
      <div v-if="possibleWinPerBet"
           class="possible-win-bet info">
        <span>
          {{translations.general_possible_winning}}: {{ calcPossibleWinPerBet(bet).rounded }}
        </span>
      </div>
      <div v-if="bet.locked"
           class="locked">
        <i class="icon icon-lock" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isArray, each } from 'lodash';
import possibleWin from '@/mixins/betslip/possibleWin';
import Input from '@/components/Input';
import helpers from '@/mixins/helpers';
import StakeBox from '@/components/StakeBox';
import { eventBus } from '../../../utility';
import horizontalSwipe from '../../../directives/horizontalSwipe';

const BetKeys = ['market', 'outcome', 'round']; // 'specialBet', 'odds', 'combinations'??

export default {
  name: 'BetslipBet',
  mixins: [helpers, possibleWin],
  data() {
    return {
      isMultilineBet: false,
      swipedLeft: false,
      swipedRight: false,
      closeAnimation: false,
      betAppear: false,
      stakeBoxVisible: false,
      swipeHandlers: {
        left: this.handleSwipeLeft,
        right: this.handleSwipeRight,
      },
    };
  },
  directives: {
    horizontalSwipe,
  },
  props: {
    bet: {
      type: Object,
      default: () => { },
      validator: config => BetKeys.every(key => key in config),
    },
  },
  components: {
    Input,
    StakeBox,
  },
  computed: {
    ...mapGetters({
      future: 'betslip/future',
      isPaymentModified: 'betslip/isPaymentModified',
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      minPaymentPerBet: 'betslip/minPaymentPerBet',
      paymentPerBet: 'betslip/paymentPerBet',
      possibleWinPerBet: 'betslip/possibleWinPerBet',
      rules: 'rules',
      stakePerBet: 'betslip/stakePerBet',
      toRound: 'betslip/toRound',
      toRoundCalc: 'betslip/toRoundCalc',
      translations: 'translations',
      tickets: 'betslip/tickets',
      ticketPayinInProgress: 'betslip/ticketPayinInProgress',
      isFuturePerBetActive: 'betslip/isFuturePerBetActive',
      isDesktop: 'isDesktop',
    }),
    isValidBet() {
      return this.bet.valid;
    },
  },
  methods: {
    ...mapActions('betslip', [
      'removeBet',
      'calculateAndUpdateBetValue',
      'disablePayinButton',
      'setTotalStakeValue',
      'paymentModified',
      'validateStake',
      'validateAllBets',
    ]),
    // TODO - different layouts for games
    ...mapActions({
      setNotification: 'notifications/setNotification',
      clearNotification: 'notifications/clearNotification',
    }),
    convertOutcomeValue(bet) {
      let currentBet;
      const displayOdd = !bet.odd || bet.betTypeId === 12 || bet.betTypeId === 13 ? '' : `(${bet.odd.toFixed(2)})`;

      if (bet.outcome.includes(',')) {
        currentBet = bet.outcome.split(',');
      } else {
        currentBet = isArray(bet.outcome) ? bet.outcome : bet.outcome.split(' ');
      }

      if (currentBet.length > 10) {
        const firstRow = currentBet.slice(0, 10);
        const secondRow = currentBet.slice(10);

        return `<div>${firstRow.join(' ')}<br>${secondRow.join(' ')}</div>`;
      }

      return `<div>${currentBet.join(' ')} ${displayOdd}</div>`;
    },
    convertMarketValue(bet) {
      const value = bet.specialBet ? `${bet.market} ${bet.specialBet}` : bet.market;
      return value;
    },
    getRoundRange(bet) {
      if (this.isFuturePerBetActive) {
        if (bet.numEvents && bet.numEvents > 1) {
          const roundRange = bet.round + (bet.numEvents - 1);
          return `${bet.round} - ${roundRange}`;
        }
      }
      if (this.future > 1) {
        const futureRound = bet.round + (this.future - 1);
        return `${bet.round} - ${futureRound}`;
      }
      return bet.round;
    },
    getOdd(odd) {
      return odd ? odd.toFixed(2) : '';
    },
    checkMultilineBet(bet) {
      const currentBet = isArray(bet) ? bet : bet.split(' ');

      if (currentBet.length > 10) {
        return true;
      }

      return false;
    },
    updateBetPayment(payment, bet) {
      // TODO do we need to validate global stake here?
      // const globalPayment = this.isPaymentModified ? this.paymentPerBet : this.minPaymentPerBet;
      this.calculateAndUpdateBetValue({ payment, bet }).then(() => {
        this.setTotalStakeValue();
        this.validateAllBets(); // .then(this.validateStake(globalPayment));
      });
      this.$root.$emit('StakeModified');
      eventBus.$emit('StakeModified');
      if (Number(payment) < Number(this.minPaymentPerBet) && this.isPaymentPerBetActive) {
        const message = {
          message: this.translations.general_min_bet_amount_rule.supplant({
            value: bet.minPayment.toFixed(2),
          }),
          notificationTimeout: 500,
        };
        this.setNotification(message).then(() => {
          const minPayment = this.minPaymentPerBet;
          this.calculateAndUpdateBetValue({ minPayment, bet }).then(() => {
            this.setTotalStakeValue();
            this.validateAllBets();
          });
        });
      } else if (Number(payment) > Number(this.rules.maxBetAmount.value)
        && this.isPaymentPerBetActive) {
        const message = {
          message: this.translations.general_max_bet_amount_rule.supplant({
            value: this.rules.maxBetAmount.value.toFixed(2),
          }),
          notificationTimeout: 500,
        };
        this.setNotification(message).then(() => {
          const maxPayment = String(this.rules.maxBetAmount.value);
          this.calculateAndUpdateBetValue({ maxPayment, bet }).then(() => {
            this.setTotalStakeValue();
            this.validateAllBets();
          });
        });
      } else if (Number(payment) >= Number(this.minPaymentPerBet) && this.isPaymentPerBetActive) {
        this.clearNotification();
      }
      if (this.isPaymentPerBetActive) {
        each(this.tickets, (singleBet) => {
          if (Number(singleBet.payment) < Number(singleBet.minPayment)) {
            const message = {
              message: this.translations.general_min_bet_amount_rule.supplant({
                value: this.minPaymentPerBet.toFixed(2),
              }),
              notificationTimeout: false,
            };
            this.setNotification(message);
          }
          if (Number(singleBet.payment) > this.rules.maxBetAmount.value) {
            const message = {
              message: this.translations.general_max_bet_amount_rule.supplant({
                value: this.rules.maxBetAmount.value.toFixed(2),
              }),
              notificationTimeout: false,
            };
            this.setNotification(message);
          }
        });
      }
    },
    handleSwipeLeft() {
      this.removeBetLeft();
    },
    handleSwipeRight() {
      this.removeBetRight();
    },
    removeBetLeft() {
      if (!this.isDesktop) {
        this.swipedLeft = true;
        setTimeout(() => {
          this.removeBet(this.bet);
          this.swipedLeft = false;
        }, 300);
      }
    },
    removeBetRight() {
      if (!this.isDesktop) {
        this.swipedRight = true;
        setTimeout(() => {
          this.removeBet(this.bet);
          this.swipedRight = false;
        }, 300);
      }
    },
    removeOnClose() {
      this.closeAnimation = true;
      setTimeout(() => {
        this.removeBet(this.bet);
        this.closeAnimation = false;
      }, 300);
    },
    toggleStakeBox() {
      if (!this.stakeBoxVisible && !this.isDesktop) {
        setTimeout(() => {
          this.stakeBoxVisible = !this.stakeBoxVisible;
        }, 200);
      }
    },
    closeStakeBox() {
      this.stakeBoxVisible = false;
    },
    handler(event) {
      const e = event;
      e.returnValue = false;
      e.preventDefault();
    },
  },
  mounted() {
    const el = this.$refs.betslipbet;
    this.isMultilineBet = this.checkMultilineBet(this.bet?.outcome);
    this.betAppear = true;
    el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    setTimeout(() => {
      this.betAppear = false;
    }, 600);
  },
  updated() {
    this.isMultilineBet = this.checkMultilineBet(this.bet?.outcome);
  },
};

</script>

<style lang="scss" scoped>
::v-deep .stake-box {
  position: fixed;
}
.closeAnimation {
  position: relative;
  animation-name: closeAnimation;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}
.swipedRightAnimation {
  position: relative;
  animation-name: swipedright;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}
.swipedLeftAnimation {
  position: relative;
  animation-name: swipedleft;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}
@keyframes closeAnimation {
  0% {
    position: relative;
    transform: scale(1);
  }
  100% {
    position: relative;
    transform: scale(0.9);
    opacity: 0;
  }
}
@keyframes swipedleft {
  0% {
    position: relative;
    transform: translateX(0%);
  }
  100% {
    position: relative;
    transform: translateX(-100%);
  }
}
@keyframes swipedright {
  0% {
    position: relative;
    transform: translateX(0%);
  }
  100% {
    position: relative;
    transform: translateX(100%);
  }
}
.betslip-bet {
  position: relative;
  touch-action: pan-y !important;
  position: relative;
  height: 91px;
  border-radius: 2px;
  padding: 8px;
  background-color: var(--card);
  margin-bottom: 1px;
  transition: background-color 0.6s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &.per-bet {
    height: 102px;
  }
  .overlay {
    animation-name: betAppear;
    animation-duration: 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 1px;
    height: 92px;
    transition-timing-function: ease;
    &.per-bet {
      height: 102px;
    }
  }
  &.scale {
    animation-name: betScale;
    animation-duration: 0.6s;
    transition-timing-function: ease;
  }
  &.multilineBet {
    height: 107px;
  }
  &:hover {
    background-color: var(--card-hover);
  }
}
.header {
  display: flex;
  justify-content: space-between;

  .remove {
    color: var(--text-primary-1);
    cursor: pointer;
    font-size: 18px;
    z-index: 101;
    &.disabled {
      z-index: 1;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }
}

.outcome {
  font-weight: bold;

  &.info {
    margin-bottom: 6px;
  }
}

.market {
  padding-bottom: 6px;
  color: var(--secondary);
}

.info {
  text-align: left;
}

.footer {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 4px;
  .background {
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 30px;
    border-radius: 2px;
    background-color: var(--card-section);
    justify-content: space-between;

    &.full-width {
      width: 100%;
    }

    > span:not(.stake-value):not(.odd-value) {
      opacity: 0.6;
    }
    &.round.payment-per-bet {
      background-color: var(--card-section);
    }
  }

  .payment-per-bet {
    height: 40px;
    background-color: var(--input);
    .input-container {
      height: 30px;
      width: 50px;
    }
  }

  .stake-value,
  .odd-value {
    opacity: 1;
    font-weight: bold;
  }

  .stake-value {
    text-align: right;
    --input-height: 30px;
    --input-bg: var(--card-section);
    --input-padding: 0;
  }
}

.possible-win-bet {
  padding-top: 5px;
  font-size: var(--font-size-xsmall, 0.625rem);
}

.betslip-bet {
  .locked {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    background-color: var(--background-2);
    opacity: 0.7;
    text-align: center;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }
}
@keyframes betAppear {
  0% {
    background-color: var(--secondary);
    opacity: 0.2;
  }
  2% {
    background-color: var(--secondary);
    opacity: 0.2;
  }
  45% {
    background-color: var(--secondary);
    opacity: 0.1;
  }
  100% {
    background-color: var(--secondary);
    opacity: 0;
  }
}
@keyframes betScale {
  0% {
    transform: scale(0.8);
  }
  10% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
