<template>
  <div class="betslip-footer">
    <div class="stake-wrapper">
      <GamesBetslipInput class="stake"
                         v-if="!isPaymentPerBetActive"
                         :parentValue="payment"
                         :label="translations.general_stake"
                         @change="updateStake"
                         @fieldFocus="$emit('fieldFocus')"
                         @delete="clearStake"
                         @contextmenu.native="handler"/>
      <div class="total-stake-pb-wrap" v-if="isPaymentPerBetActive">
        <Input class="total-stake"
          :value="totalPayment"
          :disabled="true"
          :round="toRound"
          :allowPaste="false"
          :label="translations.general_total_stake"
          orientation="left" />
      </div>
    </div>
    <div class="payin-wrapper">
      <Button @change="addToBetslip()"
              v-if="isAddBetActive"
              :label="!isDesktop ? '' : translations.general_add_bet"
              :disabled="addBtnDisabled"
              class="add">
        <i v-if="!isDesktop"
           class="icon icon-plus"></i>
      </Button>
      <LoginPayinButton class="payin" />
      <Button class="open"
              :class="{active: numberOfBets && !isBetslipOpened,
              'bet-add': betAddOrRemove }"
              @change="toogleBetslipComponent"
              label="">
        <div class="number"
             v-if="!isBetslipOpened">
          <span v-if="numberOfBets">{{numberOfBets}}</span>
          <i class="icon icon-betslip"></i>
        </div>
        <i v-else
           class="icon icon-arrow-down-a"></i>
        <div :class="{overlay: overlay }"></div>
      </Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Input from '@/components/Input';
import Button from '@/components/Button';
import LoginPayinButton from '@/components/BetslipArea/BetslipLoginPayinButton';
import GamesBetslipInput from '@/components/BetslipArea/GamesBetslipInput';
import ticket from '@/mixins/betslip/ticket';

export default {
  name: 'BetslipFooter',
  mixins: [ticket],
  data() {
    return {
      isBetslipOpen: false,
      showZeroOnInput: true,
      betAddOrRemove: false,
      overlay: false,
    };
  },
  props: [
    'isAddBetActive',
  ],
  components: {
    Button,
    Input,
    LoginPayinButton,
    GamesBetslipInput,
  },
  computed: {
    ...mapGetters({
      isBetslipOpened: 'betslip/isBetslipOpened',
      isSelectedBetValid: 'betslip/isSelectedBetValid',
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      selectedBet: 'betslip/selectedBet',
      payment: 'betslip/payment',
      paymentPerBet: 'betslip/paymentPerBet',
      tickets: 'betslip/tickets',
      translations: 'translations',
      toRound: 'betslip/toRound',
      totalPayment: 'betslip/totalPayment',
      betslipComponents: 'betslip/betslipComponents',
      ticketPayinInProgress: 'betslip/ticketPayinInProgress',
      isDesktop: 'isDesktop',
    }),
    addBtnDisabled() {
      return !this.isSelectedBetValid || this.ticketPayinInProgress;
    },
    numberOfBets() {
      return this.tickets.length;
    },
  },
  watch: {
    numberOfBets(value) {
      if (value !== 0 && !this.isBetslipOpened) {
        if (this.overlay === true) {
          this.overlay = false;
          this.betAddOrRemove = false;
        }
        this.overlay = true;
        this.betAddOrRemove = true;
        setTimeout(() => {
          this.overlay = false;
          this.betAddOrRemove = false;
        }, 600);
      }
    },
  },
  methods: {
    ...mapActions({
      addBet: 'betslip/addBet',
      addPreBet: 'betslip/addPreBet',
      toogleBetslip: 'betslip/showBetslip',
      paymentModified: 'betslip/paymentModified',
      setStakeValue: 'betslip/setStakeValue',
      setTotalStakeValue: 'betslip/setTotalStakeValue',
      setActiveBetslipTab: 'betslip/setActiveBetslipTab',
    }),
    addToBetslip() {
      this.addBet(this.selectedBet);
    },
    updateStake(payment) {
      this.paymentModified(true);
      this.setStakeValue(payment).then(() => {
        if (this.isPaymentPerBetActive) this.setTotalStakeValue();
      });
    },
    handler(e) {
      e.preventDefault();
    },
    toogleBetslipComponent() {
      this.toogleBetslip();
      if (this.tickets.length === 0) {
        this.setActiveBetslipTab(this.betslipComponents[1]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.betslip-footer {
  --input-width: 80px;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 8px;
  background-color: var(--card);

  .payin-wrapper {
    button {
      font-family: "Roboto", "sans-serif";
    }
  }
  select,
  input {
    flex: 1;
    cursor: pointer;
  }

  .stake {
    width: 100%;
  }

  .stake-wrapper {
    display: flex;
    width: 110px;
  }
  .total-stake-pb-wrap {
    ::v-deep .input-container {
      user-select: none;
      .input {
        background-color: var(--text-secondary-2);
        border-radius: 2px;
      }
    }
  }

  .payin {
    width: 110px;
    color: var(--custom-text-color);

    .button {
      width: 100%;
    }

    &.disabled {
      opacity: .7;
    }
  }

  .open {
    position: relative;
    width: 50px;
    height: 40px;
    .overlay {
      animation-name: overlay;
      animation-duration: 0.6s;
      transition-timing-function: ease;
      border-radius: 2px;
      position: absolute;
      background-color: var(--text-primary-1);
      width: 50px;
      height: 40px;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 2;
    }
  }
  .add {
    width: 50px;
  }

  .button.open.active {
    position: relative;
    background-color: var(--secondary);
    color: var(--text-secondary-1);
    .number {
      position: relative;
      font-size: var(--font-size-large, 16px);
      transform: perspective(1px) translateZ(0);
    }

    span {
      position: absolute;
      width: 18px;
      font-family: "Roboto", "sans-serif";
      height: 18px;
      border-radius: 100%;
      transform: perspective(1px) translateZ(0);
      background-color: var(--secondary);
      right: 5px;
      top: -10px;
      font-size: var(--font-size-small, 12px);
      font-weight: bold;
    }
    &.bet-add {
      animation-name: betAdd;
      animation-duration: 0.6s;
      transition-timing-function: ease;
    }
  }
  @keyframes betAdd {
    0% {
      transform: scale(1);
      font-weight: bold;
    }
    20% {
      transform: scale(1.1);
      font-weight: bold;
    }
    100% {
      transform: scale(1);
      font-weight: bold;
    }
  }
  @keyframes overlay {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.4;
    }
    100% {
      opacity: 0;
    }
  }
  .add,
  .payin,
  .stake-wrapper {
    margin-right: 8px;
  }

  // todo 784-720?? @ivo
  @media (min-width: 660px) and (max-width: 784px) {
    // 720 in marvel
    .stake {
      width: 152px;
    }

    .input-container {
      width: 152px;
    }
    .payin,
    .add {
      width: 111px;
    }
    .open {
      width: 43px;
    }
  }

  @media (min-width: 784px) {
    .stake {
      width: 180px;
    }
    .stake-wrapper {
      width: unset;
    }

    .input-container {
      width: 180px;
    }
    .payin,
    .add {
      width: 132px;
    }
    .open {
      width: 50px;
    }
  }
}
</style>
