<template>
  <div class="quickpick-desktop-wrapper">
    <Scrollable>
      <div :class="[{ closed: !isOpen }, 'quickpick-items']">
        <div
          class="quickpick-item"
          @click="handleClick(item)"
          v-for="(item, index) in items"
          :key="index"
        >
          {{ itemLabel(item.label) }}
        </div>
      </div>
    </Scrollable>
  </div>
</template>

<script>
import Scrollable from '../Scrollable';

export default {
  name: 'QuickpickDesktop',
  components: {
    Scrollable,
  },
  emits: ['change'],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
      default: 'Quick',
    },
  },
  computed: {
    itemLabel() {
      return value => `${this.label} ${value}`;
    },
  },
  methods: {
    handleClick({ value, label }) {
      this.$emit('change', { value, label });
    },
  },
};
</script>

<style lang="scss" scoped>
.quickpick-desktop-wrapper {
  .quickpick-items {
    max-height: 200px;
    position: absolute;
    background-color: var(--card);
    width: 100%;
    z-index: 3;
    margin-top: 2px;
    padding-top: 8px;
    border-radius: 2px;
    box-shadow: var(--shadow-1);

    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: cubic-bezier(0.12, 0.67, 0.25, 0.99);
    -webkit-transition-timing-function: cubic-bezier(0.12, 0.67, 0.25, 0.99);
    -o-transition-timing-function: cubic-bezier(0.12, 0.67, 0.25, 0.99);
    transition-timing-function: cubic-bezier(0.12, 0.67, 0.25, 0.99);
    .quickpick-item {
      font-size: 14px;
      transition: all 0.3s ease;
      color: var(--text-primary-1);
      padding: 11px 0 11px 16px;
      cursor: pointer;
      height: 40px;

      &:hover {
        background-color: var(--card-hover);
      }

      &:active {
        background-color: var(--card-active);
      }
    }
  }
  .closed {
    max-height: 0;
    padding-top: 0;
  }
}
</style>
