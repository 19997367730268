<template>
  <div class="betslip">
    <BetslipHeader
      :numberOfBets="numberOfBets"
      :betsCounter="betsCounter"
    />
    <BetslipTicket v-on="$listeners" v-bind="$props" :betslipLockedMessage="betslipLockedMessage"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BetslipHeader from './BetslipHeader';
import BetslipTicket from './BetslipTicket';

export default {
  name: 'BetslipAreaD',
  components: {
    BetslipHeader,
    BetslipTicket,
  },
  props: {
    betslipTypes: {
      type: Array,
    },
    numberOfBets: {
      type: Boolean,
      default: true,
    },
    isFutureActive: {
      type: Boolean,
      default: true,
    },
    showNotification: {
      type: Boolean,
      default: true,
    },
    betslipLockedMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      betslipActiveComponent: 'betslip/betslipActiveComponent',
      ticket: 'betslip/tickets',
      translations: 'translations',
    }),
    types() {
      if (!this.betslipTypes) {
        return [
          {
            id: 0,
            key: 0,
            type: 3,
            value: 'single',
            label: this.translations.general_ticket_single,
            active: true,
          },
        ];
      }
      return this.betslipTypes;
    },
    betsCounter() {
      return this.ticket && this.ticket.length;
    },
  },
  methods: {
    ...mapActions({
      setTicketTypeActiveComponent: 'betslip/setTicketTypeActiveComponent',
    }),
  },
  beforeMount() {
    this.setTicketTypeActiveComponent(this.types[0]);
  },
};
</script>

<style lang="scss" scoped>
.betslip {
  background-color: var(--background-1);
  color: var(--text-primary-1);
  font-size: var(--font-size-medium, 14px);
  width: var(--betslip-min-width, 400px);
  height: 100vh;
  position: relative;
}
</style>
