<template>
  <div
      class="comboWrapper"
      ref="bet"
      :class="{
        deleteAnimation: isRemoved,
        scale: isBetAppeared,
        swipedLeftAnimation: isSwipedLeft,
        swipedRightAnimation: isSwipedRight,
      }"
    >
    <div :class="{ betOverlay: isBetAppeared }" />
    <div class="overlayLock" v-if="bet.locked">
      <i class="icon icon-lock" />
    </div>
    <div class="marketWrapper">
      <div class="market">{{ bet.market }}</div>
       <i class="icon icon-close" @click="handleRemoveBet(bet.id)"></i>
    </div>
    <div class="outcome">{{ bet.outcome }}</div>
    <div class="footer">
      <div class="roundNumber">{{ translations.general_round }} {{ bet.roundNumber }}</div>
      <div class="odds">{{ decimalFormatNumber(bet.odds) }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../utility/eventBus';

export default {
  name: 'BetslipBetCombo',
  props: {
    bet: {
      type: Object,
    },
  },
  data() {
    return {
      isBetAppeared: false,
      isRemoved: false,
      isSwipedRight: false,
      isSwipedLeft: false,
      removeTimeoutId: 0,
      betAppeardTimeoutID: 0,
    };
  },
  computed: {
    ...mapGetters('gamesBetslip', ['decimalFormatNumber']),
    ...mapGetters(['isDesktop']),
    ...mapGetters(['translations']),
  },
  methods: {
    ...mapActions('gamesBetslip', ['removeBet']),
    handleRemoveBet(betId) {
      this.isRemoved = true;

      if (this.removeTimeoutId) clearTimeout(this.removeTimeoutId);

      this.removeTimeoutId = setTimeout(() => {
        this.removeBet(betId);
        this.isRemoved = false;
        eventBus.$emit('BetRemoved');
      }, 250);
    },
  },
  mounted() {
    this.isBetAppeared = true;

    this.$refs.bet.scrollIntoView({ behavior: 'smooth' });

    this.betAppeardTimeoutID = setTimeout(() => {
      this.isBetAppeared = false;
    }, 600);
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/animations';

.comboWrapper {
  background-color: var(--card);
  height: 104px;
  padding: 8px;
  position: relative;

  .marketWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .market {
      color: var(--secondary);
      font-size: 16px;
      line-height: 20px;
    }

    .icon-close {
      font-size: 24px;
      color: var(--text-primary-2);
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .outcome {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 20px;
  }

  .footer {
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-section);
    padding: 6px 8px;
    height: 36px;
    font-size: 16px;

    .odds {
      font-weight: 700;
    }

    .roundNumber, .odds {
      line-height: 20px;
    }
  }

  &.scale {
    animation-name: scale;
    animation-duration: 0.6s;
    transition-timing-function: ease;
  }

  &.swipedRightAnimation {
    position: relative;
    animation-name: swipedRight;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &.swipedLeftAnimation {
    position: relative;
    animation-name: swipedLeft;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .betOverlay {
    animation-name: betAppear;
    animation-duration: 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition-timing-function: ease;
  }

  .overlayLock {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-lock);

    .icon-lock {
      font-size: 36px;
      color: var(--text-primary-2);
    }
  }
}

.deleteAnimation {
  animation-name: delete;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}

@media only screen and (min-width: 1910px) {
  .comboWrapper {
    height: 126px;

    .marketWrapper {
      .market {
        font-size: 20px;
        line-height: 22px;

      }

      .icon-close {
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 32px;
        line-height: 32px;
      }
    }

    .outcome, .footer {
      font-size: 20px;
    }

    .outcome {
      margin-bottom: 8px;
      line-height: 28px;
    }

    .footer {
      height: 48px;

      .roundNumber, .odds {
        line-height: 20px;
      }
    }
  }
}
</style>
