<template>
  <div class="scrollable">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Scrollable',
};
</script>

<style lang="scss" scoped>
.scrollable > * {
  overflow: overlay;
  scrollbar-width: none;
  scrollbar-color: var(--text-primary-3)
    var(--card);
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: var(--card);
}
::-webkit-scrollbar-thumb {
  background: var(--button);
  border-radius: 3px;
}
@-moz-document url-prefix() {
  .scrollable > * {
    overflow: auto;
  }
}
</style>
