<template>
  <div class="betslipEmpty">
    <img :src="emptyBetslip" alt="Empty Betslip">
    <p>{{ translations.general_tickets_empty_info }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import emptyBetslipImage from '../../../assets/empty-betslip-dark.png';

export default {
  name: 'BetslipEmpty',
  props: {
    bgImage: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['translations']),
    emptyBetslip() {
      return this.bgImage ?? emptyBetslipImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.betslipEmpty {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;

  p {
    font-size: 16px;
    margin-top: 30px;
    color: var(--text-primary-2);
  }
}
</style>
