<template>
  <div class="possibleWinWrapper">
    <div class="possibleWin">
      <div>
        {{ translations.general_possible_winning }}
      </div>
      <div>
        {{ possibleWinCombo.possibleWinnings }}
      </div>
    </div>
    <div class="payinTax" v-if="isPayinTaxActive">
      <div>
        {{ translations.general_payin_tax }}
      </div>
      <div>
        {{ possibleWinCombo.possiblePayinTax }}
      </div>
    </div>
    <div class="odds">
      <div>
        {{ translations.general_total_odds }}
      </div>
      <div>
        {{ totalOdds }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BetslipPossibleWinCombo',
  methods: {
    ...mapActions('gamesBetslip', ['calculatePossibleWinCombo']),
  },
  computed: {
    ...mapGetters('gamesBetslip', ['possibleWinCombo', 'totalOdds', 'taxes']),
    ...mapGetters(['translations']),
    isPayinTaxActive() {
      return !this.taxes.payin.hideTax;
    },
  },
  mounted() {
    this.calculatePossibleWinCombo();
  },
};
</script>

<style lang="scss" scoped>
.possibleWinWrapper {
  margin-top: 8px;
  margin-bottom: 8px;

  .possibleWin, .payinTax {
    margin-bottom: 2px;
  }

  .payinTax {
    color: var(--text-primary-2);
  }

  .payinTax, .odds {
    font-size: 12px;
  }

  .possibleWin, .payinTax, .odds {
    display: flex;
    justify-content: space-between;
  }
}
</style>
