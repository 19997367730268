<template>
  <div>
    <Transition name="notification">
        <div
          class="betslipNotification"
          v-if="isNotificationActive"
          :style="{ '--footerHeight': footerHeight }"
        >
          <Notification type="terminal" />
        </div>
    </Transition>
    <div
      class="comboFooterWrapper"
      ref="footer">
      <BetslipInput
        class="totalPayment"
        @change="handleTotalPaymentChange"
        @fieldFocus="setActiveTotalStakeInput"
        :label="translations.general_total_stake"
        labelPosition="right"
        :parentValue="totalPayment"
        type="terminal"
      />
      <BetslipPossibleWinCombo />
      <div class="footer">
        <BetslipDeleteButton type="terminal" class="deleteButton" />
        <BetslipButton
          class="payinButton"
          type="terminal"
          :isTerminal="true"
          :disabled="isPayinDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import BetslipButton from '../BetslipA/BetslipButton';
import BetslipDeleteButton from '../BetslipA/BetslipDeleteButton';
import BetslipPossibleWinCombo from './BetslipPossibleWinCombo';
import Notification from '../../Notifications/Notification';

export default {
  name: 'BetslipFooterCombo',
  components: {
    BetslipInput,
    BetslipButton,
    BetslipDeleteButton,
    BetslipPossibleWinCombo,
    Notification,
  },
  data() {
    return {
      stakeBoxTimeoutId: null,
    };
  },
  computed: {
    ...mapGetters('gamesBetslip', [
      'totalPayment',
      'isPayinButtonDisabled',
      'isPayinInProgress',
      'isTotalPaymentValid',
      'isUserBalanceInvalid',
      'numberOfBets',
    ]),
    ...mapGetters('notifications', ['isNotificationActive']),
    ...mapGetters(['translations']),
    isPayinDisabled() {
      return this.isPayinButtonDisabled
        || this.numberOfBets <= 0
        || this.isPayinInProgress
        || !this.isTotalPaymentValid
        || this.isUserBalanceInvalid;
    },
    footerHeight() {
      return `${this.$refs.footer.clientHeight}px`;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'setTotalPayment',
      'setActiveBetslipInput',
      'setIsStakeBoxVisible',
    ]),
    handleTotalPaymentChange(totalPayment) {
      if (Number(totalPayment) === Number(this.totalPayment)) return;

      this.setTotalPayment(totalPayment);
    },
    openStakeBox() {
      if (this.stakeBoxTimeoutId) clearTimeout(this.stakeBoxTimeoutId);

      this.stakeBoxTimeoutId = setTimeout(() => {
        this.setIsStakeBoxVisible(true);
      }, 200);
    },
    setActiveTotalStakeInput() {
      this.openStakeBox();
      this.setActiveBetslipInput('_totalPayment');
    },
  },
  mounted() {
    const target = document.getElementById('betslipDWrapper');
    target.style.setProperty('--footer-height', this.footerHeight);
  },
};
</script>

<style lang="scss" scoped>
.comboFooterWrapper {
  background-color: var(--background-2);
  padding: 8px;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 99;
  height: auto;

  .totalPayment {

    ::v-deep .betslip-input {
      width: 100%;
    }
  }

  .deleteButtonB {
    width: 100%;
  }

  .footer {
    display: flex;
    gap: 8px;

    .deleteButton {
      width: 120px;
    }

    .payinButton {
      width: 100%;
      flex: 2;
    }
  }
}

.betslipNotification {
  position: absolute;
  width: 100%;
  bottom: var(--footerHeight);
  z-index: 0;
}

.notification-enter-active {
  animation-name: notificationSlideUp;
  animation-duration: 0.33s;
  transition-timing-function: ease-out;
}

.notification-leave-active {
  animation-name: notificationSlideDown;
  animation-duration: 0.33s;
  transition-timing-function: ease-out;
}

@media only screen and (min-width: 1910px) {
  .comboFooterWrapper {
    height: 261px;
  }
}

@keyframes notificationSlideUp {
  from {
    bottom: calc(var(--footerHeight) - 40px);
  }

  to {
    bottom: var(--footerHeight);
  }
}

@keyframes notificationSlideDown {
   from {
    bottom: var(--footerHeight);
  }

  to {
    bottom: calc(var(--footerHeight) - 40px);
  }
}
</style>
