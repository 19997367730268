<template>
  <div class="betslip-header">
    <div class="tabs-container"
         v-if="isTabVisible">
      <div v-for="(tab, index) in tabs"
           :key="index"
           :class="{'active': isActive(tab), tab}"
           @click="setActiveTab(tab)">
        <span class="middle">
          {{ tab.name }}
        </span>
      </div>
    </div>
    <div class="label"
        v-else>
      {{ translations.general_betslip }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  map,
  find,
  assign,
  size,
} from 'lodash';

export default {
  name: 'BetslipHeader',
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0,
      activeTab: {},
    };
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
    }),
    isTabVisible() {
      return this.tabs && size(this.tabs) > 1;
    },
  },
  methods: {
    ...mapActions({
      setTicketType: 'betslip/setTicketType',
    }),
    setActiveTab(tab) {
      this.setTicketType(tab);
      this.activeTab = tab;
    },
    isActive(tab) {
      return tab.id === this.activeTab.id;
    },
  },
  created() {
    this.tabs = map(this.items, (item, i) => (assign({ id: i }, item))) || null;
    this.activeTab = find(this.tabs, { id: this.active }) || {};
    // this.setTicketType(this.activeTab);
  },
};
</script>

<style lang="scss" scoped>
.betslip-header {
  height: 40px;
  line-height: 38px;
  border-bottom: 1px solid #15181D;

  .label {
    display: flex;
    padding-left: 17px;
  }
  .tabs-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;

    .tab {
      flex: 1;
      text-align: center;
      cursor: pointer;
      border-right: 1px solid #15181D;
      transition: all .5s ease;

      &.active {
        // border-bottom: 2px solid var(--secondary, #ffc107);
        background-color: #252930;
        // color: var(--secondary, #ffc107);
      }

      &:hover {
        background-color: #252930;
      }
    }
  }
}
</style>
