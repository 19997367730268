<template>
  <div class="lock-betslip">
    <div class="lock-betslip-content">
      <i class="icon icon-lock-a"></i>
      <h3>{{blockers[0].message}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetslipLock',
  props: ['blockers'],
};
</script>

<style lang="scss" scoped>
  .lock-betslip {
    background-color: rgba(0, 0, 0, .4);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 3;
    .lock-betslip-content {
      color: #fff;
      font-size: 28px;
      margin: 0 auto;
      position: relative;
      top: 250px;
      width: 90%;
      i {
        color: #ffc107;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 28px;
        padding-top: 20px;
      }
    }
  }
</style>
