<template>
  <div class="betslip-ticket ticket-types">
    <TicketTypes :class="{ hidden: isHidden }" />
    <BetslipBody v-bind="$props"
                 :activeTicket="ticketTypeActiveComponent">
    </BetslipBody>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BetslipBody from './BetslipBody';
import TicketTypes from './TicketTypes';

export default {
  name: 'BetslipTicket',
  components: {
    BetslipBody,
    TicketTypes,
  },
  data() {
    return {
      activeComponent: this.ticketTypeActiveComponent,
    };
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
      ticketType: 'betslip/ticketType',
      ticketTypeComponents: 'betslip/ticketTypeComponents',
      ticketTypeActiveComponent: 'betslip/ticketTypeActiveComponent',
    }),
    isHidden() {
      if (this.types.length === 1) {
        return true;
      }
      return false;
    },
    types() {
      if (!this.betslipTypes) {
        return [
          {
            type: 3,
            name: this.translations.general_ticket_single,
            value: 'single',
            active: true,
          },
          {
            type: 1,
            name: this.translations.general_ticket_combo,
            value: 'combo',
            active: true,
          },
          {
            type: 2,
            name: this.translations.general_ticket_system,
            value: 'system',
            active: false,
          },
        ];
      }
      return this.betslipTypes;
    },
  },
  props: [
    'betslipTypes', 'imageBg', 'showNotification', 'hidePayinArea', 'futureOrientation', 'isPossibleWinActive',
  ],
  methods: {
    ...mapActions({
      setTicketType: 'betslip/setTicketType',
      setTicketTypeActiveComponent: 'betslip/setTicketTypeActiveComponent',
    }),
    updateTab(component) {
      this.setTicketType(component);
      this.setTicketTypeActiveComponent(component);
    },
  },
};
</script>

<style lang="scss" scoped>
.betslip-ticket {
  padding-top: 1px;
  height: calc(100% - 40px);

  .betslip-header {
    background: var(--background-1);
  }
  .ticket-types-wrapper {
    &.hidden {
      display: none;
    }
  }
}
</style>
