<template>
  <div class="select"
       :class="typeClass"
       @blur="open = false"
       v-clickout="handleClickOutside">
    <div class="select-input"
         :class="{ open: open }"
         @click="toggleSelect">
      <span class="label"
            :class="{single: !selected}"> {{label}}</span>
      <span :class="{value : label}"> {{inputValue}}</span>
      <i class="icon"
         :class="[open ? 'icon-arrow-up-a' : 'icon-arrow-down-a']">
      </i>
    </div>
    <component :is="currentComponent"
               :items="options"
               :is-open="open"
               :title="label"
               :class="typeClass"
               :selected-item="selected"
               :orientation="orientation"
               :type="type"
               @change="updateValue"
               @closeModal="handleModalClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clickout } from '../../directives';
import { disablable, typeable } from '../../mixins';
import SelectDesktop from './SelectDesktop';
import SelectMobile from './SelectMobile';

export default {
  name: 'Select',
  directives: {
    clickout,
  },
  components: {
    SelectDesktop,
    SelectMobile,
  },
  props: {
    name: {
      default: 'selectField',
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Select',
    },
    showFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    orientation: {
      type: String,
      required: false,
      default: 'bottom',
    },
    value: {
      default: null,
      type: [String, Number],
    },
  },
  mixins: [
    disablable,
    typeable,
  ],
  data() {
    return {
      selected: this.default || this.value,
      open: false,
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
    inputValue() {
      return this.value || this.selected;
    },
    currentComponent() {
      const baseName = 'Select';

      if (this.typeClass === 'type-terminal') {
        return `${baseName}Mobile`;
      }

      return !this.isDesktop ? `${baseName}Mobile` : `${baseName}Desktop`;
    },
  },
  methods: {
    updateValue(option) {
      this.open = false;
      this.selected = option;
      this.$emit('change', option);
    },
    toggleSelect() {
      this.open = !this.open;
    },
    close() {
      this.open = false;
    },
    handleModalClose() {
      this.close();
    },
    handleClickOutside() {
      this.close();
    },
  },
  beforeMount() {
    const topOrientation = this.orientation === 'top';
    if (topOrientation) {
      this.options.reverse();
    }
    if (this.showFirst) {
      const showFirst = topOrientation
        ? this.options[this.options.length - 1] : this.options[0] || null;
      const option = this.options.length > 0 ? showFirst : null;
      this.selected = this.selected || option;
    } else {
      this.selected = this.selected;
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  position: relative;
  width: var(--select-width, 150px);
  height: var(--select-height, 40px);
  color: var(--text-primary-1);
  line-height: var(--select-height, 40px);
  font-size: var(--font-size-default, 14px);
  text-align: left;
  outline: none;

  .label {
    position: absolute;
    left: 8px;
    top: -7.5px;
    font-size: 12px;
    opacity: 0.6;

    &.single {
      top: 0;
    }
  }

  .value {
    position: absolute;
    left: 8px;
    top: 8.5px;
  }

  &.type-terminal {
    height: 54px;
    line-height: 54px;

    .label {
      font-size: 16px;
      top: -8px;
    }
    .value {
      font-size: 18px;
      top: 10px;
      font-weight: 500;
    }

    .select-input i {
      top: calc(54px / 2 - 10px);
      right: 8px;
      font-size: 24px;
    }
  }
}

.select .select-input {
  height: inherit;
  background-color: var(--button);
  border-radius: 2px;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
  font-family: Roboto, 'sans-serif';
}

.select .select-input i {
  position: absolute;
  top: calc((var(--select-height, 40px) / 2) - 5px);
  right: 16px;
}

.select {
  .top-orientation {
    bottom: var(--select-height, 40px);
  }
  .bottom-orientation {
    top: var(--select-height, 40px);
  }
}

@media only screen and (min-width: 1910px) {
  .select {
    &.type-terminal {
      height: 72px;
      line-height: 72px;

      .label {
        font-size: 20px;
        top: -16px;
      }

      .value {
        font-size: 20px;
        font-weight: 500;
        top: 13px;
      }

      .select-input i {
        top: calc(72px / 2 - 10px);
        right: 8px;
        font-size: 24px;
      }
    }
  }
}
</style>
