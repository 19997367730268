<template>
  <div>
    <div class="ticket-history-item"
         v-for="ticket in ticketsHistory"
         :key="ticket.id">
      <LastTicketsBetItem :ticket="ticket"
                          :showFooter="false"
                          :receivedIsLastTicketsData="false"
                          :productNameLabel="productNameLabel"
                          :detailsMode="detailsMode" />
    </div>
  </div>
</template>

<script>
import LastTicketsBetItem from '../BetslipArea/LastTickets/LastTicketsBetItem';

export default {
  name: 'TicketsHistoryList',
  components: {
    LastTicketsBetItem,
  },
  props: {
    ticketsHistory: {
      type: Array,
      default: () => [],
    },
    dateFrom: {
      type: Date,
    },
    dateTo: {
      type: Date,
    },
    detailsMode: {
      type: String,
      default: 'standalone',
    },
    productNameLabel: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-history-item {
  margin-bottom: 2px;

  ::v-deep {
    .last-tickets-item {
      height: 100%;
      .body {
        .bet-item {
          height: 100%;
        }
      }
    }
  }
}

.ticket-history-item:last-child {
  margin-bottom: 0;
}
</style>
