<template>
  <div class="games-settings-wrapper">
    <Modal @closeModal="handleCloseModal"
           show-footer>
      <template v-slot:header>
        <div class="games-settings-title">
          {{ title }}
        </div>
      </template>
      <template v-slot:body>
        <div class="games-setting"
             v-for="(setting, index) in settings"
             :key="index">
          <input :id="setting.value"
                 type="checkbox"
                 v-model="selectedSettings"
                 :value="setting.value"
                 @change="handleChange" />
          <label class="games-setting-label"
                 :for="setting.value">
            <span class="icon games-setting-input" />
            <span class="games-setting-name">{{ setting.name }}</span>
          </label>
        </div>
      </template>
      <template v-slot:footer>
        <div class="games-settings-buttons">
          <button class="games-settings-button"
                  @click="handleCancelClick">
            {{ translations.general_cancel }}
          </button>
          <button class="games-settings-button"
                  @click="handleAcceptClick">
            {{ translations.general_accept }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';

import Modal from './Modal';

export default {
  name: 'GamesSettings',
  components: {
    Modal,
  },
  props: {
    settings: {
      type: Array,
      required: true,
    },
    selectedSettingsFromParent: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedSettings: [],
    };
  },
  computed: {
    ...mapGetters(['translations']),
  },
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },
    handleChange() {
      this.$emit('change', this.selectedSettings);
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    handleAcceptClick() {
      this.$emit('accept', this.selectedSettings);
    },
  },
  created() {
    if (!isNil(this.selectedSettingsFromParent)) {
      this.selectedSettings = this.selectedSettingsFromParent;
    }
  },
};
</script>

<style lang="scss" scoped>
.games-settings-wrapper {
  &::v-deep .modal .modal-header {
    padding-bottom: 38px;
  }

  &::v-deep .modal-back .modal .modal-header {
    padding: 0;
    padding-bottom: 38px;
  }

  &::v-deep .modal-back .modal {
    height: auto !important;
    max-height: 400px !important;
    padding: 24px;
  }

  &::v-deep .modal-back .modal .modal-footer {
    padding-top: 51px;
    position: static;
    padding-bottom: 0;
  }

  .games-settings-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-primary-1);
  }

  .games-setting {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
    }

    input[type="checkbox"]:checked {
      + .games-setting-label {
        .games-setting-input::before {
          content: "\e6a2";
          color: var(--secondary);
        }
      }
    }

    .games-setting-label {
      display: flex;
      align-items: center;
      width: fit-content;

      &:hover {
        cursor: pointer;
      }
    }

    .games-setting-input::before {
      content: "\e69f";
      color: var(--text-primary-2);
      font-size: 20px;
      margin-right: 10px;
    }

    .games-setting-name {
      font-size: 14px;
      color: var(--text-primary-1);
    }
  }

  .games-settings-buttons {
    .games-settings-button {
      border: none;
      text-transform: uppercase;
      background-color: transparent;
      margin-right: 43px;
      font-size: 14px;
      color: var(--secondary);

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
