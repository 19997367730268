<template>
  <div class="ticketWrapper">
    <Scrollable>
      <div
        class="betslipTicket"
        :class="{clearBetslip: isClearBetslip}"
        ref="ticket">
          <div class="bet" v-for="(bet, index) in ticket" :key="index">
            <BetslipBet :bet="bet" type="terminal" />
          </div>
      </div>
    </Scrollable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scrollable from '../../Scrollable';
import BetslipBet from './BetslipBet';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipTicket',
  data() {
    return {
      isClearBetslip: false,
      clientYStart: 0,
    };
  },
  components: {
    BetslipBet,
    Scrollable,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketTypes', 'ticket']),
    ...mapGetters(['translations']),
    ...mapGetters('notifications', ['isNotificationActive']),
    hasMultipleTicketTypes() {
      return this.activeTicketTypes.length > 1;
    },
    isTicketTypesComponentVisible() {
      return this.hasMultipleTicketTypes;
    },
  },
  mounted() {
    eventBus.$on('ClearBetslip', () => {
      this.isClearBetslip = true;

      setTimeout(() => {
        this.isClearBetslip = false;
      }, 300);
    });
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    eventBus.$off('ClearBetslip');
  },
  beforeDestroy() {
    eventBus.$off('ClearBetslip');
  },
};
</script>

<style lang="scss" scoped>
.ticketWrapper {
  overflow: hidden;
  color: var(--text-primary-1);

  div.betslipTicket {
    height: calc(100vh - var(--footer-height) - var(--ticket-type-height) - var(--header-height));
    font-size: 14px;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;

    &.clearBetslip {
      position: relative;
      animation-name: clear;
      animation-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    .bet {
      display: flex;
      flex-direction: column;
      margin-bottom: 2px;
      justify-content: center;
      min-height: 102px;
      border-radius: 2px;
      scroll-snap-align: start;
    }
  }
}

@keyframes clear {
  0% {
    position: relative;
    transform: translateX(0%);
  }
  100% {
    position: relative;
    transform: translateX(100%);
  }
}
</style>
