<template>
  <div class="betslipEmpty">
    <img :src="emptyBetslip" alt="Empty Betslip">
    <p>{{ translations.general_tickets_empty_info }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import emptyBetslipImage from '../../../assets/empty-betslip-dark.png';

export default {
  name: 'BetslipEmpty',
  props: {
    bgImage: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketTypes']),
    ...mapGetters('notifications', ['isNotificationActive']),
    ...mapGetters(['translations']),
    emptyBetslip() {
      return this.bgImage ?? emptyBetslipImage;
    },
    hasMultipleTicketTypes() {
      return this.activeTicketTypes.length > 1;
    },
    isTicketTypesComponentVisible() {
      return this.hasMultipleTicketTypes;
    },
  },
};
</script>

<style lang="scss" scoped>
.betslipEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  height: calc(100vh - var(--footer-height) - 72px - var(--ticket-type-height));

  p {
    font-size: 16px;
    margin-top: 30px;
    color: var(--text-primary-2);
  }
}

@media only screen and (min-width: 1910px) {
  .betslipEmpty {
    p {
      font-size: 20px;
    }
  }
}
</style>
