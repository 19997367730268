<template>
  <div class="checkbox"
       :class="[typeClass, shapeClass]">
    <input type="checkbox"
           v-model="checked"
           :disabled="disabled"
           @change="onChange">
    <label @click="toggle">
      {{label}}
    </label>
  </div>
</template>

<script>
import { disablable, typeable, shapeable } from '../mixins';

export default {
  name: 'Checkbox',
  emits: ['input'],
  mixins: [
    disablable,
    typeable,
    shapeable,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Checkbox',
      required: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.checked);
    },
    toggle() {
      this.checked = !this.checked;
      this.$emit('input', this.checked);
    },
  },
  beforeMount() {
    this.checked = this.value;
  },
};
</script>

<style lang="scss">
.checkbox {
  $box-size-small: 22px;
  $box-size-medium: 24px;
  $box-size-large: 32px;
  $box-spacing: 10px;

  display: inline-block;
  position: relative;
  font-size: 1em;

  &:not([disabled]) {
    cursor: pointer;

    input,
    label {
      cursor: pointer;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    width: $box-size-small;
    height: $box-size-small;
    z-index: 100;
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: $box-size-small + $box-spacing;
    height: $box-size-small;
    line-height: $box-size-small;
    vertical-align: middle;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: $box-size-small;
      height: $box-size-small;
      content: "";
      border-radius: 2px;
    }

    &:after {
      position: absolute;
      display: none;
      box-sizing: border-box;
      content: "";
      top: 1px;
      left: 7px;
      width: 8px;
      height: 16px;
      transform: rotate(45deg);
      color: var(--text-primary-1);
    }
  }

  input + label:before {
    border: 1px solid var(--button);
  }

  input:checked + label:before {
    animation-name: none;
    border: var(--button);
    background: var(--button);
  }

  input:checked + label:after {
    display: block;
  }

  input + label:after {
    border-right: 2px solid var(--text-primary-1, #e6e6e6);
    border-bottom: 2px solid var(--text-primary-1, #e6e6e6);
  }

  &.shape-circle {
    label:before {
      border-radius: 100%;
    }
  }

  &.type-web {
    input {
      width: $box-size-medium;
      height: $box-size-medium;
    }

    label {
      height: $box-size-medium;
      line-height: $box-size-medium;
    }
  }

  &.type-terminal {
    input {
      width: $box-size-medium;
      height: $box-size-medium;
    }

    label {
      height: $box-size-medium;
      line-height: $box-size-medium;
    }
  }

  &.type-mobile {
    input {
      width: $box-size-small;
      height: $box-size-small;
    }

    label {
      padding-left: $box-size-small + $box-spacing;
      height: $box-size-small;
      line-height: $box-size-small;

      &:before {
        width: $box-size-small;
        height: $box-size-small;
      }

      &:after {
        left: 8px;
        width: 9px;
        height: 17px;
      }
    }
  }
}
</style>
