<template>
  <div>
    <LastTickets
      :isBallDrawn="isBallDrawn"
      :productNameLabel="productNameLabel"
    />
  </div>
</template>

<script>
import LastTickets from '@/components/BetslipArea/LastTickets/LastTicketsWrapper';

export default {
  name: 'BetslipHistory',
  components: {
    LastTickets,
  },
  props: {
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
  },
};
</script>
