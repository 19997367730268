import * as Sentry from '@sentry/vue';

export default {
  start(config) {
    Sentry.init({
      environment: process.env.NODE_ENV,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 0.15,
      ...config,
    });
  },
};
