<template>
  <div v-if="isOpen"
       class="quickpick-terminal-wrapper">
    <Modal width="344px"
           height="100%"
           showFooter
           @closeModal="handleCloseModal"
           @closeModalOutside="handleCloseModal"
    >
      <template v-slot:header>
        <h1 class="quickpick-title">
          {{ title }}
        </h1>
      </template>
      <template v-slot:body>

        <div class="quickpick-items">
          <div class="quickpick-item"
               v-for="(item, index) in items"
               :key="index">
            <input :id="item.value"
                   v-model="selectedItem"
                   type="radio"
                   :value="item.value">
            <label :for="item.value">
              <i class="icon quickpick-icon" />
              <span class="quickpick-radio-button">
                {{ itemLabel(item.label) }}
              </span>
            </label>
          </div>
        </div>

      </template>
      <template v-slot:footer>
        <button class="quickpick-cancel"
                @click="handleCloseButton">{{ cancelButtonLabel }}</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import QuickpickMobile from './QuickpickMobile';

export default {
  name: 'QuickpickTerminal',
  extends: QuickpickMobile,
};
</script>

<style lang="scss" scoped>
.quickpick-terminal-wrapper {
  .quickpick-title {
    font-size: 24px;
    color: var(--text-primary-1);
    margin: 0;
  }

  .quickpick-items {
    margin-top: 38px;

    .quickpick-item {
      color: var(--text-primary-1);
      font-size: 20px;
      margin-bottom: 54px;

      input,
      label {
        cursor: pointer;
      }

      .quickpick-icon::before {
        content: "\e9a7";
        font-size: 24px;
      }

      label {
        display: flex;
        align-items: center;
        width: 100%;

        .icon {
          color: var(--text-primary-2);
        }

        span {
          margin-left: 8px;
        }
      }

      input[type="radio"] {
        visibility: hidden;
        position: absolute;
      }

      input[type="radio"]:checked {
        + label .quickpick-icon::before {
          content: "\e6b2";
          color: var(--secondary);
        }
      }
    }

    .quickpick-item:last-child {
      margin-bottom: 0;
    }
  }

  .quickpick-cancel {
    font-size: 20px;
    border: none;
    text-transform: uppercase;
    background-color: transparent;
    color: var(--secondary);
    cursor: pointer;
  }

  .modal-back::v-deep {
    padding: 36px 8px;
  }

  .modal-back::v-deep {
    .modal {
      .modal-header {
        padding: 24px 24px 0;
      }

      .modal-body {
        overflow: auto;
        scrollbar-width: none;
        scrollbar-color: var(--text-primary-3)
          var(--card);
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: var(--card);
        }
        &::-webkit-scrollbar-thumb {
          background: var(--button);
          border-radius: 3px;
        }
        height: auto;
        padding-left: 26px;
      }

      .modal-footer {
        position: static;
        margin-top: auto;
        padding: 42px 29px 27px 0;
      }
    }
  }
}
</style>
