/**
 * Find divisor need inside convert to fractional
 * @param numb
 * @param multiplire
 */
const findDivisor = (numb, multiplire) => {
  let divisor;
  let i = 1;
  const max = numb > multiplire ? numb : multiplire;
  while (i < max) {
    if (numb % i === 0 && multiplire % i === 0) {
      divisor = i;
    }
    i += 1;
  }
  return divisor;
};
const convertToAmerican = (odd) => {
  let value;
  if (odd >= 2.00) {
    value = `+${parseInt(`${Math.round(((odd - 1) * 100) * 10) / 10}`, 10)}`;
  } else {
    value = Math.round(((-100) / (odd - 1)) * 10) / 10;
    value = parseInt(value, 10);
  }
  return value;
};
const convertToFractional = (odd) => {
  const stringOdd = odd.toString();
  const indexOfPoint = stringOdd.indexOf('.');
  const decimals = (indexOfPoint > -1) ? stringOdd.substring(indexOfPoint + 1).length : 1;
  const multiplier = 10 ** decimals;
  const number = Math.round((odd - 1) * multiplier);
  const divisor = findDivisor(number, multiplier);
  return `${number / divisor}/${multiplier / divisor}`;
};
/**
 * Transform number into number with 2 decimal
 * @param value
 * @returns {string}
 */
const formatNumber = value => parseFloat(value).toFixed(2);
/**
 * Transform odd to proper type
 * @param value
 * @returns {string}
 */
const oddConverter = (value, oddType = 1) => {
  let parsedValue;
  if (oddType === 3) {
    parsedValue = convertToAmerican(value);
  } else if (oddType === 2) {
    parsedValue = convertToFractional(value);
  } else {
    parsedValue = formatNumber(value);
  }
  return parsedValue;
};
export default oddConverter;
