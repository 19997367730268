<template>
  <div class="possible-win-wrapper">
    <div v-if="isPossibleWinActive"
         class='possible-win'>
      <span> {{ translations.general_possible_winning }} </span>
      <span>{{possibleWin && possibleWin.rounded}}</span>
    </div>
    <div class="tax-wrapper">
      <div v-if="isPayinTaxActive && showTax && payinTax.taxAmountRounded !== 0">
        <span> {{ translations.general_payin_tax }} </span>
        <span>{{(payinTax && payinTax.taxAmountRounded).toFixed(2)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import possibleWin from '@/mixins/betslip/possibleWin';

export default {
  name: 'PossibleWin',
  props: ['isPossibleWinActive'],
  data() {
    return {
      showTax: true,
    };
  },
  mixins: [possibleWin],
  computed: {
    ...mapGetters({
      translations: 'translations',
    }),
  },
};
</script>

<style scoped lang="scss">
.possible-win-wrapper {
  padding-top: 10px;
  padding-bottom: 8px;

  .possible-win {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .tax-wrapper {
    padding-top: 5px;
    opacity: 0.6;
    font-size: 12px;
    color: var(--text-primary-1);
    line-height: 12px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
