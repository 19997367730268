<template>
  <div class="betslipBody" :style="{ height: isMobile && mobileBetslipHeight }" ref="body">
    <CircleLoader :loader="isPayinInProgress" class="circle-loader"></CircleLoader>
    <BetslipTicket v-if="isTicketVisible" />
    <BetslipEmpty :bgImage="bgImage" v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BetslipTicket from './BetslipTicket';
import BetslipEmpty from './BetslipEmpty';
import CircleLoader from '../../CircleLoader';


export default {
  name: 'BetslipBody',
  props: {
    bgImage: {
      type: String,
      default: null,
    },
  },
  components: {
    BetslipTicket,
    BetslipEmpty,
    CircleLoader,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['numberOfBets', 'isPayinInProgress', 'isCombo', 'mobileBetslipHeight']),
    ...mapGetters(['isMobile']),
    isTicketVisible() {
      return this.numberOfBets > 0;
    },
    stakeBoxBottom() {
      const footerSingleHeight = getComputedStyle(document.body).getPropertyValue('--footer-single-height');
      const footerComboHeight = getComputedStyle(document.body).getPropertyValue('--footer-combo-height');

      if (this.isCombo) {
        return `${footerComboHeight}px`;
      }

      return `${footerSingleHeight}px`;
    },
    desktopHeight() {
      const footerSingleHeight = getComputedStyle(document.body).getPropertyValue('--footer-single-height');
      const footerComboHeight = getComputedStyle(document.body).getPropertyValue('--footer-combo-height');

      if (this.isCombo) {
        return `${footerSingleHeight - footerComboHeight}px`;
      }

      return '0px';
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.body.style.setProperty('--footer-difference', this.desktopHeight);
    });

    this.$refs.body.style.setProperty('--stakebox-bottom', this.stakeBoxBottom);
  },
  mounted() {
    this.$refs.body.style.setProperty('--footer-difference', this.desktopHeight);

    this.$nextTick(() => {
      this.$refs.body.style.setProperty('--stakebox-bottom', this.stakeBoxBottom);
    });
  },
};
</script>

<style lang="scss" scoped>
.betslipBody {
  height: calc(352px + var(--footer-difference));
  background-color: var(--background-1);
}
</style>
