<template>
  <div class="betslip-body"
       v-if="activeTicket && activeTicket.active">
    <div v-if="numOfBets === 0"
         v-swipeDown="swipeHandler"
         class="empty"
         :style="{ backgroundImage: 'url(' + getImageBg + ')',
          minHeight: getEmptyHeight }">
      <span class="message">
        {{ translations.general_ticket_empty_message }}
      </span>
    </div>
    <Scrollable v-else>
      <CircleLoader class="circle-loader"
                    :loader="ticketPayinInProgress"></CircleLoader>
      <div class="betslip-bets"
        :style="{ height: getHeight }"
        :class="{clearBets: clearBets}">
        <div v-for="(bet, i) in tickets"
             :key="i">
          <BetslipBet :bet="bet" />
        </div>
      </div>
    </Scrollable>
    <div class="notification-wrapper">
      <!-- The insufficient funds notification
      has precedence over all the others -->
      <Notification
        v-if="showInsufficientFundsNotification  && showNotification"
        :notification="insufficientFundsNotification"
        :use-store="false"
      />
      <Notification v-else-if="notifications && showNotification" />
    </div>
    <BetslipFooter :isFutureActive="isFutureActive"
                   :futureOrientation="futureOrientation"
                   :isPossibleWinActive="isPossibleWinActive"
                   :hidePayinArea="hidePayinArea" />
  </div>
  <div class="betslip-body not-active"
       v-else>
    <!-- TODO -->
    This option is not available currently
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '@/components/Notifications/Notification';
import Scrollable from '@/components/Scrollable';
import CircleLoader from '@/components/CircleLoader';
import ticket from '@/mixins/betslip/ticket';
import BetslipBet from './BetslipBet';
import BetslipFooter from './BetslipFooter';
import { eventBus } from '../../../utility';
import swipeDown from '../../../directives/swipeDown';

export default {
  name: 'BetslipBody',
  components: {
    BetslipBet,
    BetslipFooter,
    Notification,
    Scrollable,
    CircleLoader,
  },
  data() {
    return {
      clearBets: false,
      insufficientFundsNotification: {
        value: this.$store.getters.translations.player_balance_insufficient,
        status: 'negative',
      },
      swipeHandler: this.swipeDown,
    };
  },
  directives: {
    swipeDown,
  },
  mixins: [ticket],
  computed: {
    ...mapGetters({
      numOfBets: 'betslip/numOfBets',
      notifications: 'notifications/notification',
      showInsufficientFundsNotification: 'notifications/showInsufficientFundsNotification',
      ticketPayinInProgress: 'betslip/ticketPayinInProgress',
      mobileBetslipBodyHeight: 'betslip/mobileBetslipBodyHeight',
      betslipFooterHeight: 'betslip/betslipFooterHeight',
      ticketTypeComponents: 'betslip/ticketTypeComponents',
      translations: 'translations',
      isFutureActive: 'betslip/isFutureActive',
      isDesktop: 'isDesktop',
    }),
    getImageBg() {
      return this.imageBg || require('../../../assets/empty-betslip-dark.png');
    },
    getHeight() {
      if (this.isMobile) {
        return `calc(100vh - ${this.betslipFooterHeight}px - 56px - 40px)`;
      }
      return this.ticketTypeComponents.length === 1 ? `${479 - this.betslipFooterHeight}px` : '352px';
    },
    getEmptyHeight() {
      if (this.isMobile) {
        return `calc(100vh - ${this.betslipFooterHeight}px - 56px - 40px)`;
      }
      return this.ticketTypeComponents.length === 1 ? `${479 - this.betslipFooterHeight}px` : '352px';
    },
  },
  props: [
    'activeTicket', 'showNotification', 'imageBg', 'hidePayinArea', 'futureOrientation', 'isPossibleWinActive',
  ],
  methods: {
    ...mapActions({
      toogleBetslip: 'betslip/showBetslip',
    }),
    swipeDown() {
      if (!this.isDesktop) {
        this.toogleBetslip();
      }
    },
  },
  mounted() {
    this.$root.$on('ClearBets', () => {
      this.clearBets = true;
      setTimeout(() => {
        this.clearBets = false;
      }, 300);
    });
    eventBus.$on('ClearBets', () => {
      this.clearBets = true;
      setTimeout(() => {
        this.clearBets = false;
      }, 300);
    });
  },
};
</script>

<style scoped lang="scss">
.betslip-body {
  overflow: hidden;
  .empty {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--background-1);
    background-position: center 30%;
    background-repeat: no-repeat;

    .message {
      position: absolute;
      transform: translate(-50%, 60%);
      top: 60%;
      left: 50%;
      color: var(--text-primary-3);
      text-align: center;
      font-size: 1em;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  .betslip-bets {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--background-1);
    min-width: var(--betslip-min-width, 300px);
    overflow-x: hidden;
    &.clearBets {
      position: relative;
      animation-name: closeAnimation;
      animation-duration: 0.3s;
      transition-timing-function: ease-out;
    }
  }

  &.not-active {
    display: flex;
    flex: 1;
    height: 410px;
    justify-content: center;
    align-items: center;
    background-color: var(--background-1);
  }
  @keyframes closeAnimation {
    0% {
      position: relative;
      transform: translateX(0%);
    }
    100% {
      position: relative;
      transform: translateX(100%);
    }
  }
}
</style>
