<template>
  <Button v-if="isLoginButtonActive"
          variation="positive"
          class="betslipButton login-payin"
          :type="type"
          :label="translations.general_login"
          @change="login()"
  ></Button>
  <Button v-else-if="hasTicketLockedBets"
          variation="positive"
          class="betslipButton"
          :type="type"
          :label="translations.general_accept"
          @change="removeInvalidBets()"
  ></Button>
  <Button v-else
          :type="type"
          class="login-payin"
          :class="{'free-bet': isFreeBetMode}"
          variation="positive"
          :label="payinButtonLabel"
          @change="payin()"
          :disabled="disabled"
  >
  </Button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '../../Button';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isTerminal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['hasTicketLockedBets', 'ticket']),
    ...mapGetters(['translations', 'isFreeBetMode']),
    ...mapGetters('user', ['isLoggedIn']),
    isLoginButtonActive() {
      return !this.isLoggedIn && !this.isTerminal;
    },
    payinButtonLabel() {
      if (this.isFreeBetMode) {
        return this.translations.general_free_bet;
      }
      return this.translations.general_pay_in;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['removeLockedBets']),
    login() {
      eventBus.$emit('LoginRequired');
    },
    payin() {
      eventBus.$emit('TicketPayin');
    },
    removeInvalidBets() {
      eventBus.$emit('RemoveInvalidBets');

      setTimeout(async () => {
        await this.removeLockedBets();
        eventBus.$emit('formatBetslipInputValue');
      }, 300);
    },
  },
};
</script>
