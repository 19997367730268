<template>
  <div
    class="notification"
    v-if="message"
    :class="[typeClass, { callbackDefined: isCallbackDefined }]"
    @click="invokeCallback"
  >
    <slot name="icon" />
    <span :class="{type: message !== '', [status]: status}">
      <i class="icon"
         :class="`icon-${getIconStatus()}`">
      </i>
      {{ message }}
    </span>
    <slot name="message" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isNil } from 'lodash';
import { typeable } from '../../mixins';

export default {
  name: 'Notification',
  mixins: [typeable],
  props: {
    notification: {
      type: Object,
      default: () => ({
        value: '',
        status: '',
      }),
    },
    useStore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      notify: 'notifications/notification',
      callback: 'notifications/callback',
    }),
    data() {
      if (!this.useStore) {
        return this.notification;
      }
      return this.notify;
    },
    message() {
      return this.data && this.data.value;
    },
    status() {
      return this.data && this.data.status;
    },
    isCallbackDefined() {
      return !isNil(this.callback);
    },
  },
  methods: {
    getIconStatus() {
      let icon = '';
      const status = this.data && this.data.status;

      switch (status) {
        case 'negative':
          icon = 'void';
          break;
        case 'neutral':
          icon = 'status-b';
          break;
        case 'positive':
          icon = 'check-a';
          break;
        case 'bonus':
          icon = 'check-a';
          break;
        default:
          icon = '';
          break;
      }

      return icon;
    },
    invokeCallback() {
      if (!isNil(this.callback)) this.callback();
    },
  },
};
</script>

<style scoped lang="scss">
.notification {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: var(--text-primary-1);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &.callbackDefined {
    cursor: pointer;
  }

  .type {
    display: flex;
    padding: 12px;
    border-radius: 2px;
    height: 100%;
    align-items: center;

    .icon {
      margin-right: 8px;
    }

    &.negative {
      background: var(--system-negative);
      color: #fff;
    }

    &.positive {
      background: var(--system-positive, #388e3c);
      color: #fff;
    }

    &.neutral {
      background: var(--system-neutral, #ffc107);
      color: #000;
    }

    &.bonus {
      background: #90B653;
      color: var(--background-1);
    }
  }

  &.type-terminal {
    font-size: 16px;
    height: 54px;

    .type {
      padding: 17px;
    }

    .icon {
      padding-right: 16px;
      margin-right: 16px;
      border-right: 1px solid var(--text-primary-1);
    }
  }
}
@media all and (max-width: 420px) {
  .notification {
    font-size: 12px;
    font-weight: normal;
  }
}

@media only screen and (min-width: 1920px) {
  .notification {
    &.type-terminal {
      font-size: 20px;
      height: 72px;

      .type {
        padding: 17px;
      }

      .icon {
        padding-right: 16px;
        margin-right: 16px;
        border-right: 1px solid var(--text-primary-1);
      }
  }
  }
}
</style>
