<template>
  <div class="color-checkbox-list"
       :style="setOrientationCssVariables">
    <div class="color-checkbox-item"
         :class="setOrientationClass"
         v-for="(colorCheckBox, index) in length"
         :key="index">
      <ColorCheckbox @input="onInput($event, index)"
                     :class="[typeClass]"
                     :active="colorCheckBoxIndex === index && !!value.color"
                     :color="listOfColors[index]" />
    </div>
  </div>
</template>

<script>
import { findIndex } from 'lodash';
import { typeable } from '../mixins';
import ColorCheckbox from './ColorCheckbox';

export default {
  name: 'ColorCheckboxList',
  mixins: [typeable],
  components: {
    ColorCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    listOfColors: {
      type: Array,
      default: () => [],
    },
    length: {
      type: Number,
      default: 8,
    },
    orientation: {
      type: String,
      default: 'horizontal',
    },
  },
  data() {
    return {
      colorCheckBoxIndex: null,
    };
  },
  computed: {
    setOrientationCssVariables() {
      return this.orientation === 'horizontal'
        ? { '--flex-direction': 'row', '--margin': '14px' }
        : { '--flex-direction': 'column', '--margin': '12.5px' };
    },
    setOrientationClass() {
      return this.orientation === 'horizontal'
        ? 'color-checkbox-list-horizontal'
        : 'color-checkbox-list-vertical';
    },
  },
  methods: {
    onInput(event, index) {
      this.toggleColorCheckbox(index);
      this.$emit('input', event);
    },
    toggleColorCheckbox(index) {
      if (this.colorCheckBoxIndex === index) {
        this.colorCheckBoxIndex = null;
      } else {
        this.colorCheckBoxIndex = index;
      }
    },
  },
  watch: {
    value(newVal) {
      if (newVal.checked) {
        this.colorCheckBoxIndex = findIndex(this.listOfColors,
          color => color === newVal.color);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-checkbox-list {
  display: flex;
  flex-direction: var(--flex-direction);
  justify-content: center;
}
</style>
