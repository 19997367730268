<template>
  <div class="regulatory-message-wrapper">
    <i class="icon icon-info"></i>
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'RegulatoryMessage',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.regulatory-message-wrapper {
  width: 100%;
  background: var(--background-1);
  height: 40px;
  float: left;
  border-radius: 3px;
  color: var(--text-primary-1);
  padding: 12px;
  position: relative;
  z-index: 0;

  i {
    margin-right: 0.5em;
    font-size: 1em;
    float: left;
  }

  span {
    font-size: 0.875em;
    float: left;
  }

}
</style>
