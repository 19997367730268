<template>
  <div v-if="detailsMode === 'standalone'"
        class="left-wrapper">
    <div class="left">
      <div class="date-range">{{ translations.general_date_range }}</div>
      <DatePicker
        v-model="range"
        :masks="masks"
        :locale="locale"
        is-range
        class="date-picker-component"
        :popover="popover">
        <template v-slot="{ showPopover, inputValue, inputEvents }">
          <div class="date-picker-wrapper">
            <div class="date-picker">
              <Input
                class="date-picker-input left"
                inputType="text"
                :value="inputValue.start"
                v-on="inputEvents.start"
                :shouldUpdateValueOnBlur="false"
                :shouldSelectText="false"
                :readonly="true"
                @click="positionCalendar('startDate')"/>
              <span class="dash">{{ "-" }}</span>
              <Input
                class="date-picker-input right"
                inputType="text"
                :value="inputValue.end"
                v-on="inputEvents.end"
                :shouldUpdateValueOnBlur="false"
                :shouldSelectText="false"
                :readonly="true"
                @click="positionCalendar('endDate')"/>
              <i class="icon icon-calendar" @click="[showPopover(), positionCalendar('startDate')]" />
            </div>
          </div>
        </template>
      </DatePicker>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DatePicker } from 'v-calendar';
import Input from '../Input';

export default {
  name: 'TicketsHistoryFilter',
  components: {
    DatePicker,
    Input,
  },
  props: {
    dateFrom: {
      type: Date,
      default: () => new Date(),
    },
    dateTo: {
      type: Date,
      default: () => new Date(),
    },
    detailsMode: {
      type: String,
      default: 'standalone',
    },
    locale: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      masks: {
        input: 'DD.MM.YYYY',
      },
      range: {
        start: this.dateFrom,
        end: this.dateTo,
      },
      popover: {
        visibility: 'focus',
      },
    };
  },
  computed: {
    ...mapGetters([
      'translations',
    ]),
  },
  updated() {
    this.$emit('dateRange', this.range);
  },
  methods: {
    positionCalendar(position) {
      const popover = document.querySelector('.vc-popover-content-wrapper');
      popover.classList.toggle('popover-position-end', position !== 'startDate');
    }
  },
};
</script>

<style lang="scss">
.left-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--text-primary-3);
  margin-bottom: 16px;
  width: 60%;
  float: left;
  padding-bottom: 2px;
  position: relative;

  .icon-calendar {
    color: var(--text-primary-1);
    font-size: 18px;
    opacity: 0.6;
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .popover-position-end{
      margin-left: 80px !important;
    }

    .vc-popover-content {
      border: none;
      background-color: var(--card);
    }

    .date-picker-component {
      width: 100%;
    }

    .date-range {
      font-size: 12px;
      color: var(--text-primary-1);
      opacity: 0.6;
    }

    .date-picker-wrapper {
      .date-picker {
        color: var(--text-primary-1);
        display: flex;
        flex-direction: row;
        align-items: center;

        * {
          user-select: none;
        }

        .input-container {
          width: 70px;
        }

        .date-picker-input {
          outline: none;
          border: none;
          background-color: var(--background-1);
          color: var(--text-primary-1);
          font-size: 14px;
          height: auto;

          &.left {
            width: 72px;
            background-color: transparent;
          }
          &.right {
            width: 72px;
            background-color: transparent;
          }

          input {
            width: 100%;
            height: 18px;
            padding: 0;
            background-color: var(--background-1);
          }
        }

        .dash {
          margin-left: 2px;
          margin-right: 2px;
        }

        &:last-child {
          width: 100%;
          z-index: 100;
          background-color: transparent;

          .input {
            background-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
    .vc-popover-container {
      color: var(--system-neutral);
      background-color: var(--card);
    }
    .vc-nav-popover-container{
      color: var(--text-primary-1);
    }
    .vc-nav-title:hover {
      color: var(--text-secondary-1);
    }
    .vc-nav-arrow {
      color: var(--text-primary-1);
      &:hover {
        background-color: var(--system-neutral);
        color: var(--text-secondary-1);
      }
    }
    .vc-nav-item {
      color: var(--text-primary-1);
      &:hover {
          color: var(--text-secondary-1);
        }
    }
    .is-active {
      color: var(--text-secondary-1);
    }
    .vc-container {
      background-color: var(--card);
      border-color: var(--card);
      --blue-100: var(--system-neutral);
      --blue-200: var(--system-neutral);
      --blue-500: var(--system-neutral);
      --blue-600: var(--system-neutral);
      --blue-700: var(--system-neutral);
      --blue-900: var(--text-secondary-1);
      --white: var(--text-secondary-1);
      --gray-900: var(--system-neutral);
      .vc-pane {
        .vc-header {
          display: flex;
          justify-content: center;
        }
      }
      .vc-title {
        color: var(--text-primary-1);
        font-size: 16px;
      }
      .vc-weekday {
        color: var(--text-primary-2);
        font-size: 12px;
      }
      .vc-arrow {
        color: var(--text-primary-1);
        &:hover {
          background-color: var(--system-neutral);
          color: var(--text-secondary-1);
        }
      }
      .vc-day-content {
        font-size: 12px;
        &:hover {
          background-color: var(--system-neutral);
        }
        &:focus {
          background-color: var(--system-neutral);
        }
      }
      .vc-focusable {
        color: var(--text-primary-1);
        &:hover {
          color: var(--text-secondary-1);
        }
      }
      .vc-highlight{
        background-color: var(--system-neutral) !important;
      }
      .vc-blue{
        color: var(--text-secondary-1) !important;
      }
    }
  }
}
.left-wrapper .left .vc-container .vc-title {
  text-transform: capitalize;
}
.left-wrapper .left .vc-container .vc-weekday {
  text-transform: uppercase;
}

@media (max-width: 660px) {
  .right-wrapper {
    width: 100%;
  }
}
</style>
