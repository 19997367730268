<template>
  <!-- Show Login button -->
  <Button v-if="!isLoggedIn && !isTerminalUser"
          variation="positive"
          class="button-active login-payin"
          :label="translations.general_login"
          @change="loginUser()"
          :disabled="loginBtnDisabled">
  </Button>
  <!-- Show Accept Button -->
  <Button v-else-if="!isTicketValid"
          variation="positive"
          class="login-payin"
          :label="translations.general_accept"
          @change="[removeInvalidBets(), accepted()]">
  </Button>
  <!-- Show Payin Button -->
  <Button v-else
          class="button-active login-payin"
          :class="{'free-bet': isFreeBetActive}"
          variation="positive"
          :label="payinButtonLabel"
          @change="ticketPayin()"
          :disabled="isPayinBtnActive || isFreeBetButtonDisabled">
  </Button>
</template>

<script>
import { isString, size } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/Button';
import { eventBus } from '../../utility';

export default {
  name: 'LoginPayinButton',
  data() {
    return {
      loginBtnDisabled: false, // TODO ? do we need this
    };
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters({
      selectedBet: 'betslip/selectedBet',
      isLoggedIn: 'user/isLoggedIn',
      isTerminalUser: 'user/isTerminalUser',
      isTicketValid: 'betslip/isTicketValid',
      isPayinBtnDisabled: 'betslip/isPayinBtnDisabled',
      isFreeBetButtonDisabled: 'betslip/isFreeBetButtonDisabled',
      isQuickpayEnabled: 'betslip/isQuickpayEnabled',
      tickets: 'betslip/tickets',
      translations: 'translations',
      balance: 'user/balance',
      payment: 'betslip/payment',
      isBetValid: 'betslip/isBetValid',
      isFreeBetAllowed: 'betslip/isFreeBetAllowed',
      user: 'user/user',
    }),
    isPayinBtnActive() {
      if (this.user.bonuses.length && this.isFreeBetActive) {
        return false;
      }
      if (this.isQuickpayEnabled) {
        return (!this.isBetValid) || ((!this.userHasFunds) || (this.isPayinBtnDisabled
          || (this.tickets.length === 0 && (isString(this.selectedBet)
            || size(this.selectedBet) === 0))));
      }

      return !this.userHasFunds || this.isPayinBtnDisabled || this.tickets.length === 0;
    },
    userHasFunds() {
      return this.balance >= this.payment;
    },
    isFreeBetActive() {
      if (this.isQuickpayEnabled) {
        return this.isFreeBetAllowed && this.isBetValid;
      }
      return this.isFreeBetAllowed && !!this.tickets.length;
    },
    payinButtonLabel() {
      if (this.isFreeBetActive) {
        return this.translations.general_free_bet;
      }
      return this.translations.general_pay_in;
    },
  },
  methods: {
    ...mapActions({
      removeInvalidBets: 'betslip/removeInvalidBets',
    }),
    ticketPayin() {
      if (this.isTerminalUser) {
        this.$root.$emit('TerminalTicketPayin');
        eventBus.$emit('TerminalTicketPayin');
      } else {
        this.$root.$emit('TicketPayin');
        eventBus.$emit('TicketPayin');
      }
    },
    loginUser() {
      // Inform parent aplication (game) about user login
      this.$root.$emit('LoginRequired');
      eventBus.$emit('LoginRequired');
    },
    accepted() {
      this.$root.$emit('InvalidBetsAccepted');
      eventBus.$emit('InvalidBetsAccepted');
    },
  },
};
</script>
