<template>
  <div class="virtual-keyboard" :class="typeClass">
    <div class="keyboard-button"
         @click="selectButton(button)"
         v-for="(button, index) in keyboardButtons"
         :key="index">
      <span v-if="button || button === 0">{{ button }}</span>
    </div>
  </div>
</template>

<script>
import { typeable } from '../mixins';

export default {
  name: 'VirtualKeyboard',
  data() {
    return {
      keyboardButtons: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '.', ''],
    };
  },
  mixins: [
    typeable,
  ],
  methods: {
    selectButton(button) {
      this.$emit('keyboardButton', button);
    },
  },
};
</script>

<style lang="scss">
.virtual-keyboard {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;

  .keyboard-button {
    flex-basis: 93px;
    font-size: 14px;
    font-weight: 400;
    padding: 11px 0;
    height: 40px;
    margin-bottom: 9px;
    span {
      padding: 11px 40px;
    }
  }

  &.type-terminal {
    .keyboard-button {
      font-size: 18px;
      font-weight: 500;
      height: 54px;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .keyboard-button:last-child {
      pointer-events: none;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .virtual-keyboard {
    &.type-terminal {
      gap: 16px;

      .keyboard-button {
        height: 72px;
        font-size: 24px;
      }
    }
  }
}
</style>
