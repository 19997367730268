<template>
  <div class="select-mobile-wrapper"
       v-if="isOpen">
    <Modal
           height="100%"
           :width="width"
           showFooter
           @closeModal="$emit('closeModal');"
           @closeModalOutside="$emit('closeModal')">
      <template v-slot:header>
        <h1 class="quickpick-title">
          {{ title }}
        </h1>
      </template>
      <template v-slot:body>
        <div class="radio-buttons">
          <Radio v-for="(item, index) in items"
                 :key="index"
                 :id="item"
                 :selected="selectedItem"
                 @change="$emit('change', item)">
          </Radio>
        </div>

      </template>
      <template v-slot:footer>
        <button class="button"
                @click="$emit('closeModal')">
          {{ translations.general_cancel }}
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../Modal';
import Radio from '../Radio';

export default {
  name: 'SelectMobile',
  components: {
    Modal,
    Radio,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    selectedItem: {
      type: [Number, String],
      default: 1,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'translations',
      'isTablet',
    ]),
    width() {
      if (this.type === 'terminal') return '344px';
      return this.isTablet ? '400px' : '100%';
    },
  },
};
</script>

<style lang="scss" scoped>
.select-mobile-wrapper {
  .quickpick-title {
    font-size: 18px;
    color: var(--text-primary-1);
    margin: 0;
  }

  .radio-buttons {
    line-height: normal;

    .radio-button::v-deep {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .radio-button::v-deep label {
      padding-left: 0;
      display: flex;
      align-items: center;
      width: fit-content;
    }

    .radio-button::v-deep .check {
      margin-right: 10px;
    }

    .radio-button::v-deep label,
    .radio-button::v-deep .check {
      position: static;
    }
  }

  .select::v-deep {
    line-height: normal;
  }

  .modal-back::v-deep {
    padding: 36px 8px;
    line-height: normal;
  }

  .modal-back::v-deep {
    .modal {
      padding: 24px;
      overflow: overlay;

      .modal-header {
        padding-left: 0;
        padding-bottom: 38px;
      }

      .modal-footer {
        position: static;
        padding: 51px 5px 3px 0;
      }
    }
  }

  &.type-web {
    ::v-deep {
      .modal-back {
        .modal {
          .modal-body {
            height: auto;
          }
        }
      }
    }
  }

  &.type-terminal {
    ::v-deep {
      .modal-back {
        .modal {
          .modal-header {
            padding: 0;
            h1 {
              font-size: 24px;
            }
          }

          .modal-body {
            overflow: auto;
            .radio-buttons {
              .radio-button {
                height: 72px;
                margin-bottom: 0;
                label {
                  font-size: 20px;
                  width: 100%;
                  height: 100%;
                  .check {
                    width: 24px;
                    height: 24px;
                    border-width: 3px;
                    &::before {
                      width: 12px;
                      height: 12px;
                      top: 30px;
                      left: 6px;
                    }
                  }
                }
              }
            }
          }

          .modal-footer {
            padding: 30px 5px 3px 0;
            .button {
              font-size: 20px;
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: var(--card);
    }
    ::-webkit-scrollbar-thumb {
      background: var(--button);
      border-radius: 3px;
    }
  }
}

.button {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 14px;
  color: var(--secondary);
  text-transform: uppercase;
  margin-left: 30px;
  cursor: pointer;
}
</style>
