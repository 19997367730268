<template>
  <div class="predefinedStakesWrapper" :class="tabletClass" v-clickout="close">
    <div class="label">{{ translations.general_stake }}</div>
    <div v-if="!isDesktop" class="top">
      <BetslipInput
        @change="handleInputChange"
        labelPosition="left"
        :parentValue="tempStake"
        :label="translations.general_stake"
        :isFromDispatchEvent="isFromDispatchEvent"
        ref="betslipInput"
        @click="handleClick"
      />
      <div class="reset" @click="resetStake">
        <i class="icon icon-backspace-a" />
      </div>
    </div>
    <div class="keyboard" v-if="!isDesktop">
      <Keyboard v-if="!isDesktop" @keyboardButton="handleKeyboardButton" />
    </div>
    <div class="bottom">
      <div class="predefinedStakes">
        <div class="predefinedStake"
          v-for="predefinedStake in paymentChips"
          :key="predefinedStake"
        >
          <Button @change="handleChange(predefinedStake)" :label="addPlusSign(predefinedStake)" />
        </div>
      </div>
      <div v-if="!isDesktop" class="actions">
        <Button :label="translations.general_cancel" @change="close" />
        <Button :label="translations.general_accept" @change="acceptTempStakeToActiveInput" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNil } from 'lodash';
import Button from '../../Button';
import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import Keyboard from '../../Keyboard';
import { clickout } from '../../../directives';
import { eventBus, inputUtils } from '../../../utility';

export default {
  data() {
    return {
      tempStake: 0,
      isManualEntryAllowed: false,
      emitTimeout: null,
      isFromDispatchEvent: false,
      paymentChips: null,
    };
  },
  components: {
    Button,
    BetslipInput,
    Keyboard,
  },
  directives: {
    clickout,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeBetslipInput', 'config', 'paymentPerBet', 'rules', 'roundNumber', 'totalPayment', 'ticket']),
    ...mapGetters(['translations', 'isDesktop', 'isTablet']),
    betslipInput() {
      return this.$refs?.betslipInput?.$refs?.input;
    },
    tabletClass() {
      return this.isTablet ? 'tabletLayout' : '';
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['setIsStakeBoxVisible', 'setTotalPayment', 'setBetPayment', 'setPaymentPerBet', 'updateBet']),
    addPlusSign(value) {
      return `+${value}`;
    },
    acceptTempStakeToActiveInput() {
      // We use underscore for inputs that are not from bets
      // to differentiate it from bet ID (UUID)
      if (this.activeBetslipInput.startsWith('_')) {
        if (this.activeBetslipInput === '_totalPayment') {
          this.setTotalPayment(this.tempStake);
        }

        if (this.activeBetslipInput === '_paymentPerBet') {
          this.setPaymentPerBet(this.tempStake);
        }
      } else {
        const targetBet = this.ticket.find(bet => bet.id === this.activeBetslipInput);

        this.setBetPayment({
          betStake: this.tempStake,
          betId: targetBet.id,
          combinations: targetBet.combinations,
        });
      }

      this.close();
    },
    close() {
      this.$emit('close');
      this.setIsStakeBoxVisible(false);

      if (this.emitTimeout) {
        clearTimeout(this.emitTimeout);
      }

      setTimeout(() => {
        eventBus.$emit('formatBetslipInputValue');
      }, 0);
    },
    async handleChange(predefinedStake) {
      if (this.isDesktop) {
        this.setPredefinedStakeToActiveInput(predefinedStake);
      } else {
        this.setPredefinedStakeToInput(predefinedStake);
      }
    },
    handleInputChange(value) {
      this.tempStake = value;
    },
    handleKeyboardButton(button) {
      this.betslipInput.focus();
      this.isFromDispatchEvent = true;

      if (this.isManualEntryAllowed) {
        this.tempStake = '';
      }

      this.$nextTick(() => {
        inputUtils.dispatchKeyDownEvent(this.betslipInput, String(button));
        inputUtils.dispatchKeyUpEvent(this.betslipInput, String(button));
      });

      this.isManualEntryAllowed = false;
    },
    handleClick() {
      this.isManualEntryAllowed = true;
      this.isFromDispatchEvent = false;
    },
    resetStake() {
      this.tempStake = this.roundNumber(this.rules.minBetAmount.value);

      if (this.betslipInput) {
        this.betslipInput.click();
      }
    },
    setPredefinedStakeToActiveInput(predefinedStake) {
      // We use underscore for inputs that are not from bets
      // to differentiate it from bet ID (UUID)
      if (this.activeBetslipInput.startsWith('_')) {
        if (this.activeBetslipInput === '_totalPayment') {
          const totalPayment = Number(this.totalPayment) + predefinedStake;

          this.setTotalPayment(totalPayment);
        }

        if (this.activeBetslipInput === '_paymentPerBet') {
          const paymentPerBet = Number(this.paymentPerBet) + predefinedStake;

          this.setPaymentPerBet(paymentPerBet);
        }
      } else {
        const targetBet = this.ticket.find(bet => bet.id === this.activeBetslipInput);

        this.setBetPayment({
          betStake: Number(targetBet.stake) + predefinedStake,
          betId: targetBet.id,
          combinations: targetBet.combinations,
        });
      }
    },
    setPredefinedStakeToInput(predefinedStake) {
      const newTempStake = Number(this.tempStake) + predefinedStake;

      this.tempStake = newTempStake;

      this.$nextTick(() => {
        eventBus.$emit('formatBetslipInputValue');
      });

      this.isManualEntryAllowed = true;
    },
    setInitialTempStakeValue() {
      // We use underscore for inputs that are not from bets
      // to differentiate it from bet ID (UUID)
      if (this.activeBetslipInput.startsWith('_')) {
        if (this.activeBetslipInput === '_totalPayment') {
          this.tempStake = this.totalPayment;
        }

        if (this.activeBetslipInput === '_paymentPerBet') {
          this.tempStake = this.paymentPerBet;
        }
      } else {
        const targetBet = this.ticket.find(bet => bet.id === this.activeBetslipInput);

        this.tempStake = targetBet.stake;
      }

      this.$nextTick(() => {
        eventBus.$emit('formatBetslipInputValue');
      });
    },
  },
  mounted() {
    const { paymentChips } = this.config;

    this.paymentChips = !isNil(paymentChips) ? paymentChips : [0.5, 1, 2, 5, 10, 20];

    if (!this.isDesktop) {
      this.setInitialTempStakeValue();
      this.betslipInput.click();
    }
  },
};
</script>

<style lang="scss" scoped>
.predefinedStakesWrapper {
  position: absolute;
  width: 100%;
  bottom: var(--stakebox-bottom);
  z-index: 99;
  padding: 17px 0;
  background-color: var(--card);
  color: var(--text-primary-1);
  box-shadow: var(--shadow-3);

  &.tabletLayout {
    position: relative;
  }

  .top {
    padding: 0 32px;
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    .icon-backspace-a {
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--button);
      border-radius: 2px;
    }
  }

  .label {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .keyboard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 33px;
  }

  .bottom {
    margin-top: 7px;
    padding: 0 32px;

    .predefinedStakes {
      display: grid;
      gap: 16px;
      grid-template-columns: auto auto auto;
      justify-content: center;

      .predefinedStake {
        min-width: 88px;
        width: 100%;
        height: 40px;
      }
    }
  }

  .actions {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 43px;

    ::v-deep .button {
      background-color: transparent;
      color: var(--secondary);
      text-transform: uppercase;
    }
  }
}
</style>
