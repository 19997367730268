<template>
  <div class="betslip-body">
    <Scrollable>
      <div class="betslip-bets"
           :style="{height: bodyHeight}">
        <component v-bind:is="betslipBetComponent" v-for="(ticket, index) in tickets"
                           :key="index"
                           :editable="editable"
                           :index="index"
                           :bet="ticket"/>
      </div>
    </Scrollable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scrollable from '../../Scrollable';
import BetslipBet from './BetslipBet';
import BetslipBetKeno from './BetslipBetKeno';

export default {
  name: 'BetslipBody',
  components: {
    Scrollable,
    BetslipBet,
    BetslipBetKeno,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    bodyHeight: {
      type: String,
      default: '',
    },
    product: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      tickets: 'betslip/tickets',
    }),
    betslipBetComponent() {
      return this.product ? `BetslipBet${this.product}` : 'BetslipBet';
    },
  },
};
</script>

<style lang="scss" scoped>
.betslip-bets{
  overflow-y: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: var(--card);
}
::-webkit-scrollbar-thumb {
  background: var(--button);
  border-radius: 3px;
}
</style>
