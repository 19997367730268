<template>
  <component
    :is="currentTicketType.component"
    :bet="bet"
    :isLastBet="isLastBet"
    :class="{ removeBet: isRemoveAnimationActive }"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import BetslipBetSingleA from './BetslipBetSingleA';
import BetslipBetSingleB from './BetslipBetSingleB';
import BetslipBetComboA from './BetslipBetComboA';
import BetslipBetComboB from './BetslipBetComboB';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipBet',
  components: {
    BetslipBetSingleA,
    BetslipBetComboA,
  },
  props: {
    bet: {
      type: Object,
    },
    isLastBet: {
      type: Boolean,
    },
  },
  data() {
    return {
      isRemoveInvalidBetsActive: false,
      invalidBetsAnimationTimeoutId: null,
    };
  },
  methods: {
    activateRemoveInvalidBetsAnimation() {
      clearTimeout(this.invalidBetsAnimationTimeoutId);

      this.isRemoveInvalidBetsActive = true;

      this.invalidBetsAnimationTimeoutId = setTimeout(() => {
        this.isRemoveInvalidBetsActive = false;
      }, 300);
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketType', 'betLayout']),
    currentTicketType() {
      const types = this.ticketTypes
        .filter(ticketType => ticketType.value === this.activeTicketType.value);
      return types.find(type => type.layout === this.betLayout);
    },
    isBetPermanentlyLocked() {
      return this.bet.locked && this.bet.permanentlyLock;
    },
    isRemoveAnimationActive() {
      return this.isRemoveInvalidBetsActive && this.isBetPermanentlyLocked;
    },
    ticketTypes() {
      return [
        {
          value: 'single',
          component: BetslipBetSingleA,
          layout: 'A',
        },
        {
          value: 'single',
          layout: 'B',
          component: BetslipBetSingleB,
        },
        {
          value: 'combo',
          component: BetslipBetComboA,
          layout: 'A',
        },
        {
          value: 'combo',
          component: BetslipBetComboB,
          layout: 'B',
        },
      ];
    },
  },
  mounted() {
    eventBus.$on('RemoveInvalidBets', this.activateRemoveInvalidBetsAnimation);
  },
  beforeDestroy() {
    eventBus.$off('RemoveInvalidBets', this.activateRemoveInvalidBetsAnimation);
    clearTimeout(this.invalidBetsAnimationTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
.removeBet {
  animation-name: remove;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}

@keyframes remove {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
