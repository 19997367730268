import object from './object';

export default class GameSettings {
  constructor(namespace) {
    this.namespace = namespace;
  }

  get saved() {
    const localStorageArray = Object.entries({ ...window.localStorage }).map(
      ([key, value]) => ({ [key]: value }),
    );

    const savedSettings = localStorageArray
      .filter(setting => object.getKey(setting).includes(`${this.namespace}.settings.`))
      .map((setting) => {
        const [key, value] = object.getEntry(setting);

        return [key, value];
      });
    return savedSettings;
  }

  get forComponent() {
    return this.saved.map(([key]) => key.replace(`${this.namespace}.settings.`, ''));
  }

  get keys() {
    return this.saved.map(([key]) => key);
  }

  remove() {
    this.keys.forEach((key) => {
      window.localStorage.removeItem(key);
    });
  }

  save(settings) {
    settings.forEach((setting) => {
      window.localStorage.setItem(
        `${this.namespace}.settings.${setting}`,
        'true',
      );
    });
  }
}
