<template>
  <div class="games-header">
    <div class="header-options">
      <i class="icon icon-arrow-left header-button"
         v-if="showBackButton"
         @click="returnToLobby">
      </i>
      <i class="icon icon-info header-button"
         v-if="showInfoButton"
         @click="$emit('ShowInfo')">
      </i>
      <i class="icon icon-question header-button"
         v-if="showHelpButton"
         @click="handleHelp()">
      </i>
      <i class="icon icon-stats header-button"
         v-if="showStatsButton"
         @click="handleStats()">
      </i>
      <i class="icon icon-results header-button"
         v-if="showResultsButton"
         @click="handleResults()">
      </i>
      <i class="icon icon-settings header-button"
         v-if="showSettingsButton"
         @click="$emit('ShowSettings')">
      </i>
      <i class="icon icon-history header-button"
         v-if="showTicketHistoryButton"
         @click="handleTicketHistory()">
      </i>
      <i class="header-button"
         :class="[audioIcon]"
         v-if="showSoundButton"
         @click="toggleSound">
      </i>
    </div>
    <div class="balance-wrapper"
         v-if="showBalance && isLoggedIn">
      <div class="currency">{{ currencyDisplay || 'VM' }}</div>
      <div class="balance">{{ balanceDisplay || 0 }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../utility';

export default {
  name: 'GamesHeader',
  props: {
    showSettingsButton: {
      type: Boolean,
      default: false,
    },
    showInfoButton: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showSoundButton: {
      type: Boolean,
      default: false,
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
    showHelpButton: {
      type: Boolean,
      default: false,
    },
    showStatsButton: {
      type: Boolean,
      default: false,
    },
    showResultsButton: {
      type: Boolean,
      default: false,
    },
    showTicketHistoryButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      balance: 'user/balance',
      config: 'config',
      isAudioOn: 'isAudioOn',
    }),
    currencyDisplay() {
      return this.config && this.config.currency;
    },
    balanceDisplay() {
      return new Intl.NumberFormat(this.config.locale,
        {
          minimumFractionDigits: 2,
          currency: this.config.currency,
        }).format(this.balance);
    },
    audioIcon() {
      return this.isAudioOn ? 'icon icon-sound-on' : 'icon icon-sound-off';
    },
  },
  methods: {
    ...mapActions([
      'toggleAudio',
    ]),
    handleHelp() {
      this.$emit('ShowInfo');

      this.$nextTick(() => {
        eventBus.$emit('ShowHelp');
      });
    },
    handleStats() {
      this.$emit('ShowInfo');

      this.$nextTick(() => {
        eventBus.$emit('ShowStats');
      });
    },
    handleResults() {
      this.$emit('ShowInfo');

      this.$nextTick(() => {
        eventBus.$emit('ShowResults');
      });
    },
    toggleSound() {
      this.toggleAudio();
      this.$emit('audio', this.isAudioOn);
    },
    returnToLobby() {
      this.$root.$emit('ReturnToLobby');
      eventBus.$emit('ReturnToLobby');
    },
    handleTicketHistory() {
      if (this.isLoggedIn) {
        this.$root.$emit('openTicketHistory');
        eventBus.$emit('openTicketHistory');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.games-header {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 7px 10px 7px 8px;
  font-size: 18px;
  background-color: var(--background-1);
  color: #efefef;
  height: 40px;

  .header-options {
    display: flex;
    align-items: center;
    .header-button {
      cursor: pointer;
      margin-right: 16px;
      color: var(--text-primary-2);
      font-size: 18px;

      &:hover {
        color: var(--text-primary-1);
        transition: all 0.3s ease;
      }

      &:disabled,
      &.disabled {
        color: var(--text-primary-3);
      }
    }
  }

  .balance-wrapper {
    text-align: right;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .currency {
      font-size: 10px;
      color: var(--text-primary-1);
    }

    .balance {
      font-size: 12px;
      color: var(--system-neutral);
      font-weight: bold;
    }
  }
}
</style>
