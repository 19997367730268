<template>
  <div class="quickpick-wrapper"
       v-clickout="handleClickOutside">
    <div class="quickpick-button"
         :class="[
           typeClass,
           !isDesktop ? 'quickpick-button-mobile' : 'quickpick-button-desktop',
           actionSeparator ? '' : 'quickpick-button-remove-border',
           isActive ? 'quickpick-button-active' : '',
        ]">
      <component
              :is="buttonComponent"
              :label="buttonLabel"
              :independentLabel="buttonIndependentLabel"
              :class="[typeClass]"
              :variation="'fragmented-label'"
              @change="handleButtonChange"
              :disabled="disabled"
              :isActive="isActive"
              :isOpen="isOpen" />
      <div v-if="!isWide"
           v-interactionStates
           class="quickpick-action"
           :class="[
             actionSeparator ? '' : 'quickpick-action-remove-space',
             disabled ? 'quick-pick-action-disabled' : ''
            ]"
           @click="handleActionClick">
        <i class="icon"
           :class="[isOpen ? 'icon-arrow-up-a' : 'icon-arrow-down-a']" />
      </div>
    </div>
    <component :is="currentComponent"
               :is-open="isOpen"
               :title="title"
               :label="label"
               :cancel-button-label="cancelButtonLabel"
               :items="items"
               :modal-default-item="modalDefaultItem"
               :resetable="resetable"
               @change="handleQuickpickChange"
               @closeModal="handleModalClose" />
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { disablable, typeable } from '../../mixins';
import { interactionStates, clickout } from '../../directives';
import QuickpickDesktop from './QuickpickDesktop';
import QuickpickMobile from './QuickpickMobile';
import QuickpickTerminal from './QuickpickTerminal';
import Button from '../Button';
import ButtonQuickpick from '../ButtonQuickpick';

export default {
  name: 'Quickpick',
  directives: {
    clickout,
    interactionStates,
  },
  emits: ['change'],
  mixins: [
    disablable,
    typeable,
  ],
  components: {
    QuickpickDesktop,
    QuickpickMobile,
    QuickpickTerminal,
    Button,
    ButtonQuickpick,
  },
  props: {
    actionSeparator: {
      type: Boolean,
      default: true,
    },
    cancelButtonLabel: {
      type: String,
      required: true,
    },
    defaultItem: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
      default: 'Quick',
    },
    modalDefaultItem: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      required: true,
      default: 'Quick pick',
    },
    isWide: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedItem: isNil(this.defaultItem) ? this.items[0] : this.defaultItem,
      buttonComponent: 'Button',
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
    buttonLabel() {
      return `${this.label}`;
    },
    buttonIndependentLabel() {
      return `${this.selectedItem.label}`;
    },
    currentComponent() {
      const baseName = 'Quickpick';

      if (this.typeClass === 'type-terminal-large' || this.typeClass === 'type-terminal') {
        return `${baseName}Terminal`;
      }

      return !this.isDesktop ? `${baseName}Mobile` : `${baseName}Desktop`;
    },
    isMobileOpen() {
      return !this.isDesktop && this.isOpen;
    },
    isDesktopOpen() {
      return this.isDesktop && this.isOpen;
    },
    isActive() {
      return this.selectedItem.isActive ?? false;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    handleActionClick() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },
    handleModalClose() {
      this.close();
    },
    handleCloseDropdown() {
      this.close();
    },
    handleQuickpickChange({ value, label }) {
      this.selectedItem = {
        value,
        label,
      };
      this.$emit('change', this.selectedItem);
      this.close();
    },
    handleButtonChange() {
      if (this.isWide) {
        this.isOpen = !this.isOpen;
      }
      this.$emit('change', this.selectedItem);
    },
    handleClickOutside() {
      this.close();
    },
  },
  watch: {
    defaultItem(value) {
      this.selectedItem = value;
    },
  },
  mounted() {
    this.buttonComponent = this.isWide ? 'ButtonQuickpick' : 'Button';
  },
};
</script>

<style lang="scss" scoped>
.quickpick-wrapper {
  position: relative;
  user-select: none;

  .quickpick-button {
    display: flex;
    height: 40px;

    .quickpick-action {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--button);
      height: 100%;
      width: 40px;
      min-width: 40px;
      cursor: pointer;
      border-radius: 2px;
      margin-left: 2px;

      &::after {
        background-color: var(--button);
      }

      &.quickpick-action-remove-space {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
      }

      &.quick-pick-action-disabled {
        cursor: default;
        pointer-events: none;
        background-color: var(--button-disabled);
      }

      .icon-arrow-down-a,
      .icon-arrow-up-a {
        font-size: 16px;
        color: var(--text-primary-2);
      }

      &:hover {
        background-color: var(--hover-state);
      }

      &:active {
        background-color: var(--active-state);
      }
    }

    &.quickpick-button-active {
      .quickpick-action {
        .icon {
          color: var(--custom-text-color);
        }

        background-color: var(--primary);

        &::after {
          background-color: var(--primary);
        }

        &:hover {
          background-color: var(--hover-state);
        }

        &:active {
          background-color: var(--active-state);
        }
      }
    }

    .button::v-deep {
      width: 100%;
      text-align: center;
      padding: 0 8px;
      border-radius: 2px;
    }

    &.quickpick-button-mobile {
      .button::v-deep {
        padding: 0 8px;
      }
    }

    &.quickpick-button-desktop {
      .button::v-deep {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    &.quickpick-button-remove-border {
      .button::v-deep {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.type-terminal {
      height: 54px;
      font-size: 16px;

      .quickpick-action {
        width: 54px;
      }
    }

    &.type-terminal-large {
      height: 72px;
      font-size: 16px;

      .quickpick-action {
        width: 72px;
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .quickpick-wrapper  {
    .quickpick-button {
      &.type-terminal {
        height: 72px;
        font-size: 24px;

        .quickpick-action {
          width: 72px;
          height: 72px;
          min-width: 72px;
          .icon {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media screen and (min-height: 1790px) {
  .quickpick-wrapper  {
    .quickpick-button {
      &.type-terminal {
        height: 72px;
        font-size: 24px;

        .quickpick-action {
          width: 72px;
          height: 72px;
          min-width: 72px;
        }
      }
    }
  }
}
</style>
