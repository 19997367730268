<template>
  <div class="games-help">
    <div v-html="helpContent"></div>
  </div>
</template>

<script>
export default {
  name: 'GamesHelp',
  props: {
    helpContent: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.games-help {
  line-height: 1.29;
  text-align: left;
  background-color: var(--card);
  padding: 24px;
  h2,
  h3 {
    font-size: 14px;
    color: var(--text-primary-1);
    padding-bottom: 8px;
    text-transform: uppercase;
  }
  p,
  span,
  li {
    color: var(--text-primary-2);
    font-size: 14px;
  }
}
</style>
