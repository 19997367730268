<template>
  <div class="possibleWinWrapper">
    <div class="possibleWin">
      <div>
        {{ translations.general_possible_winning }}
      </div>
      <div>
        {{ decimalFormatNumber(possibleWinCombo.possibleWinnings) }}
      </div>
    </div>
    <div class="payinTax" v-if="isPayinTaxActive">
      <div>
        {{ translations.general_payin_tax }}
      </div>
      <div>
        {{ decimalFormatNumber(possibleWinCombo.possiblePayinTax) }}
      </div>
    </div>
    <div class="odds">
      <div>
        {{ translations.general_total_odds }}
      </div>
      <div>
        {{ decimalFormatNumber(totalOdds) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BetslipPossibleWinCombo',
  computed: {
    ...mapGetters('gamesBetslip', ['formatNumber', 'possibleWinCombo', 'totalOdds', 'taxes', 'decimalFormatNumber']),
    ...mapGetters(['translations']),
    isPayinTaxActive() {
      return !this.taxes.payin.hideTax;
    },
  },
};
</script>

<style lang="scss" scoped>
.possibleWinWrapper {
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--text-primary-1);

  .payinTax {
    color: var(--text-primary-2);
  }

  .possibleWin, .payinTax, .odds {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1910px) {
  .possibleWinWrapper {
    .possibleWin, .payinTax, .odds {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
