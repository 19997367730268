<template>
  <button @click="toggleSelected"
          class="button clearfix"
          :class="[typeClass, { active: selected }]"
          v-interactionStates>
    {{label}}
  </button>
</template>

<script>
import Button from './Button';

export default {
  name: 'ToggleButton',
  extends: Button,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    toggleSelected() {
      this.selected = this.toggleable ? !this.selected : false;
      this.$emit('change', {
        selected: this.selected,
        value: this.label,
      });
    },
  },
  beforeMount() {
    this.selected = this.value;
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  border: 0;
  border-radius: 2px;
  font-size: 14px;
  height: 40px;
  width: inherit;
  line-height: 16px;
  margin: 0;
  outline: 0;
  text-align: center;
  vertical-align: middle;
  background-color: var(--button);
  color: var(--text-primary-1);
  cursor: pointer;

  &:hover:not(:disabled):not(.disabled):not(:active):not(.active) {
    background-color: var(--button-hover);
  }

  &:active:not(:disabled):not(.disabled) {
    background-color: var(--button-active);
  }

  &:disabled:after,
  &.disabled:after,
  &[disabled]:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--button-active);
    opacity: 0.2;
    display: inline;
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &.type-mobile {
    line-height: inherit;
  }

  &.type-terminal {
    height: 72px;
    font-size: 24px;
    line-height: 18px;
    padding: 16px;
  }

  &.brand {
    background-color: var(--primary);
  }

  &.positive {
    background-color: var(--system-positive);
  }

  &.negative {
    background-color: var(--system-negative);
  }
}
</style>
