<template>
  <div class="tabs">
    <Tab
      v-for="(tab, index) in tabs"
      :is-selected="activeTab.id === index && !tab.disabled"
      :is-disabled="tab.disabled"
      :key="index"
      :label="tab.label"
      :buttonType="buttonType"
      :type="tab.type"
      :icon="tab.icon"
      :counter="tab.counter"
      :isCounterActive="tab.isCounterActive"
      @click="selectTab(index)"
    >
      <div class="counter" v-if="tab.isCounterActive && tab.counter > 0">
        <span>{{ tab.counter }}</span>
      </div>
    </Tab>
    <!-- <div class="empty"></div> -->
  </div>
</template>

<script>
import Tab from './Tab';

export default {
  name: 'Tabs',
  components: {
    Tab,
  },
  emits: ['change'],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Object,
      default: () => {},
    },
    buttonType: {
      type: String,
      default: 'flat',
    },
    preserveActiveTab: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectTab(i) {
      this.tabs.forEach((tabItem, index) => {
        const tab = tabItem;
        tab.isActive = index === i;
        if (tab.isActive && !tab.disabled) {
          this.$emit('change', tab);
        }
      });
    },
  },
  mounted() {
    if (this.preserveActiveTab) {
      this.selectTab(this.activeTab.id);
    } else {
      this.selectTab(0);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/animations.scss";
.tabs {
  display: flex;
  justify-content: flex-start;
  height: 40px;
  .empty {
    background-color: var(--card);
    border-bottom: 2px solid rgba(#ffffff, 0.05);
  }
  .counter.animate {
    animation-name: emphasize;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  }
}
</style>
