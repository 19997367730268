<template>
  <div ref="bet"
       class="betslip-bet"
       :class="{
        'active': editBet === index,
       }">
   <div class="close"
        :class="{'pointer': !bet.locked}"
        @click="removeBet(bet)">
     <em class="icon icon-close-a"
         :class="{'pointer': bet.locked}"></em>
   </div>
    <div class="bet"
         :class="{
      'odds-up': bet.oldOdd && bet.odd > bet.oldOdd,
      'odds-down': bet.oldOdd && bet.odd < bet.oldOdd,
      }"
         @click="sendEditAction()">
      <div class="display-id">{{bet.displayId}}</div>
      <div class="bet-event-name bolder">{{bet.eventName}}</div>
      <div class="bet-time">{{bet.time}}</div>
      <div class="bet-outcome bolder">
        <span v-if="!bet.selectedNumbers || !bet.selectedNumbers.length">
          {{bet.outcome}}
        </span>
        <span v-else
              v-for="(value, index) in bet.selectedNumbers"
              :key="index">
          {{value}}
        </span>
      </div>
      <div class="bet-bonus-balls bolder"
           v-if="bet.bonusBalls && bet.bonusBalls.length">
        <span>{{translations.general_bonus_balls}}</span>
        <div class="bonus-balls">
          <span v-for="(value, indexBonus) in bet.bonusBalls"
                :key="indexBonus">
          {{value}}
        </span>
        </div>
      </div>
      <div class="bet-market">{{bet.market}}</div>
      <div class="bet-odd bolder">
        <span>{{translations.general_odd}}</span>
        <span>{{bet.odd.toFixed(toRound)}}</span>
        <span v-if="bet.oldOdd && bet.odd !== bet.oldOdd"
              class="old-odd">
          {{bet.oldOdd.toFixed(toRound)}}
        </span>
      </div>
      <div class="bet-odd bolder"
           v-if="isFuturePerBetActive">
        <span>{{translations.general_future_bet}}</span>
        <span>{{bet.numEvents}}</span>
      </div>
      <div class="input-wrapper"
           v-if="!ticketTypeIsCombo && isPaymentPerBetActive"
           @click.prevent.stop>
        <Input :label-active="false"
               :round="toRound"
               type="retail"
               :name="`betslipBet${index}`"
               :value="bet.payment"
               input-type="text"
               :disabled="!isPaymentPerBetActive"
               :min="minPaymentPerBet.toString()"
               v-on:tabEnterEmit="focusFooterInputs"
               @change="updateBetPayment($event, bet)"/>
      </div>
    </div>
    <div class="locked-overlay"
         v-if="bet.locked">
      <span>{{translations['general_match_locked']}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '../../Input';
import helpers from '../../../mixins/helpers';
import betslipBet from '../../../mixins/betslip/betslipBet';

export default {
  name: 'BetslipBet',
  components: { Input },
  mixins: [helpers, betslipBet],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    bet: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      isFuturePerBetActive: 'betslip/isFuturePerBetActive',
    }),
  },
  mounted() {
    this.$refs.bet.scrollIntoView({ behavior: 'smooth' });
  },
};
</script>

<style lang="scss" scoped>
.betslip-bet {
  border-bottom: 1px solid #4F5C6C;
  display: flex;
  position: relative;
  &.active {
    background-color: #252a31;
  }
  .pointer {
    cursor: pointer;
  }
  .close {
    align-items: center;
    border-right: 1px solid #4F5C6C;
    color: #4F5C6C;
    display: flex;
    font-size: 20px;
    justify-content: center;
    width: 47px;
    em {
      position: relative;
      z-index: 1;
    }
  }
  .bet {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    line-height: 17px;
    padding: 6px 10px;
    position: relative;
    width: calc(100% - 47px);
    .bolder {
      font-weight: bolder;
    }
    > div {
      margin-bottom: 3px;
    }
    .bet-bonus-balls,
    .bet-odd {
      > span:first-child:after {
        content: ':';
        padding-right: 5px;
      }
    }
    .old-odd {
      display: inline-block;
      padding-left: 10px;
      text-decoration: line-through;
    }
    .bet-bonus-balls {
      display: flex;
    }
    .bet-outcome,
    .bonus-balls {
      > span:not(:last-child):after {
        content: ',';
        padding-right: 2px;
      }
    }
    .input-wrapper {
      --text-primary-1: #000;
      background-color: #fff;
      bottom: 6px;
      height: 32px;
      position: absolute;
      right: 10px;
      width: 76px;
    }
    &.odds-down {
      background: var(--betslip-bet-odds-down-bg);
      border: solid 1px var(--betslip-bet-odds-down-border);
    }
    &.odds-up {
      background: var(--betslip-bet-odds-up-bg);
      border: solid 1px var(--betslip-bet-odds-up-border);
    }
  }
  .locked-overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    span {
      color: var(--betslip-bet-locked);
      font-size: 12px;
      font-weight: bolder;
      text-transform: uppercase;
    }
  }
}
</style>
