import store from '@/store';
import eventBus from './eventBus';

export default {
  handleMessages(message) {
    const { data } = message;
    let drawnBalls = [];
    switch (message.type) {
      case 'state':
        if (data) {
          store.dispatch('updateRound', data.eventId);
          if (data.type === 'countdown') {
            store.dispatch('decreaseRound');
          }
          if (data.type === 'ball' || data.type === 'draw') {
            drawnBalls = data.balls;
            store.dispatch('setDrawnBalls', drawnBalls);
          }
        }
        break;
      case 'bettingDisabled':
        store.dispatch('bettingDisabledEvent', true);
        break;
      case 'ball':
      case 'draw':
        store.dispatch('ballDrawn', data);
        break;
      case 'new':
        drawnBalls = [];
        store.dispatch('setDrawnBalls', drawnBalls);
        store.dispatch('updateRound', data.eventId);
        store.dispatch('loadLastTickets');
        store.dispatch('bettingDisabledEvent', false);
        break;
      case 'results':
        store.dispatch('getStats');
        store.dispatch('getResults');
        store.dispatch('loadLastTickets');
        eventBus.$emit('roundResults', data.eventId);
        break;
      case 'countdown':
        break;
      case 'ticketUpdate':
        if (data.httpCode && [200, 201].indexOf(data.httpCode) === -1) {
          data.duration = 20000;
        }
        store.dispatch('updateNotifications', {
          status: data.status.value.toLowerCase(),
          message: data.message,
        });
        eventBus.$emit('ticketUpdate', data);
        store.dispatch('loadLastTickets');
        break;
      case 'newConfig':
        eventBus.$emit('newConfig');
        break;
      case 'balanceUpdate':
        store.dispatch('updatePlayerBalance', data);
        break;
      default:
        break;
    }
  },
};
