<template>
  <div class="betslip-footer-wrap" ref="betslipFooter">
    <div class="betslip-footer"
         :class="{'layout': hidePayinArea}">
      <!-- STAKE  -->
      <div class="input-wrapper">
        <Select v-if="isFutureActive && !ticketTypeIsCombo && !isFuturePerBetActive"
                id="future"
                type="terminal"
                :label="translations.general_future_bet"
                @change="updateFuture"
                :value="future"
                showFirst
                :is-mobile="true"
                :orientation="futureOrientation"
                :options="futureRounds" />
        <!-- TODO CREATE COMPONENT FOR THIS 3 INPUTS -->
        <Input v-if="isPaymentPerBetActive && !hidePayinArea && !ticketTypeIsCombo"
               class="stake"
               :value="paymentPerBet"
               :round="toRound"
               :allowPaste="false"
               :label="isPaymentPerBetActive ?
               translations.general_stake_per_bet : translations.general_stake"
               @change="updateStake($event)" />
        <Input class="total-stake"
               v-if="isPaymentPerBetActive && !hidePayinArea && !ticketTypeIsCombo"
               :value="totalPayment"
               :disabled="true"
               :round="toRound"
               :allowPaste="false"
               :label="translations.general_total_stake"
               orientation="right" />
        <Input v-if="!isPaymentPerBetActive && !hidePayinArea && !ticketTypeIsCombo"
               class="stake"
               ref="test"
               type="terminal"
               :value="payment"
               :label="translations.general_stake"
               :round="toRound"
               icon="icon-backspace-a"
               @fieldFocus="toggleStakeBox"
               @click="clearStake"
               :allowPaste="false"
               title=""
               @change="updateStake($event)" />
        <Input v-if="ticketTypeIsCombo"
               class="stake"
               :value="payment"
               :round="toRound"
               :allowPaste="false"
               :label="translations.general_stake"
               @change="updateStake($event)" />
      </div>
      <!-- REMOVE BET BUTTON -->
      <div class="remove-bet-wrapper">
        <Button v-if="hidePayinArea"
                class="remove-bet"
                @change="clearBetslip()"
                :disabled="isTicketEmpty"
                label="">
          <i class="icon icon-trash"></i>
        </Button>
      </div>
    </div>
    <fade-transition>
      <StakeBox @click="updateStake($event)"
                @close="closeStakeBox"
                type="terminal"
                :showKeyboard="true"
                v-if="stakeBoxVisible" />
    </fade-transition>
    <!-- TOTAL ODDS -->
    <TotalOdds />
    <!--  POSSIBLE WIN -->
    <PossibleWin :isPossibleWinActive="isPossibleWinActive" />
    <!-- PAYIN -->
    <div v-if="!hidePayinArea"
         class="payin-wrapper">
      <Button class="remove-bet"
              @change="clearBetslip()"
              type="terminal"
              :disabled="isTicketEmpty"
              label="">
        <i class="icon icon-trash"></i>
      </Button>
      <LoginPayinButton class="payin" />
    </div>
  </div>
</template>

<script>
import BetslipFooter from '@/components/BetslipArea/BetslipAreaA/BetslipFooter';

export default {
  name: 'BetslipFooter',
  extends: BetslipFooter,
};
</script>

<style lang="scss" scoped>
.betslip-footer-wrap {
  padding: 8px 0;
  background-color: var(--background-2);
  position: absolute;
  bottom: 0;
  width: 100%;

  .betslip-footer,
  .payin-wrapper {
    padding: 0 8px;
  }

  .possible-win-wrapper {
    padding: 8px;
  }

  .betslip-footer {
    .input-wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 8px;

      .input-container {
        width: 100%;
      }
    }
  }
  .stake-box {
    bottom: 180px;
    max-height: 760px;
  }
  .payin-wrapper {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 8px;

    .button {
      height: 72px;
      font-size: 24px;
    }
  }
}
</style>
