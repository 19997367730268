import { mapGetters } from 'vuex';
import { each } from 'lodash';

export default {
  computed: {
    ...mapGetters({
      ticket: 'betslip/tickets',
    }),
    totalOdds() {
      let totalOddsValue = 1;
      each(this.ticket, (bet) => {
        if (bet.odd) {
          totalOddsValue *= bet.odd;
        }
      });

      return totalOddsValue.toFixed(2);
    },
  },
};
