<template>
  <div class="item">
    <div class="header"
         v-if="ticket.bets.length > 1"
         @mouseover="showRebet"
         @mouseleave="hideRebet">
      <div class="ticket-status">
        <div class="value"
             :class="`status-${ticket.status.value.toLowerCase()}`">
          {{ticket.status.name.toUpperCase()}}
        </div>
      </div>
      <div class="payout"
           v-if="ticket.status.value === 'PAYEDOUT'">
        <div class="value"
             :class="`status-${ticket.status.value.toLowerCase()}`">
          {{': '}} {{ticket.payout.toFixed(2)}}
        </div>
      </div>
      <div class="rebet"
           v-show="(rebetVisible || !isDesktop) && ticket.bets.length > 1"
           @click="rebet">
        <span>
          {{'general_rebet' | translate}}
        </span>
      </div>
    </div>
    <div class="body">
      <div class="bet-item"
           @mouseover="showRebet"
           @mouseleave="hideRebet"
           @click="ticketDetails(ticket)"
           :class="{rebetMode: rebetVisible}"
           v-for="(betItem, index) in ticket.bets"
           :key="index">
        <div class="bet-value">
          <div class="label status"
               v-if="ticket.bets.length > 1"
               :class="`status-${betItem.status.value.toLowerCase()}`">
            {{betItem.status.name.toUpperCase()}}
          </div>
          <div class="label status"
               v-if="ticket.bets.length === 1"
               :class="`status-${ticket.status.value.toLowerCase()}`">
            {{ticket.status.name.toUpperCase()}}
          </div>
          <div class="label subtitle">
            <span class="normal">
                {{'general_round' | translate | capitalize}}{{': '}}
              </span>
            <span class="bolded">
              {{betItem.eventId}}
            </span> |
            <span class="normal">
              {{betItem.typeValue | translate}}
            </span>
            <span class="normal">
              {{betItem.ballPositions}}
            </span>
          </div>
          <div class="label title"
               :class="{active: isBallDrawn(ticket.product, betItem, value)}"
               v-for="(value, key) in formatOutcome(betItem.outcome.title)"
               :key="key">
            {{value | translate}}
          </div>
        </div>
        <div class="rebet"
             v-show="(rebetVisible || !isDesktop) && ticket.bets.length === 1"
             @click="rebet">
        <span>
          {{'general_rebet' | translate}}
        </span>
        </div>
      </div>
    </div>
    <div class="footer" v-if="ticket.superBonus">
      <div class="super-bonus">
        <span class="label">
          <i class="icon icon-ajs"></i>
          {{ticket.superBonus.name}}
          {{` +${ticket.superBonus.amount.toFixed(2)}`}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '../../utility/eventBus';

export default {
  name: 'LastTicketsBetItem',
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rebetVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      'noRebet',
      'isDesktop',
    ]),
  },
  methods: {
    getStatusIcon(data) {
      let icon = null;
      switch (data.status.value.toLowerCase()) {
        case 'lost':
          icon = 'close';
          break;
        case 'won':
        case 'payedout':
          icon = 'check-a';
          break;
        case 'open':
          icon = 'clock';
          break;
        default:
          icon = 'close';
          break;
      }

      return icon;
    },
    formatOutcome(outcome) {
      if (outcome.constructor === Array) {
        return outcome;
      }

      if (outcome.constructor === Number) {
        // eslint-disable-next-line no-param-reassign
        outcome = String(outcome);
      }

      return outcome.split(',');
    },
    rebet(event) {
      eventBus.$emit('rebet', this.ticket);
      event.preventDefault();
      event.stopPropagation();
    },
    showRebet() {
      if (!this.noRebet) {
        this.rebetVisible = true;
      }
    },
    hideRebet() {
      this.rebetVisible = false;
    },
    ticketDetails(ticket) {
      eventBus.$emit('ticketDetails', ticket);
    },
    isBallDrawn(product, bet, value) {
      if (bet.outcome.value.length > 1) {
        if (product === 'LuckySix') {
          return bet.eventValue.split(',').includes(value);
        }
        return bet.outcome.drawn.includes(Number(value));
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.item {
  float: left;
  width: 100%;

  .rebet {
    cursor: pointer;
    color: var(--text-primary-1);
    float: right;
    text-transform: uppercase;
    font-size: 12px;
  }

  .header {
    height: 40px;
    background-color: var(--card-section-bg);
    float: left;
    width: 100%;
    font-size: 12px;
    margin-bottom: 1px;
    padding: 0 16px;


    .icon {
      float: left;
      font-size: 14px;
      line-height: 41px;
      margin-right: 8px;
      cursor: default;
    }

    .payout, .ticket-status {
      float: left;

      .value {
        line-height: 41px;
        float: left;

        &.status-lost, &.status-closed {
          color: var(--system-negative);
        }

        &.status-won, &.status-payedout {
          color: var(--system-positive);
        }

        &.status-open, &.status-in_play, &.status-pending {
          color: var(--system-neutral);
        }
      }
    }

    .rebet {
      line-height: 40px;
    }
  }

  .body {
    .bet-item {
      float: left;
      width: 100%;
      height: 80px;
      background-color: var(--card);
      display: flex;
      padding: 12px 16px;
      border-radius: 2px;
      cursor: pointer;
      transition: all .5s ease;
      border-bottom: 1px solid var(--card-section-bg);
      position: relative;

      &.rebetMode {
        background-color: var(--card-hover-bg);
      }

      &:hover {
        background-color: var(--card-hover-bg);
      }

      .icon {
        float: left;
        line-height: 54px;
        margin-right: 8px;
        font-size: 14px;
      }

      .bet-value {
        width: 100%;

        .status {
          font-size: 12px;
          font-weight: 600;

          &.status-lost, &.status-closed {
            color: var(--system-negative);
          }

          &.status-won, &.status-payedout {
            color: var(--system-positive);
          }

          &.status-open, &.status-in_play, &.status-pending {
            color: var(--system-neutral);
          }
        }

        .label {
          float: left;
          margin-bottom: 6px;

          .normal {
            color: var(--text-primary-2);
          }

          .bolded {
            color: var(--text-primary-1);
            font-weight: 700;
            margin-left: 3px;
          }
        }

        .title {
          top: 11px;
          font-size: 14px;
          color: var(--text-primary-3);
          margin-right: 4px;

          &.active {
            color: var(--text-primary-1);
            font-weight: 500;
          }

          .drawn-number {
            margin-right: 5px;
          }
        }

        .subtitle {
          top: 13px;
          font-size: 12px;
          color: var(--text-primary-2);
          width: 100%;
        }
      }

      .rebet {
        position: absolute;
        right: 16px;
      }
    }
  }

  .footer {
    float: left;
    width: 100%;
    height: 40px;
    background-color: var(--system-neutral);
    padding: 0 16px;
    border-radius: 2px;

    .super-bonus {
      height: 100%;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: var(--text-secondary-1);
      text-align: left;
    }
  }
}
</style>
