<template>
  <div class="last-tickets-wrapper">
    <CircleLoader :loader="lastTicketsInProgress"></CircleLoader>
    <Actions></Actions>
    <Scrollable>
      <div class="last-tickets-body">
        <div class="no-tickets-message"
             v-if="!tickets.length">
          <i class="icon icon-betslip"></i>
          <span>{{ 'general_tickets_empty_info' | translate }}</span>
        </div>
        <TicketItem v-for="(ticket, key) in tickets"
                    :ticket="ticket"
                    :key="key">
        </TicketItem>
      </div>
    </Scrollable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Actions from './LastTicketsActions';
import TicketItem from './LastTicketsBetItem';
import CircleLoader from '../CircleLoader';
import Scrollable from '../Scrollable';

export default {
  name: 'LastTickets',
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Actions,
    TicketItem,
    CircleLoader,
    Scrollable,
  },
  computed: {
    ...mapGetters([
      'lastTicketsInProgress',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.last-tickets-wrapper {
  float: left;
  width: 300px;
  position: relative;
  height: 467px;

  .last-tickets-body {
    background: var(--background-1);

    .no-tickets-message {
      position: absolute;
      top: 30%;
      display: flex;
      flex-wrap: wrap;

      .icon {
        width: 100%;
        text-align: center;
        font-size: 72px;
        color: var(--text-primary-3);
      }
      span {
        margin-top: 66px;
        font-size: 16px;
        width: 100%;
        float: left;
        padding: 0 40px;
        text-align: center;
        color: var(--text-primary-3);
      }
    }
  }
}

@media (min-width: 306px) and (max-width: 660px) {
  .last-tickets-wrapper {
    width: 100%;
    height: calc(100% - 40px);
    .last-tickets-body {
      float: left;
      width: 100%;
      padding-bottom: 100px;
      min-height: 400px;
    }
  }
}

@media (min-width: 661px) and (max-width: 960px) {
  .last-tickets-wrapper {
    width: 100%;
    .last-tickets-body {
      height: 300px;
    }
  }
}
</style>
