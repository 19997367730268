<template>
  <div class="body">
    <!-- TABLE -->
    <div class="ticket-details-table">
      <!-- DESKTOP -->
      <div class="ticket-details-body-desktop">
        <div class="columns">
          <!-- HEADER -->
          <div class="column">
            {{ translations.general_round.toUpperCase() }}
          </div>
          <div class="column">
            {{ translations.general_type.toUpperCase() }}
          </div>
          <div class="column">
            {{ translations.general_bet.toUpperCase() }}
          </div>
          <div class="column">
            {{ translations.general_odds.toUpperCase() }}
          </div>
          <div class="column">
            {{ translations.general_stake.toUpperCase() }}
          </div>
          <div class="column">
            {{ translations.general_winnings.toUpperCase() }}
          </div>
          <div class="column"
               v-if="isPayinActive">
            {{ translations.general_pay_in_details.toUpperCase() }}
          </div>
          <div class="column"
               v-if="isPayoutActive">
            {{ translations.general_payout.toUpperCase() }}
          </div>
          <div class="column">
          </div>
        </div>
        <!-- ROWS -->
        <Scrollable>
          <div class="rows">
            <div class="row"
                 v-for="(bet, key) in ticket.bets"
                 :key="key">
              <div class="bet-item">{{ bet.round }}</div>
              <div class="bet-item">{{ betTypeLabel(bet.market) }}</div>
              <div class="bet-item">
                <div v-html="getOutcome(bet)"></div>
              </div>
              <div class="bet-item">{{ getOdd(bet) || '-' }}</div>
              <div class="bet-item">{{ bet.stake ? bet.stake.toFixed(2) : '-' }}</div>
              <div class="bet-item">{{ bet.winnings ? bet.winnings.toFixed(2) : '-' }}</div>
              <div class="bet-item"
                   v-if="isPayinActive">{{ formatNumber(bet.payin) }}</div>
              <div class="bet-item"
                   v-if="isPayoutActive">{{ bet.payout }}</div>
              <div class="bet-item status">
                <i class="icon"
                   :class="`icon-${getStatusIcon(bet.status)}
                   status-${bet.status.value.toLowerCase()}`">
                </i>
              </div>
            </div>
          </div>
        </Scrollable>
      </div>
      <!-- MOBILE -->
      <div class="ticket-details-body-mobile">
        <component :showFooter="false"
                            statusPosition="right"
                            :receivedIsLastTicketsData="isLastTicketsData"
                            :isDetailsMode="true"
                            :clickDisabled="clickDisabled"
                            :ticket="ticket"
                            :ticketDetailItem="ticketDetailItem"
                            :is="component">
        </component>
      </div>
    </div>
    <!-- PAYMENT INFO -->
    <div class="payment-info">
      <div class="row">
        <div class="payin-info">
          <span class="label">
            {{ translations.general_pay_in_details }}
          </span>
          <span class="value">
            {{ formatNumber(ticket.payin) }}
          </span>
        </div>
        <div class="total-odds-info" v-if="showTotalOdds">
           <span class="label">
            {{ translations.general_total_odds }}
          </span>
          <span class="value">
            {{ formatNumber(ticket.totalOdds) }}
          </span>
        </div>
        <div class="tax-info"
             v-if="ticket.payinTax">
          <span class="label">
            {{ translations.general_payin_tax }}
          </span>
          <span class="value">
            {{ formatNumber(ticket.payinTax) }}
          </span>
        </div>
      </div>
      <div class="row winning-info">
        <div class="tax-info" v-if="ticket.status.value === 'PAYEDOUT' && ticket.payoutTax">
          <span class="label payout-tax">
            {{ translations.general_payout_tax }}
          </span>
          <span class="value">{{ formatNumber(ticket.payoutTax) }}</span>
        </div>
        <div class="winning">
          <span v-if="ticketIsOpen"
                class="label">
            {{ translations.general_possible_winning }}
          </span>
          <span v-else
                class="label">
            {{ ticket.status.value !== 'WON'
            ? translations.general_payout : translations.general_winning }}
          </span>
          <span v-if="ticketIsOpen"
                class="value">
            {{ getMaxPossibleWinValue(ticket.maxPossibleWin) }}
          </span>
          <span v-else
                class="value">
            {{ formatNumber(ticket.status.value !== 'WON' ? ticket.payout : ticket.winnings) }}
          </span>
        </div>
      </div>
      <div class="row bonus"
           v-if="ticket.superBonus">
        <div class="super-bonus">
          <span class="label">
            <i class="icon icon-ajs"></i>
            {{ ticket.superBonus.name }}
            {{` +${formatNumber(ticket.superBonus.amount)}`}}
          </span>
        </div>
      </div>
      <div class="row free-bet-wrapper"
           v-if="ticket?.bonuses?.length">
        <div class="free-bet">
          <span class="label">
            <i class="icon icon-info"></i>
            {{ translations.general_free_bet  }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { some, isNil, isArray } from 'lodash';
import numeral from 'numeral';
import { defineAsyncComponent } from 'vue';
import Scrollable from '../../Scrollable';
import { currentApp, oddConverter } from '../../../utility';

export default {
  name: 'LastTicketsDetailsBody',
  props: {
    isLastTicketsData: {
      type: Boolean,
      default: true,
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    clickDisabled: {
      type: Boolean,
      default: false,
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
  },
  components: {
    LastTicketsBetItem: () => import('./LastTicketsBetItem'),
    LastTicketsBetItemV3: defineAsyncComponent(() => import(/* webpackChunkName: "components.LastTicketsBetItem" */ './LastTicketsBetItem')),
    Scrollable,
  },
  data() {
    return {
      ticketDetailItem: true,
    };
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
      numbersFormat: 'numbersFormat',
      oddType: 'oddType',
    }),
    component() {
      if (currentApp.isVue3()) return 'LastTicketsBetItemV3';
      return 'LastTicketsBetItem';
    },
    isPayinActive() {
      return some(this.ticket.bets, bet => !isNil(bet.payin));
    },
    isPayoutActive() {
      return some(this.ticket.bets, bet => !isNil(bet.payout));
    },
    showTotalOdds() {
      return !isNil(this.ticket.totalOdds);
    },
    ticketIsOpen() {
      return this.ticket.status.value !== 'PAYEDOUT'
        && this.ticket.status.value !== 'LOST'
        && this.ticket.status.value !== 'WON';
    },
  },
  methods: {
    getStatusIcon(status) {
      let icon = null;
      switch (status.value.toLowerCase()) {
        case 'lost':
          icon = 'close';
          break;
        case 'won':
        case 'payedout':
          icon = 'check-a';
          break;
        case 'open':
          icon = 'clock';
          break;
        default:
          icon = 'close';
          break;
      }
      return icon;
    },
    betTypeLabel(type) {
      if (type === 'Forecast' || type === 'Reverse Forecast') {
        return type === 'Forecast' ? this.translations.greyhound_forecast
          : `${this.translations.greyhound_reverse} ${this.translations.greyhound_forecast}`;
      }
      if (type === 'Head to Head') {
        return type;
      }
      return this.translations[`general_${type.toLowerCase()}`] ?? type;
    },
    convertBallsToHtml(balls) {
      const html = balls.map(ball => `<span class='ball ${ball.isDrawn ? 'active' : ''}'>${ball.ball}</span>`);

      return `<div class="ballsContainer">${html.join(' ')}</div>`;
    },
    getOdd(bet) {
      if ((bet.market === 'Reverse Forecast' && bet.status.id !== '5') || !bet.odd) {
        return '-';
      }

      const odd = Number(bet.odd);
      return oddConverter(odd, this.oddType);
    },
    getOutcome(bet) {
      if (this.ticket.productName && (this.ticket.productName.toLowerCase() === 'greyhoundraces'
        || this.ticket.productName.toLowerCase() === 'virtualgreyhoundraces'
        || this.ticket.productName.toLowerCase() === 'virtualhorseraces'
        || this.ticket.productName.toLowerCase() === 'slotcarraces')) {
        return bet.outcome;
      }
      if (typeof bet.outcome === 'string' && bet.outcome.includes(',')) {
        const balls = bet.outcome.split(',').map(Number).map(ball => ({
          ball,
          isDrawn: isArray(bet.eventValue)
            ? ((bet.eventValue && bet.eventValue.includes(ball))
              || this.isBallDrawn?.(ball, bet.round, bet))
            : bet.eventValue.split(',').map(item => parseInt(item, 10)).includes(ball)
            || this.isBallDrawn?.(ball, bet.round, bet),
        }));

        return this.convertBallsToHtml(balls);
      }

      // Case when there is only one number in bet outcome
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(Number(bet.outcome))) {
        const ball = Number(bet.outcome);

        const mappedBall = [{
          ball: Number(bet.outcome),
          isDrawn: (!isNil(bet.eventValue)
            && bet.eventValue.includes(ball))
            || this.isBallDrawn?.(ball, bet.round, bet),
        }];

        return this.convertBallsToHtml(mappedBall);
      }

      return bet.outcome;
    },
    formatNumber(number) {
      return numeral(number).format(this.numbersFormat);
    },
    getMaxPossibleWinValue(value) {
      if (value) {
        return this.formatNumber(value);
      }
      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.status-lost {
  color: var(--system-negative);
}

.status-won,
.status-payedout {
  color: var(--system-positive);
}

.status-open {
  color: var(--system-neutral);
}

.body {
  height: 100%;
  .ticket-details-table {
    float: left;
    width: 100%;
  }

  .ticket-details-body-desktop {
    .columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 0 16px;

      .column {
        font-size: 12px;
        color: var(--text-primary-2);
        flex: 1;
        text-align: center;

        &:first-child {
          flex: 0.5;
          text-align: left;
        }

        &:last-child {
            max-width: 16px;
            text-align: right;
          }
          &:nth-child(2) {
            flex: 2.6;
          }
          &:nth-child(3) {
            flex: 2.6;
          }

          &:nth-child(4) {
            flex: 1;
          }
          &:nth-child(5) {
            flex: 1.3;
          }
          &:nth-child(6) {
            flex: 1.3;
          }
      }
    }
    .rows {
      max-height: 300px;
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 32px;
        height: 100%;
        background-color: var(--card-section);
        color: var(--text-primary-1);
        padding: 8px 16px 6px;
        font-size: 14px;
        margin-bottom: 1px;
        border-radius: 3px;

        .bet-item {
          flex: 1;
          text-align: center;

          &::v-deep .ballsContainer {
            .ball {
              color: var(--text-primary-3);

              &.active {
                color: var(--text-primary-1);
              }
            }
          }

          &:first-child {
            flex: 0.5;
            text-align: left;
          }

          &:last-child {
            max-width: 16px;
            text-align: right;
          }
          &:nth-child(2) {
            flex: 2.6;
          }
          &:nth-child(3) {
            flex: 2.6;
          }

          &:nth-child(4) {
            flex: 1;
          }
          &:nth-child(5) {
            flex: 1.3;
          }
          &:nth-child(6) {
            flex: 1.3;
          }
        }
      }
    }
  }

  .ticket-details-body-mobile {
    display: none;
  }

  .payment-info {
    float: left;
    width: 100%;
    margin-top: 8px;
    background-color: var(--card);
    color: var(--text-primary-1);
    font-size: var(--font-size-medium, 14px);

    .row {
      background-color: var(--card-section);
      border-radius: 3px;
      float: left;
      width: 100%;
      padding: 8px 16px 6px;

      &.bonus {
        background-color: var(--system-neutral);
        color: var(--text-secondary-1);
        margin-top: 8px;
        font-size: 12px;

        .icon {
          position: relative;
          top: 1px;
          margin-right: 10px;
        }
      }

      &.free-bet-wrapper {
        line-height: 40px;
        padding: 0 16px;
        margin-top: 8px;
        background-color: var(--free-bet);
        color: var(--text-secondary-1);
        font-weight: 500;
        font-size: 12px;

        .icon {
          position: relative;
          top: 3px;
          margin-right: 8px;
          font-size: 16px;
        }
      }

      .payin-info,
      .tax-info {
        color: var(--text-primary-2);
        float: left;
        width: 100%;
      }

      .total-odds-info {
        color: var(--text-primary-2);
        font-size: 12px;
      }


      .payout-tax {
        margin-bottom: 4px;
      }

      .label {
        float: left;
      }

      .value {
        float: right;
      }

      .payin-info {
        margin-bottom: 2px;
      }

      .tax-info {
        font-size: 12px;
        margin-top: 4px;
      }
    }
    .winning-info {
      display: flex;
      flex-direction: column;
      margin-top: 2px;
    }
  }
}
@media (max-width: 660px) {
  .body {
    .ticket-details-table {
      max-height: calc(100% - 230px);
      overflow-x: auto;
    }
    .ticket-details-body-desktop {
      display: none;
    }
    .ticket-details-body-mobile {
      display: block;
      max-height: 100%;
    }
    .payment-info {
      margin-top: 0px;
      background-color: var(--card-section);
    }
  }
}
</style>
