<template>
  <div class="betslip">
    <BetslipHeader :selectedTab="betslipActiveComponent"
                   :isMinimizeVisible="isMinimizeVisible"
                   @change="updateTab"
                   @minimizeClick="handleMinimizeClick" />
    <keep-alive>
      <component :is="component.component"
                v-on="$listeners"
                v-bind="$props" />
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// Imported tab components
import BetslipTicket from './BetslipTicket';
import BetslipHistory from './BetslipHistory';
import BetslipHeader from './BetslipHeader';

export default {
  name: 'BetslipAreaA',
  components: {
    BetslipHeader,
    BetslipTicket,
    BetslipHistory,
  },
  data() {
    return {
      components: [],
    };
  },
  computed: {
    ...mapGetters({
      ticket: 'betslip/tickets',
      numOfBets: 'betslip/numOfBets',
      translations: 'translations',
      betslipComponents: 'betslip/betslipComponents',
      betslipActiveComponent: 'betslip/betslipActiveComponent',
      ticketTypeComponents: 'betslip/ticketTypeComponents',
      isLoggedIn: 'user/isLoggedIn',
      playerTickets: 'betslip/playerTickets',
    }),
    betsCounter() {
      return this.ticket && this.ticket.length;
    },
    numberOfLastTickets() {
      let count = 0;
      this.playerTickets.forEach((ticket) => {
        if (ticket.status.value === 'OPEN' || ticket.status.value === 'IN_PLAY') count += 1;
      });
      return this.isLoggedIn ? count : 0;
    },
    component() {
      return this.betslipActiveComponent;
    },
    types() {
      if (!this.betslipTypes) {
        return [
          {
            id: 0,
            key: 0,
            type: 3,
            value: 'single',
            label: this.translations.general_ticket_single,
            active: true,
          },
          {
            id: 1,
            key: 1,
            type: 1,
            value: 'combo',
            label: this.translations.general_ticket_combo,
            active: true,
          },
          {
            id: 2,
            key: 2,
            type: 2,
            value: 'system',
            label: this.translations.general_ticket_system,
            active: false,
          },
        ];
      }
      return this.betslipTypes;
    },
  },
  // Betslip properties definition - todo documentation
  props: {
    betslipTypes: {
      type: Array,
    },
    // Internal betslip prop for differenet layouts
    hidePayinArea: {
      type: Boolean,
      default: false,
    },
    showNotification: {
      type: Boolean,
      default: true,
    },
    numberOfBets: {
      type: Boolean,
      default: true,
    },
    futureOrientation: {
      type: String,
      default: 'top',
    },
    imageBg: {
      type: String,
      default: null,
    },
    ticketHistory: {
      type: Boolean,
      default: true,
    },
    isPossibleWinActive: {
      type: Boolean,
      default: true,
    },
    isMinimizeVisible: {
      type: Boolean,
      default: false,
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
    showLastTickets: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions({
      setTicketType: 'betslip/setTicketType',
      setNotification: 'notifications/setNotification',
      setBetslipComponents: 'betslip/setBetslipComponents',
      setActiveBetslipTab: 'betslip/setActiveBetslipTab',
      setTicketTypeComponents: 'betslip/setTicketTypeComponents',
      setTicketTypeActiveComponent: 'betslip/setTicketTypeActiveComponent',
    }),
    handleMinimizeClick() {
      this.$emit('minimizeClick');
    },
    updateTab(value) {
      this.setActiveBetslipTab(value);
    },
    handleComponents() {
      this.components.push({
        id: 0,
        key: 0,
        name: 'Betslip',
        label: this.translations.general_betslip,
        component: BetslipTicket,
        disabled: false,
        counter: this.betsCounter,
        isCounterActive: true,
        type: 'betslip',
      });
      if (this.showLastTickets) {
        this.components.push({
          id: 1,
          key: 1,
          name: 'TicketHistory',
          type: 'lastTickets',
          label: this.translations.general_active_tickets,
          component: BetslipHistory,
          disabled: !this.isLoggedIn,
          counter: this.numberOfLastTickets,
          isCounterActive: true,
          animate: true,
        });
      }
    },
  },
  watch: {
    isLoggedIn(value) {
      this.components[1].disabled = !value;
      this.setBetslipComponents(this.components);
      this.setActiveBetslipTab(this.betslipComponents[0]);
    },
    ticket(value) {
      if (value.length > 0) {
        this.setActiveBetslipTab(this.betslipComponents[0]);
      }
    },
    betsCounter(newValue) {
      if (this.components[0]) this.components[0].counter = newValue;
    },
    numberOfLastTickets(newValue) {
      if (this.components[1]) this.components[1].counter = newValue;
    },
  },
  mounted() {
    this.handleComponents();
    this.setBetslipComponents(this.ticketHistory ? this.components : [this.components[0]]);
    this.setActiveBetslipTab(this.betslipComponents[0]);
    this.setTicketTypeComponents(this.types);
    this.setTicketTypeActiveComponent(this.types[0]);
  },
};
</script>

<style lang="scss">
.betslip {
  position: relative;
  background-color: var(--background-1);
  color: var(--text-primary-1);
  font-size: var(--font-size-medium, 14px);
  width: var(--betslip-min-width, 300px);
  box-shadow: -10px -20px 30px -10px rgba(0, 0, 0, 0.3);
}
</style>
