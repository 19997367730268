<template>
    <div class="empty-box-wrapper">
      <div class="empty-box">
        <i class="icon" :class="iconName"></i>
        <p class="description">{{ text }}</p>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'TerminalTicketHistoryEmpty',
  props: {
    text: {
      type: String,
      required: false,
      default: 'No results found.',
    },
    iconName: {
      type: String,
      required: false,
      default: 'n-i-product-operations',
    },
  },
};
</script>
  <style lang="scss" scoped>
  .empty-box-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    color: var(--text-primary-3);
    padding-top: 18px;
    line-height: 21px;
    text-align: center;
    .empty-box {
      max-width: 200px;
      .icon::before {
        height: 128px;
        width: 128px;
        display: block;
        font-size: 128px;
      }
      .description {
        margin-top: 20px;
      }
    }
  }
  </style>
