<template>
  <div class="last-tickets-actions">
    <div class="action-item">
      <component :is="tooltipComponent"
        :text="translations.general_active_tickets"
        :show="showLastTicketsTooltip">
        <i class="icon icon-refresh-a"
           v-long-press="500"
           @long-press-start="showLastTicketsTooltip = true"
           @long-press-stop="showLastTicketsTooltip = false"
           @click="refreshTicketList"></i>
      </component>
    </div>
    <div class="action-item">
      <component :is="tooltipComponent"
        :text="translations.general_ticket_history"
        :show="showTicketHistoryTooltip">
        <i class="icon icon-history"
           v-long-press="500"
           @long-press-start="showTicketHistoryTooltip = true"
           @long-press-stop="showTicketHistoryTooltip = false"
           @click="openTicketHistory"></i>
      </component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';
import LongPress from '../../directives/longPress';
import eventBus from '../../utility/eventBus';
import { currentApp } from '../../utility';

export default {
  name: 'LastTicketsActions',
  directives: {
    'long-press': LongPress,
  },
  components: {
    Tooltip: () => import(/* webpackChunkName: "components.tooltip" */'../Tooltip'),
    Tooltipv3: defineAsyncComponent(() => import (/* webpackChunkName: "components.tooltipv3" */'../Tooltipv3')),
  },
  data() {
    return {
      showTicketHistoryTooltip: false,
      showLastTicketsTooltip: false,
    };
  },
  computed: {
    ...mapGetters([
      'translations',
    ]),
    refreshLabel() {
      return 'general_refresh';
    },
    ticketHistoryLabel() {
      return 'general_ticket_history';
    },
    tooltipComponent() {
      return currentApp.isVue2() ? 'Tooltip' : 'Tooltipv3';
    },
  },
  methods: {
    refreshTicketList() {
      eventBus.$emit('refreshTicketsList');
    },
    openTicketHistory() {
      eventBus.$emit('openTicketHistory');
    },
  },
};
</script>

<style lang="scss" scoped>
  .last-tickets-actions {
    height: 40px;
    background-color: var(--bg-color-1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;

    .action-item {
      line-height: 40px;
      cursor: pointer;
      color: var(--text-primary-2);
      transition: all .5s ease;

      &:hover {
        color: var(--text-primary-1);
      }
    }
  }
</style>
