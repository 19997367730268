<template>
  <div>
    <div class="betslipHeaderWrapper" ref="header">
      <div class="betslipHeaderTitle">{{ translations.general_betslip }}</div>
      <div class="betslipNumberOfBets" :class="{ active: isNumberOfBetsActive }">
        {{ numberOfBets }}
      </div>
      <div class="minimize-betslip-button" v-if="showMinimizeButton"
      @click="minimizeBetslip">
      <i class="icon icon-arrow-right">
      </i>
    </div>
    </div>
    <BetslipTicketTypes v-show="isTicketTypesComponentVisible" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BetslipTicketTypes from './BetslipTicketTypes';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipHeader',
  props: {
    showMinimizeButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BetslipTicketTypes,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['numberOfBets', 'activeTicketTypes']),
    ...mapGetters(['translations']),
    isNumberOfBetsActive() {
      return this.numberOfBets > 0;
    },
    hasMultipleTicketTypes() {
      return this.activeTicketTypes.length > 1;
    },
    isTicketTypesComponentVisible() {
      return this.hasMultipleTicketTypes;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['setActiveTicketType']),
    minimizeBetslip() {
      eventBus.$emit('MinimizeBetslip');
    },
  },
  beforeMount() {
    this.setActiveTicketType(this.activeTicketTypes[0]);
  },
  mounted() {
    const height = this.$refs.header?.offsetHeight;
    document.body.style.setProperty('--header-height', `${height}px`);
  },
};
</script>

<style lang="scss" scoped>
.betslipHeaderWrapper {
  background-color: var(--button);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  border-bottom: 2px solid var(--secondary);

  .betslipHeaderTitle {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary);
  }

  .betslipNumberOfBets {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 14px;
    background-color: var(--input);
    color: var(--text-primary-1);
    margin-left: 8px;
    font-weight: 700;
    border-radius: 2px;

    &.active {
      color: var(--text-secondary-1);
      background-color: var(--secondary);
    }
  }
  .minimize-betslip-button {
    text-align: right;
    height: 72px;
    line-height: 72px;
    width: 100%;
    color: var(--text-primary-1);
    font-size: 24px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1100px) {
  .betslipHeaderWrapper {
    .minimize-betslip-button {
      display: none;
    }
  }
}
// Currently we receive few pixels less on the terminal 1920 => 1914
// Until it gets fixed we need to lower the width to activate it
@media only screen and (min-width: 1910px) {
  .betslipHeaderWrapper {
    height: 72px;
    .betslipHeaderTitle {
      font-size: 20px;
    }
  }
}
</style>
