export default {
  computed: {
    activeTicketTypeValue() {
      return this.$store.getters['betslip/ticketType'].value;
    },
    ticketTypeIsCombo() {
      return this.activeTicketTypeValue === 'combo';
    },
    ticketTypeIsSingle() {
      return this.activeTicketTypeValue === 'single';
    },
    ticketTypeIsSystem() {
      return this.activeTicketTypeValue === 'system';
    },
  },
};
