<template>
  <div class="betslip-body"
       v-if="activeTicket && activeTicket.active">
       <div class="betslip-locked" v-if="isBetslipLocked">
          <div class="message">{{ betslipLockedMessage }}</div>
          <i class="icon icon-lock" />
       </div>
    <div v-if="numOfBets === 0"
         class="empty"
         :style="{ backgroundImage: 'url(' + getImageBg + ')' }">
      <span class="message" v-if="!isBetslipLocked">
        {{ translations.general_ticket_empty_message }}
      </span>
    </div>
    <Scrollable v-else>
      <CircleLoader class="circle-loader"
                    :loader="ticketPayinInProgress"></CircleLoader>
      <div class="betslip-bets">
        <div v-for="(bet, i) in tickets"
             :key="i">
          <BetslipBet :bet="bet" />
        </div>
      </div>
    </Scrollable>
    <div class="notification-wrapper">
      <Notification v-if="notifications && showNotification" type="terminal" />
    </div>
    <BetslipFooter :isFutureActive="isFutureActive"
                   :futureOrientation="futureOrientation"
                   :isPossibleWinActive="isPossibleWinActive"
                   :hidePayinArea="hidePayinArea" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Notification from '@/components/Notifications/Notification';
import Scrollable from '@/components/Scrollable';
import CircleLoader from '@/components/CircleLoader';
import ticket from '@/mixins/betslip/ticket';
import BetslipBet from './BetslipBet';
import BetslipFooter from './BetslipFooter';
import BetslipBody from '../BetslipAreaA/BetslipBody';

export default {
  name: 'BetslipBody',
  extends: BetslipBody,
  components: {
    BetslipBet,
    BetslipFooter,
    Notification,
    Scrollable,
    CircleLoader,
  },
  mixins: [ticket],
  computed: {
    ...mapGetters({
      isBetslipLocked: 'betslip/isBetslipLocked',
    }),
  },
  props: {
    betslipLockedMessage: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.betslip-body {
  .empty {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 253px);
    width: 100%;
    background-color: var(--background-1);
    background-position: center 30%;
    background-repeat: no-repeat;

    .message {
      position: absolute;
      transform: translate(-50%, 55%);
      top: 55%;
      left: 50%;
      color: var(--text-primary-3);
      text-align: center;
      font-size: 24px;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      width: 65%;
    }
  }

  .betslip-bets {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: calc(100vh - 253px);
    height: 100%;
    background-color: var(--background-1);
    min-width: var(--betslip-min-width, 300px);
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .notification-wrapper {
    position: absolute;
    bottom: 190px;
    width: 100%;
    height: 72px;
    .notification {
      height: 72px;
      background-color: var(--system-neutral);
      ::v-deep span {
        i {
          margin-right: 5px;
        }
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
  }
  .betslip-locked {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    background-color: var(--overlay-lock);
    .message {
      position: absolute;
      text-align: center;
      width: 100%;
      top: 44%;
      font-size: 24px;
    }
    .icon {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>
