import { httpResults } from './http';

export default {
  getEndpoint(platformName) {
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const path = `VUE_APP_${platform}_RESULTS_PATH`;
    return process.env[path];
  },
  getResults(config, user, count = 10) {
    const { platformName, productName, productId } = config;
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const url = this.getEndpoint(platform);
    const params = {
      cpvUuid: productId,
      product: productName,
      count,
    };

    return httpResults(config).get(url, { params });
  },
};
