import { currentApp } from '../utility';

let touchEndY = 0;
let touchStartY = 0;

const enablePullToRefresh = () => {
  window.document.documentElement.style.overscrollBehavior = 'auto';
  window.document.body.style.overscrollBehavior = 'auto';
};

const removePullToRefresh = () => {
  window.document.documentElement.style.overscrollBehavior = 'none';
  window.document.body.style.overscrollBehavior = 'none';
};

const handleTouchStart = (event) => {
  removePullToRefresh();
  touchStartY = event.changedTouches[0].screenY;
};

const checkDirection = (callback) => {
  const touchDistance = 100;

  if (touchEndY > touchStartY + touchDistance) {
    callback();
    enablePullToRefresh();
  }
};

const handleTouchEnd = callback => (event) => {
  touchEndY = event.changedTouches[0].screenY;
  checkDirection(callback);
};

const swipeDownVue2 = {
  bind(element, binding) {
    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchend', handleTouchEnd(binding.value));
  },
  unbind(element, binding) {
    element.removeEventListener('touchstart', handleTouchStart);
    element.removeEventListener('touchend', handleTouchEnd(binding.value));
  },
};

const swipeDownVue3 = {
  beforeMount(el, binding) {
    el.addEventListener('touchstart', handleTouchStart);
    el.addEventListener('touchend', handleTouchEnd(binding.value));
  },
  unmounted(el, binding) {
    el.removeEventListener('touchstart', handleTouchStart);
    el.removeEventListener('touchend', handleTouchEnd(binding.value));
  },
};
const swipeDown = currentApp.isVue2() ? swipeDownVue2 : swipeDownVue3;
export default swipeDown;
