<template>
  <div class="ticketTypes">
    <Tabs
      :tabs="activeTicketTypes"
      :activeTab="this.activeTicketType"
      buttonType="rounded"
      @change="handleChange" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Tabs from '../../Tabs';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipTicketTypes',
  components: {
    Tabs,
  },
  computed: {
    ...mapGetters('gamesBetslip', [
      'activeTicketType',
      'activeTicketTypes',
      'isManualMode',
      'isSingle',
      'isCombo',
      'minBetPayment',
      'minBetPaymentForBet',
      'ticket',
    ]),
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'calculateTotalPayment',
      'calculateTotalOdds',
      'callTotalPaymentSplitAlgorithm',
      'calculatePossibleWinCombo',
      'findDuplicateEventsAndUnlock',
      'findDuplicateEventsAndLock',
      'setActiveTicketType',
      'setActiveTicketType',
      'setTotalPaymentValue',
      'setTicket',
      'validateTotalPayment',
      'validateAllBets',
    ]),
    async callSingleActions() {
      this.findDuplicateEventsAndUnlock(this.ticket);

      if (this.isManualMode) {
        await this.callTotalPaymentSplitAlgorithm();
      }

      if (this.ticket.length && !this.isManualMode) {
        await this.calculateTotalPayment();
      }

      await this.validateAllBets();
    },
    async callComboActions() {
      if (!this.isManualMode && this.ticket.length) {
        await this.setTotalPaymentValue(this.minBetPayment);
      }

      this.calculateTotalOdds();
      this.calculatePossibleWinCombo();
    },
    ...mapActions('notifications', ['clearNotification']),
    async handleChange(value) {
      this.setActiveTicketType(value);

      await this.clearNotification();

      if (this.isSingle) {
        await this.callSingleActions();
        await this.validateTotalPayment();
      }

      if (this.isCombo) {
        await this.callComboActions();
        await this.findDuplicateEventsAndLock(this.ticket);
        await this.validateTotalPayment();
      }

      eventBus.$emit('formatBetslipInputValue');
    },
  },
  beforeMount() {
    this.setActiveTicketType(this.activeTicketTypes[0]);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tabs {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  background-color: var(--background-1);
  padding: 10px;
  gap: 10px;

  .tab-button {
    background-color: var(--background-1);
    border-bottom: 2px solid transparent;
    height: 60px;
    padding: 0;

    button {
      background-color: var(--background-1);
      font-weight: normal;
      height: 60px;
      border: 1px solid var(--button);
      border-radius: 2px;
      font-size: 18px;
      font-weight: 700;

      &.active {
        border: 1px solid var(--secondary);
        border-radius: 2px;
      }
    }
  }
}
</style>
