<template>
  <transition name="slide" mode="out-in">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'FadeTransition',
};
</script>

<style>
@keyframes slideup {
  0% {
    transform: translate(0, 5px);
  }
  50% {
    transform: translate(0, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.slide-enter-active,
.slide-leave-active {
  opacity: 1;
  animation-name: slideup;
  animation-duration: 0.125s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>
