import Gateway from '@nsftx/seven-gravity-gateway';

let gateway = null;

export default {
  init() {
    const GatewayConstructor = Gateway.slave;
    gateway = GatewayConstructor({
      debug: true,
      slaveId: 'GamesClientLuckySix',
      allowedOrigins: '*',
      data: {
        title: 'Lucky Six',
      },
      load: () => {
        gateway.sendMessage({
          action: 'Slave.Loaded',
          data: {},
        });
      },
    });
  },
  sendMessage(action, data) {
    const msg = {
      action,
      data,
    };
    gateway.sendMessage(msg);
  },
  setupListeners() {
    gateway.on('AuthorizationChanged', () => {});
    gateway.on('BalanceChanged', () => {});
    gateway.on('User.Login', () => {});
    gateway.on('User.Logout', () => {});
  },
};
