<template>
  <div ref="betslipbet">
    <div class="betslip-bet"
         :class="{ multilineBet: isMultilineBet,
          swipedLeftAnimation: swipedLeft,
          swipedRightAnimation: swipedRight,
          closeAnimation: closeAnimation }">

      <div class="header">
        <div class="outcome info"
             v-html="convertOutcomeValue(bet)" />
        <div :class="{ disabled: ticketPayinInProgress }"
             class="remove icon icon-close info"
             @click="removeOnClose"></div>

      </div>
      <div class="market info"> {{ convertMarketValue(bet) }} </div>
      <div class="footer flex">
        <div class="round info background"
             :class="{'full-width': ticketTypeIsCombo}"
             v-if="bet.round">
          <span>{{translations.general_round}}: {{bet.round}}</span>
        </div>
        <div class="stake info background"
             v-if="!ticketTypeIsCombo"
             :class="{'payment-per-bet': isPaymentPerBetActive}">
          <span>{{ translations.general_stake }}</span>
          <Input v-if="isPaymentPerBetActive"
                 class="stake-value"
                 orientation="right"
                 :round="toRound"
                 :value="bet.payment"
                 @change="updateBetPayment($event, bet)" />
          <span v-else
                class="stake-value">{{bet.payment && bet.payment.toFixed(2)}} </span>
        </div>
      </div>
      <div v-if="possibleWinPerBet"
           class="possible-win-bet info">
        <span>
          {{translations.general_possible_winning}}: {{ calcPossibleWinPerBet(bet).rounded }}
        </span>
      </div>
      <div v-if="bet.locked"
           class="locked">
        <i class="icon icon-lock" />
      </div>
    </div>
  </div>
</template>

<script>
import BetslipBet from '@/components/BetslipArea/BetslipAreaA/BetslipBet';

export default {
  name: 'BetslipBet',
  extends: BetslipBet,
};

</script>

<style lang="scss" scoped>
.betslip-bet {
  position: relative;
  height: 127px;
  border-radius: 2px;
  padding: 16px;
  background-color: var(--card);
  margin-bottom: 1px;
  transition: background-color 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &.multilineBet {
    height: 107px;
  }

  &:hover {
    background-color: var(--card-hover);
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;

    .remove {
      opacity: 0.3;
      cursor: pointer;
      font-size: 24px;
      &.disabled {
        z-index: 1;
        user-select: none !important;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }
  }

  .outcome {
    margin-bottom: 8px;
    &.info {
      font-size: 20px;
    }
  }

  .market {
    &.info {
      margin-bottom: 10px;
      font-size: 18px;
      color: var(--secondary);
    }
  }

  .footer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 4px;
    font-size: 18px;
    .background {
      display: flex;
      align-items: center;
      padding: 0 8px;
      width: 100%;
      height: 36px;
      border-radius: 2px;
      background-color: var(--card-section);
      justify-content: space-between;

      &.full-width {
        width: 100%;
      }

      > span:not(.stake-value):not(.odd-value) {
        opacity: 0.6;
      }
    }

    .stake-value,
    .odd-value {
      font-weight: bold;
    }

    .stake-value {
      text-align: right;
    }
  }

  .locked {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--overlay-lock);
    opacity: 0.7;
    text-align: center;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>
