<template>
  <button class="button clearfix"
          :class="[typeClass, variation, { disabled: disabled }, { 'button-active': isActive }]"
          :disabled="disabled"
          v-interactionStates
          @click="$emit('change', $event)"
          @contextmenu="handler($event)">
  <div v-if="disabled" class="disabled-overlay"></div>
    <span class="label">{{ label }}</span>
    <span v-show="independentLabel" class="label-dynamic">
      &nbsp;{{ independentLabel }}</span>
    <slot />
  </button>
</template>

<script>
import { interactionStates } from '../directives';
import { disablable, typeable } from '../mixins/index';

export default {
  name: 'Button',
  emits: ['change'],
  mixins: [
    disablable,
    typeable,
  ],
  directives: {
    interactionStates,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number],
      default: 'Button',
    },
    // Offers granular control
    independentLabel: {
      type: [String, Number],
      default: '',
    },
    variation: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    handler(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  border: 0;
  border-radius: var(--button-border-radius, var(--border-radius));
  font-size: 14px;
  height: 40px;
  width: inherit;
  line-height: 16px;
  margin: 0;
  outline: 0;
  text-align: center;
  vertical-align: middle;
  background-color: var(--button);
  color: var(--button-text-color, var(--text-primary-1));
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: Roboto, 'sans-serif';
  -webkit-tap-highlight-color: transparent;
  --free-bet-hover: #a5d260ff;

  &.fragmented-label {
    display: flex;
    align-items: center;
    justify-content: center;
    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.button-active {
      color: var(--custom-text-color);
    }
  }

  &::after {
    background-color: var(--button);
  }
  &:focus {
    background-color: var(-button);
  }

  &.button-active {
    background-color: var(--primary);
    color: var(--button-text-color-active, var(--text-primary-1));
    &::after {
      background-color: var(--primary);
    }
    &.login-payin {
      color: var(--payin-button-text, var(--custom-text-color));
    }
    &.free-bet {
      background-color: var(--free-bet);
      color: var(--text-secondary-1);
      font-weight: 500;
    }
  }

  &.brand {
    background-color: var(--primary);
    background: var(--gradient, var(--primary));

    &::after {
      background-color: var(--primary);
    }
  }

  &.type-web {
    &.brand {
      .label {
        color: var(--custom-text-color);
      }
    }
  }

  &.positive {
    &.login-payin {
      background-color: var(--payin-button, var(--system-positive));
      color: var(--payin-button-text, #ffffff);
      &:hover:not(:disabled):not(.disabled) {
        background-color: var(--payin-button-hover, var(--hover-state));
      }
      &:active:not(:disabled):not(.disabled) {
        background-color: var(--payin-button-active, var(--active-state));
      }
    }
    background-color: var(--system-positive);
    background: var(--gradient, var(--system-positive));

    &.free-bet {
      background-color: var(--free-bet);
      color: var(--text-secondary-1);
      font-weight: 500;
      &:hover:not(:disabled):not(.disabled) {
        background-color: var(--free-bet-hover, var(--free-bet-hover-state));
      }
    }

    &::after {
      background-color: var(--system-positive);
    }
  }

  &.tertiary {
    background-color: var(--tertiary, var(--button));
    color: var(--text-tertiary-1, var(--text-primary-1));

    &::after {
      background-color: var(--tertiary, var(--button));
    }
  }

  &.negative {
    background-color: var(--system-negative);

    &::after {
      background-color: var(--system-negative);
    }
  }

  &:active:not(:disabled):not(.disabled) {
    background-color: var(--active-state);
  }

  &:disabled,
  &.disabled,
  &[disabled] {
    cursor: default;
    color: var(--text-tertiary-2, var(--text-primary-3));
    &.type-web {
      &.brand {
        .label {
          color: var(--text-tertiary-2, var(--custom-text-color));
        }
      }
    }
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &.type-mobile {
    line-height: inherit;
  }

  &.type-terminal {
    height: 54px;
    min-width: 64px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    padding: 16px;
    &.brand {
      background-color: var(--primary);

      &::after {
        background-color: var(--primary);
      }
    }

    &.positive {
      &::after {
        background-color: var(--system-positive);
      }
    }
  }

  &.type-terminal-large {
    height: 72px;
    font-size: 24px;
    line-height: 18px;
    font-weight: 500;
    padding: 27px;
    &.brand {
      background-color: var(--primary);

      &::after {
        background-color: var(--primary);
      }
    }

    &.positive {
      background-color: var(--system-positive);

      &::after {
        background-color: var(--system-positive);
      }
    }
  }
}
.disabled-overlay {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.2;
}
@media (hover:hover) and (pointer: fine) {
  .button {
    &:hover:not(:disabled):not(.disabled) {
      background-color: var(--hover-state);
    }

    &:active:not(:disabled):not(.disabled) {
      background-color: var(--active-state);
    }
  }
}

@media only screen and (min-width: 1910px) {
  .button {
    &.type-terminal {
      min-width: 72px;
      font-size: 24px;
      height: 72px;
    }
  }
}

@media only screen and (min-height: 1790px) {
  .button {
    &.type-terminal {
      min-width: 72px;
      font-size: 24px;
      height: 72px;
    }
  }
}
</style>
