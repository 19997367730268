<template>
  <div class="betslip">
    <BetslipHeader :items="types" v-if="showHeader"/>
    <BetslipTicket v-on="$listeners"
                   v-bind="$props"/>
    <BetslipLock v-if="blockers.length" :blockers="blockers"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import eventBus from '../../../utility/eventBus';
import BetslipHeader from './BetslipHeader';
import BetslipTicket from './BetslipTicket';
import BetslipLock from './BetslipLock';

export default {
  name: 'BetslipAreaC',
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    betslipTypes: {
      type: Array,
    },
    isPossibleWinActive: {
      type: Boolean,
      default: false,
    },
    product: {
      type: String,
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BetslipLock,
    BetslipHeader,
    BetslipTicket,
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
      blockers: 'betslip/betslipBlockers',
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
    }),
    types() {
      if (!this.betslipTypes) {
        return [
          {
            type: 3,
            name: this.translations.general_ticket_single,
            value: 'single',
            active: true,
          },
          {
            type: 1,
            name: this.translations.general_ticket_combo,
            value: 'combo',
            active: true,
          },
          /* {
            type: 2,
            name: this.translations.general_ticket_system,
            value: 'system',
            active: false,
          }, */
        ];
      }
      return this.betslipTypes;
    },
  },
  methods: {
    ...mapActions({
      setTicketType: 'betslip/setTicketType',
      validateAllBets: 'betslip/validateAllBets',
    }),
  },
  mounted() {
    eventBus.$on('focusBetslipFooterInput', () => {
      this.validateAllBets();
      const focusElementName = this.isPaymentPerBetActive
        ? 'inputPaymentPerBet' : 'globalPayment';
      const focusElement = document.querySelectorAll(`[name=${focusElementName}]`)[0];
      if (focusElement) focusElement.focus();
    });
    eventBus.$on('focusBet', () => {
      setTimeout(() => {
        const firstBet = document.querySelectorAll('[name="betslipBet0"]')[0];
        if (firstBet) firstBet.focus();
      }, 100);
    });
  },
  beforeMount() {
    this.setTicketType(this.types[0]);
  },
};
</script>

<style lang="scss" scoped>
  .betslip {
    --background-1: #2E3743;
    --input: #fff;
    --input-width: 76px;
    --input-height: 32px;
    --input-padding: 0 0 0 10px;
    --button: #6B7585;
    --input-border-radius: none;
    --betslip-min-width: 100%;
    --betslip-bet-locked: #FF5252;
    --betslip-bet-odds-down-bg: #97454B;
    --betslip-bet-odds-down-border: #FF5252;
    --betslip-bet-odds-up-bg: #557542;
    --betslip-bet-odds-up-border: #7CB342;
    background-color: var(--background-1);
    color: var(--text-primary-1);
    font-family: var(--input-font-family);
    font-size: var(--font-size-medium, 14px);
    width: var(--betslip-min-width, 300px);
    height: 100%;
  }
</style>
