<template>
  <div class="predefinedStakesWrapper" v-clickout="close">
    <div class="label">{{ translations.general_stake }}</div>
    <div class="top">
      <BetslipInput
        class="stakeInput"
        @change="handleInputChange"
        labelPosition="left"
        :label="translations.general_stake"
        :parentValue="tempStake"
        :isFromDispatchEvent="isFromDispatchEvent"
        ref="betslipInput"
        type="terminal"
        @click="handleClick"
      />
      <div class="reset" @click="resetStake">
        <i class="icon icon-backspace-a" />
      </div>
    </div>
    <div class="keyboard">
      <Keyboard @keyboardButton="handleKeyboardButton" type="terminal" />
    </div>
    <div class="bottom">
      <div class="predefinedStakes">
        <div class="predefinedStake"
          v-for="predefinedStake in paymentChips"
          :key="predefinedStake"
        >
          <Button @change="handleChange(predefinedStake)" :label="addPlusSign(predefinedStake)" />
        </div>
      </div>
      <div class="actions">
        <Button :label="translations.general_cancel" @change="close" />
        <Button :label="translations.general_accept" @change="acceptTempStakeToActiveInput" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNil } from 'lodash';
import Button from '../../Button';
import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import Keyboard from '../../Keyboard';
import { clickout } from '../../../directives';
import { eventBus, inputUtils } from '../../../utility';

export default {
  data() {
    return {
      tempStake: 0,
      isManualEntryAllowed: false,
      emitTimeout: null,
      isFromDispatchEvent: false,
      paymentChips: null,
    };
  },
  components: {
    Button,
    BetslipInput,
    Keyboard,
  },
  directives: {
    clickout,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeBetslipInput', 'config', 'paymentPerBet', 'rules', 'roundNumber', 'totalPayment', 'ticket']),
    ...mapGetters(['translations']),
    betslipInput() {
      return this.$refs?.betslipInput?.$refs?.input;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['setIsStakeBoxVisible', 'setTotalPayment', 'setBetPayment', 'setPaymentPerBet', 'updateBet']),
    addPlusSign(value) {
      return `+${value}`;
    },
    acceptTempStakeToActiveInput() {
      // We use underscore for inputs that are not from bets
      // to differentiate it from bet ID (UUID)
      if (this.activeBetslipInput.startsWith('_')) {
        if (this.activeBetslipInput === '_totalPayment') {
          this.setTotalPayment(this.tempStake);
        }

        if (this.activeBetslipInput === '_paymentPerBet') {
          this.setPaymentPerBet(this.tempStake);
        }
      } else {
        const targetBet = this.ticket.find(bet => bet.id === this.activeBetslipInput);

        this.setBetPayment({
          betStake: this.tempStake,
          betId: targetBet.id,
          combinations: targetBet.combinations,
        });
      }

      this.close();
    },
    close() {
      this.$emit('close');
      this.setIsStakeBoxVisible(false);

      if (this.emitTimeout) {
        clearTimeout(this.emitTimeout);
      }

      setTimeout(() => {
        eventBus.$emit('formatBetslipInputValue');
      }, 0);
    },
    async handleChange(predefinedStake) {
      if (this.isDesktop) {
        this.setPredefinedStakeToActiveInput(predefinedStake);
      } else {
        this.setPredefinedStakeToInput(predefinedStake);
      }
    },
    handleInputChange(value) {
      this.tempStake = value;
    },
    handleKeyboardButton(button) {
      this.betslipInput.focus();
      this.isFromDispatchEvent = true;

      if (this.isManualEntryAllowed) {
        this.tempStake = '';
      }

      this.$nextTick(() => {
        inputUtils.dispatchKeyDownEvent(this.betslipInput, String(button));
        inputUtils.dispatchKeyUpEvent(this.betslipInput, String(button));
      });

      this.isManualEntryAllowed = false;
    },
    handleClick() {
      this.isManualEntryAllowed = true;
      this.isFromDispatchEvent = false;
    },
    resetStake() {
      this.tempStake = this.roundNumber(this.rules.minBetAmount.value);

      if (this.betslipInput) {
        this.betslipInput.click();
      }
    },
    setPredefinedStakeToActiveInput(predefinedStake) {
      // We use underscore for inputs that are not from bets
      // to differentiate it from bet ID (UUID)
      if (this.activeBetslipInput.startsWith('_')) {
        if (this.activeBetslipInput === '_totalPayment') {
          const totalPayment = Number(this.totalPayment) + predefinedStake;

          this.setTotalPayment(totalPayment);
        }

        if (this.activeBetslipInput === '_paymentPerBet') {
          const paymentPerBet = Number(this.paymentPerBet) + predefinedStake;

          this.setPaymentPerBet(paymentPerBet);
        }
      } else {
        const targetBet = this.ticket.find(bet => bet.id === this.activeBetslipInput);

        this.setBetPayment({
          betStake: Number(targetBet.stake) + predefinedStake,
          betId: targetBet.id,
          combinations: targetBet.combinations,
        });
      }
    },
    setPredefinedStakeToInput(predefinedStake) {
      const newTempStake = Number(this.tempStake) + predefinedStake;

      this.tempStake = newTempStake;

      this.$nextTick(() => {
        eventBus.$emit('formatBetslipInputValue');
      });

      this.isManualEntryAllowed = true;
    },
    setInitialTempStakeValue() {
      // We use underscore for inputs that are not from bets
      // to differentiate it from bet ID (UUID)
      if (this.activeBetslipInput.startsWith('_')) {
        if (this.activeBetslipInput === '_totalPayment') {
          this.tempStake = this.totalPayment;
        }

        if (this.activeBetslipInput === '_paymentPerBet') {
          this.tempStake = this.paymentPerBet;
        }
      } else {
        const targetBet = this.ticket.find(bet => bet.id === this.activeBetslipInput);

        this.tempStake = targetBet.stake;
      }

      this.$nextTick(() => {
        eventBus.$emit('formatBetslipInputValue');
      });
    },
  },
  mounted() {
    const { paymentChips } = this.config;

    this.paymentChips = !isNil(paymentChips) ? paymentChips : [0.5, 1, 2, 5, 10, 20];

    if (!this.isDesktop) {
      this.setInitialTempStakeValue();
      this.betslipInput.click();
    }
  },
};
</script>

<style lang="scss" scoped>
.predefinedStakesWrapper {
  position: absolute;
  width: calc(100% - 8px);
  bottom: 8px;
  left: 8px;
  z-index: 99;
  padding-top: 17px;
  padding-bottom: 19px;
  background-color: var(--card);
  color: var(--text-primary-1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  .top {
    padding: 0 16px;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;

    .icon-backspace-a {
      width: 116px;
      height: 54px;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--button);
      border-radius: 2px;
    }
  }

  .label {
    font-size: 19px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 23px;
  }

  .stakeInput {
    ::v-deep .betslip-input {
      width: 100%;
    }
  }

  .keyboard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 33px;
  }

  .bottom {
    margin-top: 8px;
    padding: 0 32px;

    .predefinedStakes {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;

      .predefinedStake {
        width: 100%;
        height: 54px;

        ::v-deep .button {
          height: 100%;

          .label {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .actions {
    margin-top: 19px;
    display: flex;
    justify-content: flex-end;
    gap: 43px;

    ::v-deep .button {
      background-color: transparent;
      color: var(--secondary);
      text-transform: uppercase;
      height: 100%;

      .label {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (min-width: 1910px) {
  .predefinedStakesWrapper {
    padding-top: 25px;
    padding-bottom: 27px;

    .label {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 33px;
    }

    .top {
      gap: 16px;
      margin-bottom: 16px;

      .icon-backspace-a {
        width: 112px;
        height: 72px;
      }
    }

    .bottom {
      margin-top: 16px;

      .predefinedStakes {
        .predefinedStake {
          height: 72px;

          ::v-deep .button {
            .label {
              font-size: 24px;
            }
          }
        }
      }
    }

    .actions {
      margin-top: 35px;
      ::v-deep .button {
        height: 100%;
        .label {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
