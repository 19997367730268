<template>
  <div class="betslip-footer">
    <!-- STAKE PER BET -->
    <div class="stake-per-bet"
       v-if="isPaymentPerBetActive && !ticketTypeIsCombo">
      <Input :label-active="false"
        name="inputPaymentPerBet"
        :round="toRound"
        :value="paymentPerBet"
        :shouldRound="false"
        type="retail"
        input-type="text"
        v-on:tabEnterEmit="handlePayin"
        @keyup="updateStake($event.target.value, false, $event)"
        @input="keepNumeric($event)"
        @fieldFocus="validateAllBets"/>
      <button>
        {{ translations.general_stake_per_bet }}
      </button>
    </div>
    <!-- TOTAL ODDS -->
    <div class="total-odds input-wrapper"
       v-if="ticketTypeIsCombo">
      <Input :label-active="false"
        :value="totalOdds"
        :round="toRound"
        :disabled="true"
        input-type="text"
        type="retail"/>
      <button>
        {{ translations.general_total_odds }}
      </button>
    </div>
    <!--  POSSIBLE WIN -->
    <div class="possible-win input-wrapper"
       v-if="isPossibleWinActive">
      <Input :label-active="false"
        :value="possibleWin.value"
        :round="toRound"
        :disabled="true"
        input-type="text"
        type="retail"/>
      <button>
        {{ translations.general_possible_winning }}
      </button>
    </div>
    <!-- PAYIN -->
    <div class="payin input-wrapper">
      <Input v-if="isPaymentPerBetActive && !ticketTypeIsCombo"
        :label-active="false"
        :round="toRound"
        :value="totalPayment"
        :disabled="true"/>
      <Input v-if="!isPaymentPerBetActive && !ticketTypeIsCombo"
        :label-active="false"
        :value="payment"
        :round="toRound"
        name="globalPayment"
        :shouldRound="shouldRound"
        type="retail"
        input-type="text"
        v-on:tabEnterEmit="handlePayin"
        @input="keepNumeric($event)"/>
      <Input v-if="ticketTypeIsCombo"
        :label-active="false"
        :value="payment"
        :round="toRound"
        :shouldRound="shouldRound"
        type="retail"
        input-type="text"
        name="globalPayment"
        v-on:tabEnterEmit="handlePayin"
        @keyup="updateStake($event.target.value, false)"
        @input="keepNumeric($event)"/>
      <button @click="validateAndPayin">
        {{ translations.general_pay_in }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNil, throttle } from 'lodash';
import eventBus from '../../../utility/eventBus';
import helpers from '../../../mixins/helpers';
import ticket from '../../../mixins/betslip/ticket';
import totalOdds from '../../../mixins/betslip/totalOdds';
import possibleWin from '../../../mixins/betslip/possibleWin';
import Input from '../../Input';

export default {
  name: 'BetslipFooter',
  mixins: [helpers, ticket, totalOdds, possibleWin],
  props: ['isPossibleWinActive'],
  components: {
    Input,
  },
  data() {
    return {
      shouldRound: true,
    };
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
      toRound: 'betslip/toRound',
      tickets: 'betslip/tickets',
      isPayinBtnDisabled: 'betslip/isPayinBtnDisabled',
      numOfBets: 'betslip/numOfBets',
      maxBetNumber: 'betslip/maxBetNumber',
      minBetAmount: 'minBetAmount',
      maxBetAmount: 'maxBetAmount',
      payment: 'betslip/payment',
    }),
  },
  methods: {
    ...mapActions({
      paymentModified: 'betslip/paymentModified',
      disablePayinButton: 'betslip/disablePayinButton',
      validateAllBets: 'betslip/validateAllBets',
    }),
    ticketPayin() {
      if (this.isPaymentPerBetActive) {
        if (this.payment > this.maxBetAmount) {
          eventBus.$emit('focusBettingInput');
          this.updateStake(String(this.maxBetAmount));
        }
        if (this.payment < this.minBetAmount && this.payment !== '') {
          eventBus.$emit('focusBettingInput');
          this.updateStake(String(this.minBetAmount));
        }
      }
      if (!this.isPayinBtnDisabled) {
        this.disablePayinButton(true);
        this.$root.$emit('TicketPayin');
        // include external library 'tiny-emitter' for event bus,
        // this provides the same event emitter API as in Vue 2
        eventBus.$emit('TicketPayin');
      }
    },
    updateStake(payment, shouldRound, event) {
      if (event && event.key === 'Enter') return;
      if (!isNil(payment)) {
        const paymentStringInValidFormat = payment.replace(',', '.');
        this.shouldRound = shouldRound;
        this.paymentModified(true);
        this.setStakeValue(paymentStringInValidFormat).then(() => {
          if (this.isPaymentPerBetActive) this.setTotalStakeValue();
        });
      }
    },
    handlePayin(event) {
      if (event.key === 'Enter') {
        setTimeout(() => {
          this.ticketPayin();
        }, 100);
      } else {
        setTimeout(() => {
          if (this.numOfBets === this.maxBetNumber) eventBus.$emit('focusBet');
          else eventBus.$emit('focusBettingInput');
        }, 100);
      }
    },
    keepNumeric(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9.,]/g, '');
    },
    validateAndPayin() {
      this.validateAllBets().then(() => {
        if (this.isPayinBtnDisabled || this.tickets.length === 0) return;
        this.throttledTicketPayin();
      });
    },
  },
  created() {
    this.throttledTicketPayin = throttle(() => {
      this.ticketPayin();
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
.betslip-footer {
  display: grid;
  grid-row-gap: 8px;
  padding: 14px 18px;
  > div {
    --input-width: calc(100% - 117px);
    --text-primary-1: #000;
    display: flex;
    button {
      background-color: var(--button);
      border: none;
      outline: none;
      width: 117px;
      --text-primary-1: #fff;
      color: var(--text-primary-1);
    }
    &.input-wrapper {
      > div.disabled {
        --text-primary-1: #fff;
        color: var(--text-primary-1);
        --input: #252930;
      }
    }
  }
  .payin {
    button {
      background: #155ABB;
    }
  }
}
</style>
