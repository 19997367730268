<template>
  <component :is="currentTicketType.component" />
</template>

<script>
import { mapGetters } from 'vuex';
import BetslipFooterCombo from './BetslipFooterCombo';
import BetslipFooterSingle from './BetslipFooterSingle';

export default {
  name: 'BetslipFooter',
  components: {
    BetslipFooterCombo,
    BetslipFooterSingle,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketType']),
    ticketTypes() {
      return [
        {
          value: 'single',
          component: BetslipFooterSingle,
        },
        {
          value: 'combo',
          component: BetslipFooterCombo,
        },
      ];
    },
    currentTicketType() {
      return this.ticketTypes.find(ticketType => ticketType.value === this.activeTicketType.value);
    },
  },
};
</script>
