<template>
  <div v-if="isOpen">
    <Scrollable>
      <div class="items" :class="[direction, typeClass]">
        <div v-for="(item, i) of items" :key="i"
             @click="selectItem(item)">
          {{ item }}
        </div>
      </div>
    </Scrollable>
  </div>
</template>

<script>
import Scrollable from '../Scrollable';
import { typeable } from '../../mixins';


export default {
  name: 'SelectDesktop',
  components: {
    Scrollable,
  },
  mixins: [typeable],
  props: {
    items: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    orientation: {
      type: String,
      default: 'bottom',
    },
  },
  computed: {
    direction() {
      return this.orientation;
    },
  },
  methods: {
    selectItem(option) {
      this.$emit('change', option);
    },
  },
};
</script>

<style scoped lang="scss">
.items {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: var(--card);
  border-radius: 2px;
  max-height: 250px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);

  &.type-terminal {
    font-size: 18px;
  }

  &.top {
    bottom: var(--select-height, 40px);;
  }

  div {
    padding-left: 8px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
  }

  div:hover {
    background-color: var(--button-hover);
  }
}

@media only screen and (min-width: 1920px) {
  .items {
    &.type-terminal {
      font-size: 20px;
    }
  }
}
</style>
