import { mapGetters, mapActions } from 'vuex';
import { isNumber } from 'lodash';
import eventBus from '../../utility/eventBus';

export default {
  computed: {
    ...mapGetters({
      translations: 'translations',
      toRound: 'betslip/toRound',
      editBet: 'betslip/editBet',
      minPaymentPerBet: 'betslip/minPaymentPerBet',
    }),
  },
  methods: {
    ...mapActions('betslip', [
      'removeBet',
      'calculateAndUpdateBetValue',
      'setTotalStakeValue',
      'setStakeValue',
      'validateAllBets',
      'setEditBet',
      'updateStake',
      'paymentModified',
    ]),
    sendEditAction() {
      if (!this.editable) return;
      this.setEditBet(this.editBet === this.index ? null : this.index);
      const bet = isNumber(this.editBet) ? this.bet : null;
      this.$root.$emit('EditBet', bet);
      // include external library 'tiny-emitter' for event bus,
      // this provides the same event emitter API as in Vue 2
      eventBus.$emit('EditBet', bet);
    },
    updateBetPayment(payment, bet) {
      if (payment !== bet.payment) {
        this.paymentModified(false);
      }
      const stake = payment || this.minPaymentPerBet.toString();
      this.calculateAndUpdateBetValue({ stake, bet, payment: stake }).then(() => {
        this.setTotalStakeValue();
        this.validateAllBets();
      });
    },
    focusFooterInputs(event) {
      if (event.key === 'Enter') {
        eventBus.$emit('focusBetslipFooterInput', event.key);
      }
    },
  },
};
