<template>
  <div class="betslip-ticket"
       :class="{'multi-type': ticketTypeComponents.length > 1}"
       ref="betslipTicket">
    <BetslipBody ref="betslipBody"
                 :body-height="bodyHeight"
                 v-bind="$props" />
    <BetslipFooter ref="betslipFooter"
                   :isPossibleWinActive="isPossibleWinActive" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ticket from '../../../mixins/betslip/ticket';
import BetslipBody from './BetslipBody';
import BetslipFooter from './BetslipFooter';

export default {
  name: 'BetslipTicket',
  components: {
    BetslipBody,
    BetslipFooter,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    isPossibleWinActive: {
      type: Boolean,
      default: false,
    },
    product: {
      type: String,
      default: '',
    },
  },
  mixins: [ticket],
  data() {
    return {
      bodyHeight: '',
    };
  },
  computed: {
    ...mapGetters({
      ticketTypeComponents: 'betslip/ticketTypeComponents',
    }),
  },
  methods: {
    calcHeight() {
      this.$nextTick(() => {
        const ticketHeight = this.$refs.betslipTicket.clientHeight;
        const footerHeight = this.$refs.betslipFooter.$el.clientHeight;
        this.bodyHeight = `${ticketHeight - footerHeight}px`;
      });
    },
  },
  mounted() {
    this.calcHeight();
    window.addEventListener('resize', this.calcHeight);
  },
  unmounted() {
    window.removeEventListener('resize', this.calcHeight);
  },
  watch: {
    tickets() {
      this.calcHeight();
    },
  },
};
</script>

<style lang="scss" scoped>

.betslip-ticket {
  height: 100%;
  &.multi-type {
    height: calc(100% - 40px);
  }
}
</style>
