<template>
  <div class="betslipLockersWrapper">
    <i class="icon icon-lock"></i>
    <div class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BetslipLock',
  computed: {
    ...mapGetters('gamesBetslip', ['betslipBlockers']),
    message() {
      return this.betslipBlockers[0].message;
    },
  },
};
</script>

<style lang="scss" scoped>
.betslipLockersWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--text-primary-1);
  background-color: var(--overlay-lock);
  z-index: 100;
  font-size: 20px;

  .icon-lock {
    font-size: 45px;
    color: var(--secondary);
  }

  .message {
    margin-top: 32px;
  }
}
</style>
