<template>
  <Button :type="type" @change="handleRemoveBets()"
          variation="tertiary"
          label=""
          :disabled="isDisabled"
  >
    <i class="icon icon-trash"></i>
  </Button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '../../Button';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipDeleteButton',
  props: {
    type: {
      type: String,
    },
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters('gamesBetslip', ['numberOfBets']),
    ...mapGetters(['isBettingDisabled']),
    isDisabled() {
      return this.numberOfBets === 0;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['clearBetslip', 'setIsPayinButtonDisabled']),
    handleRemoveBets() {
      this.setIsPayinButtonDisabled(true);

      eventBus.$emit('ClearBetslip');

      setTimeout(() => {
        this.clearBetslip();
        this.setIsPayinButtonDisabled(this.isBettingDisabled);
      }, 300);
    },
  },
};
</script>
