<template>
  <div class="betslip-bet"
       :class="{
        'active': editBet === index,
       }">
    <div class="bet" @click="sendEditAction()">
      <div class="bet-outcome">
        <span>{{bet.displayValue}}</span>
      </div>
      <div class="bet-event-name ">{{bet.displayName}}</div>
      <div class="bet-type">{{bet.displayBetType}}</div>
      <div class="display-id">
        <span v-if="bet.numEvents > 1">{{translations.main_events}}:</span>
        <span v-else>{{translations.main_event}}:</span>
        {{bet.round}}
      </div>
    </div>
    <div class="betslip-bet-right">
    <div class="close"
    :class="{'pointer': !bet.locked}"
    @click="removeBet(bet)">
    <em class="icon icon-close-a"
        :class="{'pointer': bet.locked}"></em>
   </div>
   <div class="input-wrapper"
   :class="{'payment-per-bet': isPaymentPerBetActive}">
      <span class="input-label">{{translations.main_stake}}</span>
    <Input v-if="isPaymentPerBetActive"
               :label-active="false"
               :round="toRound"
               :name="`betslipBet${index}`"
               :value="bet.payment"
               type="retail"
               input-type= "text"
               orientation= "center"
               :min="minPaymentPerBet.toString()"
               v-on:tabEnterEmit="focusFooterInputs"
               @change="updateBetPayment($event, bet)"/>
    <div class="input-value" v-else>
      {{bet.payment && bet.payment.toFixed(2)}}
    </div>
   </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '../../Input';
import helpers from '../../../mixins/helpers';
import betslipBet from '../../../mixins/betslip/betslipBet';

export default {
  name: 'BetslipBet',
  components: { Input },
  mixins: [helpers, betslipBet],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    bet: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
    }),
  },
};
</script>

<style lang="scss" scoped>
.betslip-bet {
    display: flex;
    justify-content: space-between;
    border-top:0.1rem solid #4f5c6c;
    border-bottom:0.1rem solid #4f5c6c;
    &.active {
    background-color: #252a31;
    }
    .bet {
        width: 70%;
        padding: 0.5rem 1rem 0rem 0.3rem;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #838383;
        .bet-outcome {
            font-weight: 700;
            color: #fff;
        }
    }
    .betslip-bet-right {
        width: 30%;
        display: flex;
        flex-direction: column;
        border-left: 0.1rem solid #4f5c6c;
        font-size: 1rem;
        line-height: 1.5rem;
        .close {
            height: 50%;
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            color: #ebebeb;
        }
        .input-wrapper {
            height: 50%;
            width: 100%;
            text-align: center;
            border-top: 0.1rem solid #4f5c6c;
            .input-label {
              color: #838383;
            }
            .input-value {
              color: #fff;
            }
        }
        .payment-per-bet {
          background-color: #fff;
          .input-label {
            color:#000;
          }
          .input-container {
              width: 100%;
              height: 50%;
            }
            ::v-deep {
              .input {
                color: #000;
              }
            }
        }
    }
}
</style>
