<template>
  <div class="betslip-ticket ticket-types">
    <BetslipBody v-bind="$props"
                 :activeTicket="ticketTypeActiveComponent"
                 :betslipLockedMessage="betslipLockedMessage">
    </BetslipBody>
  </div>
</template>

<script>
import BetslipTicket from '@/components/BetslipArea/BetslipAreaA/BetslipTicket';
import BetslipBody from './BetslipBody';

export default {
  name: 'BetslipTicket',
  extends: BetslipTicket,
  components: {
    BetslipBody,
  },
  props: {
    betslipLockedMessage: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.betslip-ticket {
  padding-top: 1px;

  .betslip-header {
    background: var(--background-1);
  }
  .ticket-types-wrapper {
    &.hidden {
      display: none;
    }
  }
}
</style>
