<template>
  <div :class="[{ phone: !isDesktop }, 'betslip-area-wrap']">
    <Transition name="slide">
      <BetslipA v-show="isBetslipOpened"
                :hidePayinArea="true"
                :imageBg="imageBg"
                :showNotification="false"
                :isPossibleWinActive="isPossibleWinActive"
                :betslipTypes="betslipTypes"
                :isBallDrawn="isBallDrawn"
                :productNameLabel="productNameLabel"
                :showLastTickets="showLastTickets"
                futureOrientation="top" />
    </Transition>
    <fade-transition>
      <StakeBox v-show="isStakeBoxOpened"
                @close="closeStakeBox"
                :isPaymentPerBet="isPaymentPerBetActive"
                @click="updateStakeValue($event)">
      </StakeBox>
    </fade-transition>
    <div class="betslip-wrap-footer">
      <BetslipInfoBar :showSingleBetSelected="showSingleBetSelected"
                      :marketTranslationKey="marketTranslationKey"
                      :removeBetsFromBetslip="removeBetsFromBetslip" />
      <BetslipFooter :isAddBetActive="isAddBetActive"
                     @fieldFocus="toggleStakeBox" />
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import ticket from '@/mixins/betslip/ticket';
import BetslipA from '../BetslipAreaA/BetslipAreaA';
import BetslipInfoBar from './BetslipInfoBar';
import BetslipFooter from './BetslipFooter';
import StakeBox from '../../StakeBox';
import FadeTransition from '../../FadeTransition';

export default {
  name: 'BetslipAreaB',
  components: {
    BetslipA,
    BetslipInfoBar,
    BetslipFooter,
    StakeBox,
    FadeTransition,
  },
  mixins: [ticket],
  props: {
    isAddBetActive: {
      type: Boolean,
      default: true,
    },
    isPossibleWinActive: {
      type: Boolean,
      default: true,
    },
    imageBg: {
      type: String,
      default: null,
    },
    betslipTypes: {
      type: Array,
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
    showSingleBetSelected: {
      type: Boolean,
      default: false,
    },
    marketTranslationKey: {
      type: String,
      default: null,
    },
    removeBetsFromBetslip: {
      type: Boolean,
      default: false,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
    showLastTickets: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isBetslipOpened: 'betslip/isBetslipOpened',
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      payment: 'betslip/payment',
      isDesktop: 'isDesktop',
    }),
  },
  data() {
    return {
      isStakeBoxOpened: false,
    };
  },
  methods: {
    ...mapActions({
      paymentModified: 'betslip/paymentModified',
      setTotalStakeValue: 'betslip/setTotalStakeValue',
    }),
    closeStakeBox() {
      this.isStakeBoxOpened = false;
    },
    toggleStakeBox() {
      if (!this.isStakeBoxOpened) {
        setTimeout(() => {
          this.isStakeBoxOpened = !this.isStakeBoxOpened;
        }, 200);
      }
    },
    updateStakeValue(value) {
      this.paymentModified(true);
      if (this.isPaymentPerBetActive) {
        this.setStakeValue(Number(value)).then(() => {
          this.setTotalStakeValue();
        });
      } else {
        this.setStakeValue(value);
      }
      this.toggleStakeBox();
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
   transition: all 0.3s cubic-bezier(0,.93,.55,1.01);
}

.slide-leave-active {
   transition: all 0.3s cubic-bezier(0,.93,.55,1.01);
   opacity: 1;
}

.slide-enter-to, .slide-leave {
   max-height: 740px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
.betslip-area-wrap {
  position: fixed;
  margin: 0 auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 848px;
  z-index: 2;
  pointer-events: none;
  &.phone {
    &::v-deep .betslip {
      box-shadow: none;
    }
  }

  .betslip-wrap-footer {
    max-width: 848px;
    margin: 0 auto;
    pointer-events: all;
    z-index: 22;

    /deep/ .bet-info-bar {
      max-width: 848px;
      margin: 0 auto;
      position: fixed;
      bottom: 56px;
      left: 0;
      right: 0;
    }

    /deep/ .notification {
      max-width: 848px;
      margin: 0 auto;
      position: fixed;
      bottom: 56px;
      left: 0;
      right: 0;
      z-index: 223;
    }

    /deep/ .betslip-footer {
      max-width: 848px;
      margin: 0 auto;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .betslip {
    width: 100%;
    position: fixed;
    bottom: 56px;
    z-index: 223;
    top: auto;
    pointer-events: all;
  }

  .stake-box {
    overflow: auto;
    box-shadow: 3px 4px 15px 3px rgba(40, 40, 40, 1);
  }

  /deep/ .betslip-ticket {
    overflow: hidden;
  }

  /deep/ .betslip-body,
  /deep/ .last-tickets-body {
    display: flex;
    flex-direction: column;
    flex: 1;

    .betslip-footer-wrap {
      margin-top: auto;
    }

    .empty,
    .betslip-bets,
    &.not-active {
      height: 100%;
      width: 100%;
      margin: auto;
    }

    .betslip-bets {
      height: 352px;
    }
  }

  /deep/ .last-tickets-body {
    max-height: 352px;
  }
}
@media (min-width: 660px) {
  .betslip-area-wrap {
    max-width: 832px;
    width: 100%;
    height: 96px;
    position: relative;
    .betslip-wrap-footer {
      position: absolute;
      width: 100%;
      .betslip-footer {
        max-width: 848px;
        width: 100%;
        position: relative;
      }
      ::v-deep .bet-info-bar {
        position: unset;
      }
      ::v-deep .notification {
        position: relative;
        bottom: 0px;
      }
    }
    .betslip {
      position: absolute;
      width: 300px;
      right: 0px;
    }
    .stake-box {
      position: absolute;
      top: auto;
      width: 300px;
      bottom: 56px;
      overflow-y: hidden;
    }
  }
  ::v-deep .last-tickets-wrapper {
    .last-tickets-body {
      max-height: 440px;
    }
  }
  ::v-deep .betslip-body.not-active {
    max-height: 409px;
  }
  ::v-deep .betslip-ticket.ticket-types {
    .empty {
      min-height: 439px;
    }
  }
}
</style>
