import { mapGetters } from 'vuex';
import taxLib from '@nsftx/seven-gravity-tax-service';
import nsoftMoney from '../../utility/nsoftMoney';

export default {
  computed: {
    ...mapGetters({
      isPayinTaxActive: 'betslip/isPayinTaxActive',
      isPayoutTaxActive: 'betslip/isPayoutTaxActive',
      taxGetter: 'betslip/taxes',
      ticket: 'betslip/tickets',
      toRound: 'betslip/toRound',
      toRoundCalc: 'betslip/toRoundCalc',
      ticketType: 'betslip/ticketType',
      payment: 'betslip/payment',
      totalPayment: 'betslip/totalPayment',
      config: 'config',
    }),
    payinTax() {
      if (this.isPayinTaxActive) {
        return taxLib.calculateTax(
          this.taxGetter.payin.policy,
          this.payment,
        );
      }
      return 0;
    },
    payoutTax() {
      if (this.isPayoutTaxActive) {
        return taxLib.calculateTax(
          this.taxGetter.payout.policy,
          this.possibleWin.value,
        );
      }
      return 0;
    },
    possibleWin() {
      let possibleWin = 0;
      let totalOdds = 1;
      this.ticket.forEach((bet) => {
        if (bet.odd) {
          if (this.ticketType.value !== 'combo') {
            possibleWin += Number(this.calcPossibleWinPerBet(bet).value.toFixed(this.toRoundCalc));
          } else {
            totalOdds *= bet.odd;
            const payment = this.calcTotalPaymentPerTicket();
            possibleWin = nsoftMoney.getMainUnit(Number(totalOdds) * payment, this.currencyDisplay);
          }
        }
      });
      return {
        value: possibleWin,
        rounded: possibleWin.toFixed(this.toRound), // todo Round value
      };
    },
    currencyDisplay() {
      return this.config && this.config.currency;
    },
  },
  methods: {
    // Stake here represents value => payment - tax
    calcStakePerBet(payment) {
      return taxLib.calculateTax(
        this.taxGetter.payin.policy,
        payment,
      );
    },
    calcPossibleWinPerBet(bet) {
      let possibleWin = 0;

      if (bet.odd) {
        const stake = this.isPayinTaxActive
          && (bet.payment - this.calcStakePerBet(bet.payment).taxAmount);
        const payment = this.isPayinTaxActive ? stake : bet.payment;
        possibleWin += Number((bet.odd * payment).toFixed(this.toRoundCalc));
      }
      return {
        value: possibleWin,
        rounded: possibleWin.toFixed(2), // todo Round value
      };
    },
    calcTotalPaymentPerTicket() {
      if (this.isPayinTaxActive) {
        return this.payment - taxLib.calculateTax(
          this.taxGetter.payin.policy,
          this.payment,
        ).taxAmount;
      }
      return this.payment;
    },
  },
};
