// import { isNil } from 'lodash';
import { each } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isPaymentModified: 'betslip/isPaymentModified',
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      minPayment: 'betslip/minPayment',
      minPaymentPerBet: 'betslip/minPaymentPerBet',
      payment: 'betslip/payment',
      paymentPerBet: 'betslip/paymentPerBet',
      totalPayment: 'betslip/totalPayment',
      tickets: 'betslip/tickets',
      numOfBets: 'betslip/numOfBets',
      ticketType: 'betslip/ticketType',
      selectedBet: 'betslip/selectedBet',
      isQuickpayEnabled: 'betslip/isQuickpayEnabled',
      isTerminalUser: 'user/isTerminalUser',
    }),
    userPayment() {
      return this.isPaymentModified ? this.payment : this.minPayment;
    },
    userPaymentPerBet() {
      return this.isPaymentModified ? this.paymentPerBet : this.minPaymentPerBet;
    },
  },
  methods: {
    ...mapActions({
      calculateAndUpdateTicketValues: 'betslip/calculateAndUpdateTicketValues',
      setStakeValue: 'betslip/setStakeValue',
      setTotalStakeValue: 'betslip/setTotalStakeValue',
      updateStake: 'betslip/updateStake',
      validateStake: 'betslip/validateStake',
      disablePayinButton: 'betslip/disablePayinButton',
      setPaymentPerBetValue: 'betslip/setPaymentPerBetValue',
    }),
    clearStake() {
      if (this.isTerminalUser) {
        this.$root.$emit('TerminalStakeReset');
      }
      this.setStakeValue(this.minPayment);
    },
    clearStakePerBet() {
      if (this.tickets.length > 0) {
        each(this.tickets, (signleBet) => {
          // eslint-disable-next-line no-param-reassign
          signleBet.payment = signleBet.minPayment;
        });
        this.setPaymentPerBetValue(this.minPaymentPerBet);
        this.setTotalStakeValue();
        this.disablePayinButton(false);
      } else {
        this.setStakeValue(this.minPaymentPerBet).then(() => {
          this.setTotalStakeValue();
        });
      }
    },
  },
  watch: {
    tickets() {
      // On tickets change
      // If payment per bet is active
      if (this.isPaymentPerBetActive) {
        this.setTotalStakeValue();
        this.validateStake(this.userPaymentPerBet);
      } else {
        this.calculateAndUpdateTicketValues().then(() => {
          this.setStakeValue(this.userPayment);
          this.validateStake(this.userPayment);
        });
      }
    },
    selectedBet() {
      if (this.isQuickpayEnabled) {
        this.setStakeValue(this.minPayment);
      }
    },
  },
};
