<template>
  <div class="ticket-types-wrapper">
    <Tabs
      :tabs="ticketTypeComponents"
      :activeTab="ticketTypeActiveComponent"
      buttonType="rounded"
      @change="changeTab"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Tabs from '../../Tabs';

export default {
  name: 'TicketTypes',
  components: {
    Tabs,
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
      ticketTypeComponents: 'betslip/ticketTypeComponents',
      ticketTypeActiveComponent: 'betslip/ticketTypeActiveComponent',
    }),
    selectedTab() {
      return {
        key: this.ticketTypeActiveComponent.id,
        selected: true,
      };
    },
  },
  methods: {
    ...mapActions({
      setTicketType: 'betslip/setTicketType',
      setTicketTypeComponents: 'betslip/setTicketTypeComponents',
      setTicketTypeActiveComponent: 'betslip/setTicketTypeActiveComponent',
    }),
    changeTab(event) {
      this.setTicketTypeActiveComponent(this.ticketTypeComponents[`${event.id}`]);
      this.setTicketType(event);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .tabs {
  .tab-button {
    display: flex;
    border-bottom: 2px solid var(--card);
    &.active {
      border-bottom: 2px solid var(--card);
    }
    button {
      font-weight: normal;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid var(--card) !important;
      &.active {
        color: var(--secondary);
        border: 1px solid var(--secondary);
        border-bottom: 1px solid var(--secondary) !important;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
</style>
