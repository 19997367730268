<template>
  <v-popover v-tooltip="{
              content: content,
              trigger: !isDesktop ? 'manual' : 'hover',
              show: show,
              delay: !isDesktop ? 1000 : 100,
              placement: position}"
             offset="16"
             disabled>
    <slot></slot>
  </v-popover>
</template>

<script>
import Vue from 'vue';
import { VTooltip, VPopover } from 'v-tooltip';
import { mapGetters } from 'vuex';

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'icon-info',
    },
    position: {
      type: String,
      default: 'bottom',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isDesktop']),
    content() {
      return `<i class="icon ${this.icon}"></i> ${this.text}`;
    },
  },
  beforeCreate() {
    Vue.directive('tooltip', VTooltip);
    Vue.component('v-popover', VPopover);
  },
};
</script>

<style lang="scss">
  .v-popover {
    display: inline-block;
  }
  .tooltip {
    display: block !important;
    z-index: 10000;
    font-family: 'Roboto', 'sans-serif';
    font-size: 12px;
    max-width: 203px;

    .tooltip-inner {
      background-color: var(--background-2);
      color: var(--text-primary-1);
      border-radius: 2px;
      line-height: 30px;
      padding: 0 8px;
    }

    .icon {
      position: relative;
      top: 1px;
      margin-right: 4px;
    }

    .icon-close-a {
      float: right;
      position: relative;
      top: 10px;
      left: 5px;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      margin: 5px;
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }
</style>
