<template>
  <div class="schedule-wrapper" :class="{terminal: isTerminal}">
    <Button class="scroll-button left"
            label=""
            @change="swipe('left')">
      <i class="icon icon-arrow-left"></i>
    </Button>
    <div class="content-wrapper" ref="content">
      <Button class="round-wrapper"
              label=""
              ref="round-content"
              v-for="(round, index) in offer"
              :disabled="isScheduleDisabled"
              :key="index"
              :class="{ brand: activeIndex === index }"
              :style="{width: `${roundWrapperWidth}px`}"
              @change="toggle(round, index)">
        <div class="round-time">{{ formatTime(round.startDate.date) }}</div>
        <div class="round-number">{{ `${roundLabel} ${round.eventIdToday}` }}</div>
      </Button>
    </div>
    <Button class="scroll-button right"
            label=""
            @change="swipe">
      <i class="icon icon-arrow-right"></i>
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from './Button';

export default {
  name: 'Schedule',
  components: {
    Button,
  },
  props: {
    offer: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    roundLabel: {
      type: String,
      default: 'Round',
    },
    isTerminal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isScheduleDisabled: 'betslip/isScheduleDisabled',
    }),
  },
  data() {
    return {
      activeIndex: 0,
      timeOptions: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      },
      roundWrapperWidth: null,
    };
  },
  methods: {
    toggle(round, index) {
      this.activeIndex = index;
      this.$emit('selectedRound', round.eventIdToday);
    },
    swipe(direction) {
      const { content } = this.$refs;
      content.scrollLeft = direction === 'left' ? content.scrollLeft -= this.roundWrapperWidth : content.scrollLeft += this.roundWrapperWidth;
    },
    formatTime(value) {
      const preFormatedDate = String(value);
      const formatedDate = preFormatedDate.replace(/\s+/g, 'T');
      const date = new Date(formatedDate);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return date.toLocaleTimeString([], this.timeOptions);
    },
    getWidth() {
      this.roundWrapperWidth = `${(this.$refs.content.clientWidth / this.isMobileWidth()) - 2}`;
    },
    isMobileWidth() {
      // Number of elements displayed on visible part of schedule component
      return window.innerWidth < 600 ? 3 : 6;
    },
  },
  mounted() {
    let roundWrapperWidth;
    if (this.isTerminal) {
      roundWrapperWidth = `${(this.$refs.content.clientWidth / 6) - 4}`;
    } else {
      roundWrapperWidth = `${(this.$refs.content.clientWidth / this.isMobileWidth()) - 2}`;
    }

    this.roundWrapperWidth = roundWrapperWidth;
    window.addEventListener('resize', this.getWidth);
  },
};
</script>

<style lang="scss" scoped>
.schedule-wrapper {
  cursor: pointer;
  padding: 8px;
  height: 56px;
  background-color: var(--card);
  border-radius: 2px;
  -webkit-tap-highlight-color: transparent;
  .scroll-button {
    max-width: 40px;
    width: 100%;
    color: var(--text-primary-2);
    margin: 0 1px;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
  }
  &.terminal {
    margin: 0px;
    height: 78px;
    padding: 4px 0px;
    .scroll-button {
      font-size: 24px;
      height: 70px;
    }
    .content-wrapper {
      height: 70px;
      .round-wrapper {
        height: 100%;
        margin: 0px 2px;

        .round-time {
          font-size: 24px;
          line-height: 24px;
        }
        .round-number {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
.content-wrapper {
  overflow-x: hidden;
  white-space: nowrap;
  float: left;
  width: calc(100% - calc(40px * 2) -
    calc(1px * 4));
  text-align: left;
  cursor: default;
  .round-wrapper {
    display: inline-block;
    width: 112px;
    margin: 0 1px;
    font-size: 12px;
    .round-time {
      font-weight: bold;
    }
    .round-number {
      opacity: 0.6;
    }
    &.brand {
      color: var(--custom-text-color);
    }
  }
  button {
    font-family: 'Roboto', 'sans-serif';
    line-height: 14px;
  }
}
@media (min-width: 306px) and (max-width: 660px) {
  .content-wrapper {
    overflow: overlay;
  }
}
@media all and (max-width: 420px) {
  .schedule-wrapper {
    .scroll-button.left,
    .scroll-button.right {
      display: none;
    }
    .content-wrapper {
      width: 100%;
    }
  }
}
@media all and (max-width: 1025px) {
  .schedule-wrapper {
    &.terminal {
      width: calc(100% - 80px);
    }
  }
}
@media all and (max-width: 1680px) {
  .schedule-wrapper {
    margin: 4px;
    &.terminal {
      margin: 0px;
      padding: 9px 0px;
      height: 72px;
      .scroll-button {
        font-size: 24px;
        height: 54px;
      }
      .content-wrapper {
        height: 54px;
        .round-wrapper {
          height: 100%;
          margin: 0px 2px;
          .round-time {
            font-size: 16px;
            line-height: 16px;
          }
          .round-number {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>
