<template>
  <div class="color-checkbox"
       @change="onChange"
       :class="[typeClass]"
       :style="activeState">
    <input type="checkbox"
           v-model="checked" />
    <label :style="normalState" />
  </div>
</template>

<script>
import { startsWith } from 'lodash';
import Checkbox from './Checkbox';
import { convert } from '../utility';

export default {
  name: 'ColorCheckbox',
  extends: Checkbox,
  emits: ['input'],
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
    active: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    computedChecked() {
      return this.active === null ? this.checked : this.active;
    },
    calculatedColor() {
      const isHex = this.isHex(this.color);
      return isHex ? convert.hexToRgba(this.color, 50) : this.color;
    },
    normalState() {
      return {
        background: this.color,
      };
    },
    activeState() {
      return this.computedChecked ? {
        background: this.calculatedColor,
        transition: 'all .3s ease',
      } : {};
    },
  },
  methods: {
    onChange() {
      try {
        this.$emit('input', {
          checked: this.checked,
          key: this.$vnode.key,
          color: this.color,
        });
      } catch {
        this.$emit('input', {
          checked: this.checked,
          color: this.color,
        });
      }
    },
    isHex(color) {
      return startsWith(color, '#');
    },
  },
  watch: {
    active(value) {
      this.checked = value;
    },
  },
};
</script>

<style lang="scss">
.color-checkbox {
  $box-size-small: 20px;
  $box-size-medium: 72px;
  $box-size-large: 32px;

  width: $box-size-small;
  height: $box-size-small;
  background-color: var(--color-checkbox-bg);
  border-radius: 100%;
  position: relative;

  &:hover {
    background-color: var(--color-checkbox-hover);
  }

  &:active {
    background-color: var(--color-checkbox-active);
  }

  input {
    opacity: 0;
    cursor: pointer;
    width: $box-size-small;
    height: $box-size-small;
    margin: 0;
  }

  label {
    position: absolute;
    pointer-events: none;
    cursor: pointer;
    display: block;
    border-radius: 100%;
    top: 5px;
    left: 5px;
    width: calc(#{$box-size-small} / 2);
    height: calc(#{$box-size-small} / 2);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  &.type-web {
    width: $box-size-small;
    height: $box-size-small;

    input {
      width: $box-size-small;
      height: $box-size-small;
    }

    label {
      width: calc(#{$box-size-small} / 2);
      height: calc(#{$box-size-small} / 2);
    }
  }
  &.type-terminal {
    width: 54px;
    height: 54px;

    input {
      width: 54px;
      height: 54px;
    }

    label {
      width: 23px;
      height: 23px;
      top: 16px;
      left: 16px;
    }
  }

  &.type-terminal-large,
  &.type-mobile {
    width: $box-size-medium;
    height: $box-size-medium;

    input {
      width: $box-size-medium;
      height: $box-size-medium;
    }

    label {
      width: 30px;
      height: 30px;
      top: 21px;
      left: 21px;
    }
  }
}
</style>
