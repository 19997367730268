import Vue from 'vue';
import Vuex, { createStore } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { currentApp } from '../utility';
import storeConfig from './storeConfig';

const initStore = () => {
  const isDev = process.env.NODE_ENV === 'staging';
  if (currentApp.isVue3()) {
    return createStore(storeConfig);
  }
  // There is a bug in devtools where it loads sdk store
  // and ignores store from games. We found that the issue
  // is related to how we initialize Vuex Store in sdk. For the
  // moment the workaround is to initialize store only in
  // development mode.
  if (isDev) {
    Vue.use(Vuex);
    return new Vuex.Store(storeConfig);
  }
  return null;
};

const store = initStore();

export default store;
