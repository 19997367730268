<template>
  <div class="radio-button">
    <input type="radio"
           :id="id"
           :value="id"
           :checked="selectedPick"
           v-model="selectedPick"
           @change="onChange(id)"
           name="radio">
    <label @click="onChange(id)"
           :for="id">
      <div class="check"></div>
      {{ id }} {{ fullLabel }}
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Radio',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    subLabel: {
      type: String,
      default: '',
      required: false,
    },
    selected: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedPick: this.selected,
    };
  },
  computed: {
    ...mapGetters([
      'translations',
    ]),
    sublabel() {
      return this.subLabel ? ` ${this.subLabel}` : `${this.subLabel}`;
    },
    fullLabel() {
      return this.label ? `(${this.transLabel(this.label)}${this.sublabel})` : '';
    },
  },
  methods: {
    onChange(id) {
      this.selectedPick = id;
      this.$emit('change', this.selectedPick);
    },
    transLabel(label) {
      const translatedLabel = `${this.translations[label]}`;
      if (!translatedLabel || translatedLabel === 'undefined') {
        return label;
      }
      return `${this.translations[label]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  color: var(--text-primary-1);
  display: block;
  position: relative;
  float: left;
  width: 100%;

  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }

  label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 14px;
    bottom: 20px;
    left: 0;
    padding-left: 38px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }

  .check {
    display: block;
    position: relative;
    border: 2px solid #aaaaaa;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 10px;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
  }

  .check::before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }

  input[type="radio"]:checked ~ label .check {
    border: 2px solid var(--secondary, var(--brand));
  }

  input[type="radio"]:checked ~ label .check::before {
    background: var(--secondary, var(--brand));
  }

  input[type="radio"]:checked ~ label {
    color: var(--text-primary-1);
  }
}
</style>
