<template>
  <div>
    <div ref="notification">
      <Transition name="notification">
        <div
          class="betslipNotification"
          v-if="isNotificationActive"
        >
          <Notification v-if="isA" />
        </div>
      </Transition>
    </div>
    <div class="betslipFooter" ref="footer">
      <div class="betslipFooterUpper">
        <div class="betslipUpperWrapperA" v-if="isA">
          <div class="betslipFooterSelect"
            v-if="isFuturePerTicketAllowed"
            :class="{ paymentPerBetDisabled: !isPaymentPerBetAllowed }">
            <Select
              :label="translations.general_future_bet"
              :options="selectOptions"
              :value="futureRounds"
              @change="handleFutureRoundsChange"
              :orientation="selectOrientation"
            />
          </div>
          <div
            v-if="isPaymentPerBetAllowed"
            class="betslipFooterInput"
            :class="{ futurePerTicketDisabled: !isFuturePerTicketAllowed }">
            <BetslipInput
              @change="handlePaymentPerBetChange"
              @fieldFocus="setActivePaymentPerBetInput"
              :label="translations.general_stake_per_bet"
              :parentValue="paymentPerBet"
              @delete="handlePaymentPerBetDelete"
              :isValid="isPaymentPerBetValid"
            />
          </div>
          <BetslipInput
            class="totalPayment"
            @change="handleTotalPaymentChange"
            @fieldFocus="setActiveTotalStakeInput"
            :label="translations.general_total_stake"
            :labelPosition="isLeftSideLabel ? 'left' : 'right'"
            :parentValue="totalPayment"
            @delete="handleTotalPaymentDelete"
            :class="{
              futurePerTicketDisabled: !isFuturePerTicketAllowed,
              paymentPerBetDisabled: !isPaymentPerBetAllowed
            }"
          />
          <div v-if="!isA" class="betslipDeleteButton">
            <BetslipDeleteButton />
          </div>
        </div>
        <div class="betslipUpperWrapperB" v-if="!isA" :class="{ paymentPerBetDisabled:
            !isPaymentPerBetAllowed
          }">
          <div class="betslipFooterSelect" v-if="isFuturePerTicketAllowed">
            <Select
              :label="translations.general_future_bet"
              :options="selectOptions"
              :value="futureRounds"
              @change="handleFutureRoundsChange"
            />
          </div>
          <div v-if="isPaymentPerBetAllowed" class="betslipFooterInput">
            <BetslipInput
              @change="handlePaymentPerBetChange"
              @fieldFocus="setActivePaymentPerBetInput"
              :label="translations.general_stake_per_bet"
              :parentValue="paymentPerBet"
              @delete="handlePaymentPerBetDelete"
              :isValid="isPaymentPerBetValid"
            />
          </div>
          <div class="betslipDeleteButton"
            :class="{ paymentPerBetDisabled:
            !isPaymentPerBetAllowed, futurePerTicketDisabled: !isFuturePerTicketAllowed,
            onlyDelete: !isPaymentPerBetAllowed && !isFuturePerTicketAllowed
          }">
            <BetslipDeleteButton />
          </div>
        <BetslipPossibleWin @updateFooterHeight="updateHeight"  :isA="false" class="possibleWin" />
        </div>
      </div>
      <div v-if="isA" class="betslipFooterLower">
        <BetslipPossibleWin @updateFooterHeight="updateHeight" :isA="true" />
        <div class="betslipFooterActions">
          <div class="betslipDeleteButton">
            <BetslipDeleteButton />
          </div>
          <div class="betslipActionButton">
            <BetslipButton :disabled="isPayinDisabled" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import Notification from '../../Notifications/Notification';
import Select from '../../Select/Select';
import BetslipButton from './BetslipButton';
import BetslipDeleteButton from './BetslipDeleteButton';
import BetslipPossibleWin from './BetslipPossibleWin';

export default {
  name: 'BetslipFooterSingle',
  props: {
    isA: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      stakeBoxTimeoutId: 0,
      footerHeightWithoutUnit: 0,
    };
  },
  components: {
    BetslipInput,
    BetslipButton,
    BetslipDeleteButton,
    BetslipPossibleWin,
    Notification,
    Select,
  },
  computed: {
    ...mapGetters('gamesBetslip', [
      'config',
      'isPayinButtonDisabled',
      'isPaymentPerBetValid',
      'isPayinInProgress',
      'isTotalPaymentValid',
      'isPaymentValid',
      'isCantSplitActive',
      'isUserBalanceInvalid',
      'futureRounds',
      'futureRoundsDefault',
      'minPaymentAllowed',
      'numberOfBets',
      'paymentPerBet',
      'totalPayment',
      'isFuturePerTicketAllowed',
      'isPaymentPerBetAllowed',
    ]),
    ...mapGetters(['translations', 'isTablet', 'isMobile']),
    ...mapGetters('notifications', ['isNotificationActive']),
    selectOptions() {
      return this.config.futureRounds;
    },
    selectOrientation() {
      return this.config.futureRoundsOrientation ?? 'bottom';
    },
    isPayinDisabled() {
      return this.isPayinButtonDisabled
      || this.numberOfBets <= 0
      || this.isPayinInProgress
      || !this.isTotalPaymentValid
      || !this.isPaymentValid
      || this.isCantSplitActive
      || this.isUserBalanceInvalid;
    },
    isLeftSideLabel() {
      return !this.isFuturePerTicketAllowed && this.isPaymentPerBetAllowed;
    },
    footerHeight() {
      return `${this.footerHeightWithoutUnit}px`;
    },
    isMobileTabletDevice() {
      return this.isMobile || this.isTablet;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'paymentPerBetDelete',
      'setTotalPayment',
      'setPaymentPerBet',
      'setPaymentPerBetValue',
      'setFutureRounds',
      'setIsPaymentPerBetValid',
      'setActiveBetslipInput',
      'setIsStakeBoxVisible',
      'totalPaymentDelete',
    ]),
    handleTotalPaymentChange(totalPayment) {
      if (Number(totalPayment) === Number(this.totalPayment)) return;

      this.setTotalPayment(totalPayment);
    },
    async handlePaymentPerBetChange(paymentPerBet) {
      const isValid = await this.setPaymentPerBet(paymentPerBet);

      this.setIsPaymentPerBetValid(isValid);
    },
    handleFutureRoundsChange(futureRounds) {
      this.setFutureRounds(futureRounds);
    },
    handleTotalPaymentDelete() {
      this.totalPaymentDelete();
    },
    async handlePaymentPerBetDelete() {
      this.isPaymentPerBetValid = true;
      this.paymentPerBetDelete();
    },
    openStakeBox() {
      if (this.stakeBoxTimeoutId) clearTimeout(this.stakeBoxTimeoutId);

      this.stakeBoxTimeoutId = setTimeout(() => {
        this.setIsStakeBoxVisible(true);
      }, 200);
    },
    setActiveTotalStakeInput() {
      this.openStakeBox();
      this.setActiveBetslipInput('_totalPayment');
    },
    setActivePaymentPerBetInput() {
      this.openStakeBox();
      this.setActiveBetslipInput('_paymentPerBet');
    },
    updateHeight() {
      this.$nextTick(() => {
        this.footerHeightWithoutUnit = this.$refs.footer.clientHeight;
        this.$refs.notification.style.setProperty('--footerHeight', this.footerHeight);
      });
    },
  },
  mounted() {
    this.footerHeightWithoutUnit = this.$refs.footer?.clientHeight;
    document.body.style.setProperty('--footer-single-height', this.footerHeightWithoutUnit);

    this.$nextTick(() => {
      this.$refs.notification.style.setProperty('--footerHeight', this.footerHeight);
    });
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    clearTimeout(this.stakeBoxTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.stakeBoxTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
.betslipNotification {
  position: absolute;
  width: 100%;
  bottom: var(--footerHeight);
  z-index: 10;
}

.notification-enter-active {
  animation-name: notificationSlideUp;
  animation-duration: 0.33s;
  transition-timing-function: ease-out;
}

.notification-leave-active {
  animation-name: notificationSlideDown;
  animation-duration: 0.33s;
  transition-timing-function: ease-out;
}

@keyframes notificationSlideUp {
  from {
    bottom: calc(var(--footerHeight) - 40px);
  }

  to {
    bottom: var(--footerHeight);
  }
}

@keyframes notificationSlideDown {
   from {
    bottom: var(--footerHeight);
  }

  to {
    bottom: calc(var(--footerHeight) - 40px);
  }
}

.betslipFooter {
  position: relative;
  max-height: 218px;
  background-color: var(--background-2);
  padding: 8px;
  z-index: 98;

  .betslipFooterUpper {
    .betslipStakePerBet {
      margin-bottom: 8px;
    }

    .betslipUpperWrapperA {
      .betslipFooterInput {
        grid-row-start: 1;
        grid-column-start: 2;
        width: 100px;

        ::v-deep .betslip-input-wrapper {
          input {
            width: 100px;
          }
        }

        &.futurePerTicketDisabled {
          ::v-deep .betslip-input-wrapper {
            input {
              width: 100px;
            }
          }
        }
      }

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;

      .betslipFooterSelect {

        &.paymentPerBetDisabled {
          grid-row-start: 1;
          grid-column-start: 1;

          ::v-deep .select {
            width: 138px;
          }
        }
      }

      .paymentPerBet {
        grid-column-start: 2;
      }

      .totalPayment {
        grid-column: 1/-1;

        &.futurePerTicketDisabled {
          grid-column-start: 1;
          grid-row-start: 1;
          grid-column: auto;

          ::v-deep {
            input {
              width: 176px;
            }
          }
        }

        &.paymentPerBetDisabled {
          grid-column: auto;

          ::v-deep {
            input {
              width: 138px;
            }
          }
        }

        &.futurePerTicketDisabled.paymentPerBetDisabled {
          grid-column: 1/-1;

          ::v-deep {
            input {
              width: 100%;
            }
          }
        }
      }

      ::v-deep .select {
        width: 176px;
      }
    }
  }

  .betslipUpperWrapperB {
    display: grid;
    grid-template-columns: 1fr 103px;
    gap: 8px;

    .betslipFooterInput {
      width: 103px;
    }

    &.paymentPerBetDisabled {
      grid-template-columns: 50px 1fr;
    }

    .betslipFooterSelect {
      ::v-deep .select {
        width: 100%;

        .modal-back {
          padding: 36px 8px;

          .modal {
            overflow: overlay;
            padding: 0px;
            height: auto;
            max-height: 100%;

            .modal-header {
              padding: 24px 24px 0;
              margin-bottom: 38px;
            }

            .modal-body {
              height: auto;
              padding-left: 26px;
            }

            .modal-footer {
              position: static;
              margin-top: auto;
              padding: 42px 29px 27px 0;
            }
          }
        }
      }
    }

    .betslipDeleteButton {
      grid-column: 1/-1;
      grid-row-start: 3;

      &.paymentPerBetDisabled {
        grid-column: auto;
        grid-row-start: 1;
      }

      &.futurePerTicketDisabled {
        grid-column: auto;
        grid-row-start: 1;
      }

      &.onlyDelete {
        grid-column: 1/-1;
      }
    }

    .possibleWin {
      grid-column: 1/-1;
    }
  }

  .betslipFooterLower {
    width: 100%;
    margin-top: 8px;

    .betslipFooterActions {
      display: flex;
      width: inherit;
      margin-top: 8px;
      gap: 8px;

      .betslipActionButton {
        width: inherit;
        flex: 3;
        width: 210px;
      }
    }
  }
}

.betslipDeleteButton {
  width: 100%;
  flex: 1;
}
</style>
