<template>
  <div
    class="possibleWinWrapper"
  >
    <div class="possibleWin" v-if="isPossibleWinActive">
      <div>
        {{ translations.general_possible_winning }}
      </div>
      <div>
        {{ decimalFormatNumber(possibleWin.possibleWinnings) }}
      </div>
    </div>
    <div class="payIn" v-if="isPayinTaxActive">
      <div>
        {{ translations.general_payin_tax }}
      </div>
      <div>
        {{ decimalFormatNumber(possibleWin.possiblePayinTax) }}
      </div>
    </div>
    <div class="payOut" v-if="isPayoutTaxActive && isPossibleWinActive">
      <div>
        {{ translations.general_payout_tax }}
      </div>
      <div>
        {{ decimalFormatNumber(possibleWin.possiblePayoutTax) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PossibleWin',
  data() {
    return {
      isExpanded: true,
    };
  },
  props: {
    isA: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['config', 'possibleWin', 'taxes', 'decimalFormatNumber']),
    ...mapGetters(['translations']),
    isPossibleWinActive() {
      return this.config.isPossibleWinActive;
    },
    isPayinTaxActive() {
      return !this.taxes.payin.hideTax;
    },
    isPayoutTaxActive() {
      return !this.taxes.payout.hideTax;
    },
  },
  methods: {
    toggleClick() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.possibleWinWrapper {
  .possibleWin {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: var(--text-primary-1);
    font-weight: 400;
  }

  .payIn, .payOut {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: var(--text-primary-1);
    opacity: 0.6;
    font-weight: 400;
  }

  .arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }

  @media only screen and (min-width: 1910px) {
    .possibleWin {
      font-size: 20px;
    }

    .payIn, .payOut {
      font-size: 20px;
    }
  }
}
</style>
