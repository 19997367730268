<template>
  <div class="total-odds-wrapper"
       v-if="ticketTypeIsCombo">
    <span>{{ translations.general_total_odds }}</span>
    <span>{{ totalOdds }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import helpers from '@/mixins/helpers';
import totalOdds from '@/mixins/betslip/totalOdds';

export default {
  name: 'TotalOdds',
  mixins: [helpers, totalOdds],
  computed: {
    ...mapGetters({
      translations: 'translations',
    }),
  },
};
</script>

<style scoped lang="scss">
.total-odds-wrapper {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
}
</style>
