<template>
  <div class="loader-wrapper"
       v-if="loader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'CircleLoader',
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: var(--overlay-lock);
  top: 0;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }
  .loader {
    font-size: 10px;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    border-top: 2px solid var(--loader-color);
    border-right: 2px solid var(--lodader-color);
    border-bottom: 2px solid var(--loader-color);
    border-left: 2px solid var(--loader-color-active);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
