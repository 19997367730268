<template>
  <div
    class="betslipDWrapper"
    id="betslipDWrapper"
    :style="[
      {'--ticket-type-height': isTicketTypesComponentVisible ? '80px' : '0px'}
    ]">
    <BetslipHeader :showMinimizeButton="showMinimizeButton" />
    <BetslipTicket v-if="isTicketVisible" />
    <BetslipEmpty v-else :bgImage="bgImage" />
    <BetslipFooter />
    <FadeTransition>
      <BetslipStakeBox v-if="isStakeBoxVisible" />
    </FadeTransition>
    <BetslipLock v-if="isBetslipLockActive" />
    <button ref="simulateClickElement" style="display: none;"></button>
    <CircleLoader :loader="isPayinInProgress" class="circle-loader"></CircleLoader>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../utility';

import BetslipHeader from './BetslipHeader';
import BetslipTicket from './BetslipTicket';
import BetslipFooter from './BetslipFooter';
import BetslipEmpty from './BetslipEmpty';
import BetslipStakeBox from './BetslipStakeBox';
import CircleLoader from '../../CircleLoader';
import BetslipLock from './BetslipLock';
import FadeTransition from '../../FadeTransition';

export default {
  name: 'BetslipD',
  components: {
    BetslipHeader,
    BetslipTicket,
    BetslipFooter,
    BetslipEmpty,
    BetslipStakeBox,
    BetslipLock,
    CircleLoader,
    FadeTransition,
  },
  props: {
    bgImage: {
      type: String,
      default: null,
    },
    showMinimizeButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', [
      'activeTicketTypes',
      'betslipBlockers',
      'isStakeBoxVisible',
      'isPayinInProgress',
      'numberOfBets',
      'rules',
      'roundNumber',
      'totalPayment',
    ]),
    ...mapGetters('notifications', ['isNotificationActive']),
    hasMultipleTicketTypes() {
      return this.activeTicketTypes.length > 1;
    },
    isTicketTypesComponentVisible() {
      return this.hasMultipleTicketTypes;
    },
    isTicketVisible() {
      return this.numberOfBets > 0;
    },
    isBetslipLockActive() {
      return this.betslipBlockers.length;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['setTotalPaymentValue']),
    simulateClick() {
      const clickElement = this.$refs.simulateClickElement;
      setTimeout(() => {
        if (clickElement) clickElement.click();
      }, 200);
    },
  },
  mounted() {
    if (Number(this.totalPayment) <= 1) {
      this.setTotalPaymentValue(this.roundNumber(this.rules.minBetAmount.value));
    }
    eventBus.$on('BetRemoved', this.simulateClick);
  },
};
</script>

<style lang="scss" scoped>
.betslipDWrapper {
  height: calc(100vh - 8px);
  max-width: 400px;
  min-width: 360px;
  background-color: var(--background-1);
  position: relative;
}
</style>
