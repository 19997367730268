<template>
  <div class="accordion-wrapper">
    <div class="accordion-header"
         v-if="accordionHeader"
         @click="setExpanded"
         :class="typeClass"
         :style="headerPositionStyles">
      <div class="accordion-title">
        {{title}}
      </div>
      <i class="icon">
        {{ expanded ? '&#xe677;' :  '&#xe676;' }}
      </i>
    </div>
    <Transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave">
      <div v-if="expanded"
           class="accordion-content">
          <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import { typeable } from '../mixins/index';

export default {
  name: 'Accordion',
  data() {
    return {
      expanded: false,
    };
  },
  mixins: [
    typeable,
  ],
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
    title: {
      type: String,
      default: '',
    },
    accordionHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    headerPositionStyles() {
      const flexDirection = this.position === 'left' || this.position === 'center'
        ? 'row'
        : 'row-reverse';
      const justifyContent = this.position === 'center' ? 'center' : 'space-between';


      return {
        flexDirection,
        justifyContent,
      };
    },

  },
  methods: {
    setExpanded() {
      this.expanded = !this.expanded;
      this.$emit('change', this.expanded);
    },
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          // eslint-disable-next-line no-param-reassign
          element.style.height = '0px';
        }
        // eslint-disable-next-line no-param-reassign
        element.style.display = null;
      });
    },
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          // eslint-disable-next-line no-param-reassign
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },
    afterEnter(element) {
      // eslint-disable-next-line no-param-reassign
      element.style.height = null;
    },
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          // eslint-disable-next-line no-param-reassign
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          // eslint-disable-next-line no-param-reassign
          element.style.height = '0px';
        });
      });
    },
    afterLeave(element) {
      // eslint-disable-next-line no-param-reassign
      element.style.height = null;
    },
  },
  created() {
    this.expanded = this.isExpanded;
  },
  watch: {
    isExpanded(value) {
      this.expanded = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
  font-family: "Roboto", sans-serif, "nfont";
  .accordion-header {
    width: 100%;
    height: 40px;
    background-color: var(--button);
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    color: var(--text-primary-1);
    font-size: 14px;
    padding: 0 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      background-color: var(--card-hover);
    }
    &:active {
      background-color: var(--button-active);
    }

    &.disabled {
      background-color: var(--button-disabled);
      color: var(--text-primary-3);
    }
    .accordion-title,
    .icon {
      user-select: none;
    }

    &.type-terminal {
      height: 54px;
      font-size: 18px;
    }
  }
  .accordion-content {
    width: 100%;
    overflow: hidden;
  }

  i {
    font-style: normal;
  }
}

.enter-active,
.leave-active {
  overflow: hidden;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -o-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
}

@media screen and (min-width: 1920px) {
  .accordion-wrapper {
    .accordion-header {
      &.type-terminal {
        height: 72px;
        font-size: 20px;
      }
    }
  }
}

@media screen and (min-height: 1920px) {
  .accordion-wrapper {
    .accordion-header {
      &.type-terminal {
        height: 72px;
        font-size: 20px;
      }
    }
  }
}
</style>
