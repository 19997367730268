<template>
  <div>
    <div v-if="!isNotificationActive"
         class="bet-info-bar"
         :class="typeClass">
      <!-- Show message about selected bet (not added to betslip) -->
      <div class="bet-message-container">
        <div class="bet-message">
          <span class="title"> {{selectedBet.market}} </span>
          <span class="description"> {{convertOutcomeValue(selectedBet)}} </span>
        </div>
        <i class="icon icon-close-a"
           :class="{disabled: !isDeleteButtonActive, active: isDeleteButtonActive}"
           @click="[clearBet(), removeBets()]"
           :disabled="!isDeleteButtonActive"
           v-if="!isDesktopVersion"></i>
        <div v-if="isDesktopVersion"
             class="bet-info-actions-desktop">
          <Button label=""
                  :disabled="!isDeleteButtonActive"
                  class="delete"
                  @change="clearBet()">
            <i class="icon icon-trash"
               :class="{disabled: !isDeleteButtonActive, active: isDeleteButtonActive}"
               :disabled="!isDeleteButtonActive" />
          </Button>
          <Button @change="addToBetslip()"
                  :label="translations.general_add_bet"
                  :disabled="isAddToBetslipDisabled"
                  variation="brand"
                  class="add" />
        </div>
      </div>

      <!-- Show single bet info if only one bet is selected -->
      <div v-if="isTicketActive && !isSelectedBetActive && showSingleBetSelected"
           class="single-bet-info"
           :class="{betAppear: numberOfBetsChanged
            && ticket.length > 1, firstBetAppear: ticket.length === 1}">
        <div v-if="ticket.length === 1">
          <div class="single-bet-info-title">{{ ticket[0].market }}</div>
          <div class="single-bet-info-subtitle">
            <span v-if="marketTranslationKey">{{ marketTranslationKey }}</span>
            {{ convertOutcomeValue(ticket[0]) }}
          </div>
        </div>
        <div v-else>
          {{ numberOfBets }}
        </div>
      </div>
      <!-- Show message about number of bets on betslip -->
      <div v-if="isTicketActive && !isSelectedBetActive && !showSingleBetSelected"
           class="number-of-bets" :class="{betAppear: numberOfBetsChanged
            && ticket.length > 1, firstBetAppear: ticket.length === 1}">
        {{ numberOfBets }}
      </div>
      <!-- Show default message -->
      <div v-if="!isTicketActive && !isSelectedBetActive">
        <i class="icon icon-info"></i>
        <span class="message">
          {{ infoMessage }}
        </span>
      </div>
    </div>
    <div v-else>
      <notification
        v-if="showInsufficientFundsNotification"
        :notification="insufficientFundsNotification"
        :use-store="false"
      />
      <notification v-else-if="notifications"
                    :message="notifications" />
    </div>
  </div>
</template>

<script>
import {
  isArray,
  isEmpty,
  isObject,
} from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { typeable } from '@/mixins';
import Button from '@/components/Button';
import Notification from '@/components/Notifications/Notification';

export default {
  name: 'BetslipInfoBar',
  mixins: [typeable],
  components: {
    Button,
    Notification,
  },
  props: {
    isDesktopVersion: {
      type: Boolean,
      default: false,
    },
    showSingleBetSelected: {
      type: Boolean,
      default: true,
    },
    marketTranslationKey: {
      type: String,
      default: null,
    },
    removeBetsFromBetslip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberOfBetsChanged: false,
      insufficientFundsNotification: {
        value: this.$store.getters.translations.player_balance_insufficient,
        status: 'negative',
      },
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/notification',
      showInsufficientFundsNotification: 'notifications/showInsufficientFundsNotification',
      selectedBet: 'betslip/selectedBet',
      ticket: 'betslip/tickets',
      translations: 'translations',
      isSelectedBetValid: 'betslip/isSelectedBetValid',
    }),
    isAddToBetslipDisabled() {
      return !this.isSelectedBetValid;
    },
    isNotificationActive() {
      return (!this.notifications
        || !isEmpty(this.notifications.value)
        || this.showInsufficientFundsNotification)
        && !this.isDesktopVersion;
    },
    isSelectedBetActive() {
      return this.selectedBet && this.selectedBet.market;
    },
    isTicketActive() {
      return this.ticket.length > 0;
    },
    isDeleteButtonActive() {
      const isBetSelected = isObject(this.selectedBet) && Object.keys(this.selectedBet).length;
      return this.removeBetsFromBetslip ? isBetSelected || this.ticket.length > 0 : isBetSelected;
    },
    infoMessage() {
      return this.translations.general_ticket_empty_message;
    },
    numberOfBets() {
      const number = this.ticket.length;
      const translation =
        number > 1
          ? this.translations.general_multiple_bets_selected
          : this.translation.general_single_bet_selected;
      return `${number} ${translation}`;
    },
  },
  methods: {
    ...mapActions('betslip', ['addPreBet', 'addBet', 'clearBetslip']),
    addToBetslip() {
      this.addBet(this.selectedBet);
    },
    clearBet() {
      this.addPreBet({
        bet: '',
        valid: false,
      });
    },
    removeBets() {
      this.clearBetslip();
      if (this.removeBetsFromBetslip) {
        this.clearBetslip();
      }
    },
    convertOutcomeValue(bet) {
      if (!bet.outcome) return '';

      let currentBet;
      // Added preceding space to odd
      // To avoid including it in the returned string
      // If odd is undefined
      const displayOdd = !bet.odd || bet.betTypeId === 12 ? '' : `(${bet.odd.toFixed(2)})`;

      if (bet.outcome.includes(',')) {
        currentBet = bet.outcome.split(',');
      } else {
        currentBet = isArray(bet.outcome) ? bet.outcome : bet.outcome.split(' ');
      }

      return `${currentBet.join(' ')}${displayOdd}`;
    },
  },
  watch: {
    numberOfBets() {
      this.numberOfBetsChanged = true;
      setTimeout(() => {
        this.numberOfBetsChanged = false;
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.bet-info-bar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  color: var(--text-primary-1, #efefef);
  background-color: var(--background-1, #1a1c1d);
  font-size: var(--font-size-small, 12px);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .bet-info-actions-desktop {
    .add {
      width: 184px;
    }

    .delete {
      width: 60px;
      color: var(--text-primary-1);
      margin-right: 8px;

      .icon {
        padding-right: 0;
        opacity: 1;
      }
    }
  }

  .icon {
    padding-right: 10px;
  }

  .bet-message-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    .bet-message {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    .title {
      font-size: var(--font-size-small, 12px);
      line-height: 14px;
      opacity: 0.6;
      margin-bottom: 1px;
    }

    .description {
      font-size: var(--font-size-medium, 14px);
      line-height: 18px;
    }
  }

  .message,
  .icon {
    opacity: 0.3;
  }

  .icon {
    cursor: pointer;
    transition: all 0.3s ease;
    &.active {
      color: var(--text-primary-1);
      opacity: .6;
      &:hover {
        opacity: 1;
      }
    }
    &.disabled {
      opacity: 0;
      cursor: default;
      user-select: none;
    }
    &:disabled {
      opacity: 0.1;
      cursor: not-allowed;
    }
  }

  .single-bet-info {
    text-align: left;
    .single-bet-info-title {
      color: var(--text-primary-1);
      opacity: 0.6;
    }
    .single-bet-info-subtitle {
      color: var(--text-primary-1);
      font-size: 14px;
    }
     &.betAppear {
      animation-name: betShow;
      animation-duration: 0.4s;
      transition: all 0.4s ease-in;
    }
    &.firstBetAppear {
      animation-name: firstBetShow;
      animation-duration: 0.4s;
      transition: all 0.4s ease-in;
    }
  }
  .number-of-bets {
     &.betAppear {
      animation-name: betShow;
      animation-duration: 0.4s;
      transition: all 0.4s ease-in;
    }
    &.firstBetAppear {
      animation-name: firstBetShow;
      animation-duration: 0.4s;
      transition: all 0.4s ease-in;
    }
  }

  &.type-terminal {
    height: 72px;
    .add,
    .delete {
      height: 72px;
      font-size: 24px;
    }
    .add {
      width: 220px;
    }
    .delete {
      width: 72px;
    }
    .message,
    .icon {
      font-size: 24px;
    }
    .single-bet-info {
      .single-bet-info-title {
        font-size: 20px;
      }
      .single-bet-info-subtitle {
        font-size: 24px;
      }
    }
    .bet-message-container {
      .bet-message {
        .title {
          font-size: 20px;
        }
        .description {
          font-size: 24px;
        }
      }
    }
  }
}
@keyframes betShow {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes firstBetShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
