<template>
  <div class="betslipHistory">
    <LastTickets :isBallDrawn="isBallDrawn"/>
  </div>
</template>

<script>
import LastTickets from '../../BetslipArea/LastTickets/LastTicketsWrapper';

export default {
  name: 'BetslipHistory',
  props: {
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
  },
  components: {
    LastTickets,
  },
};
</script>

<style lang="scss" scoped>
.betslipHistory {
  position: relative;
  z-index: 99;
}
</style>
