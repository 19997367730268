<template>
  <div class="tickets-history">
    <TicketsHistoryFilter @dateRange="setDateRange"
                          :dateFrom="dateFrom"
                          :dateTo="dateTo"
                          :detailsMode="detailsMode"
                          :locale="locale" />
    <div class="tickets-list-wrapper">
      <TicketsHistoryList :ticketsHistory="ticketsHistory"
                          :detailsMode="detailsMode"
                          :productNameLabel="productNameLabel" />
    </div>
    <div class="notification-wrapper">
      <Notification :notification="notification"
                    :useStore="false" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';
import Notification from '../Notifications/Notification';
import TicketsHistoryList from './TicketsHistoryList';
import TicketsHistoryFilter from './TicketsHistoryFilter';
import { eventBus } from '../../utility';

export default {
  name: 'TicketsHistoryWrapper',
  components: {
    TicketsHistoryList,
    TicketsHistoryFilter,
    Notification,
  },
  props: {
    detailsMode: {
      type: String,
      mode: 'standalone',
    },
    locale: {
      type: String,
      default: 'en',
    },
    productNameLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      notification: null,
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
      dateFormat: 'yyyy-MM-dd HH:mm:ss',
    };
  },
  computed: {
    ...mapGetters({
      ticketsHistory: 'betslip/ticketsHistory',
      translations: 'translations',
      isLoggedIn: 'user/isLoggedIn',
    }),
    // dateRange allows us to watch changes for dateFrom and dateTo at the same time
    dateRange() {
      return `${this.dateFrom} ${this.dateTo}`;
    },
  },
  methods: {
    ...mapActions('betslip', [
      'setTicketsHistory',
      'getTicketsHistory',
    ]),
    setDateRange({ start, end }) {
      this.dateFrom = start;
      this.dateTo = end;
    },
    formatDate(date) {
      return format(date, this.dateFormat);
    },
    loadTicketsHistory() {
      if (this.isLoggedIn) {
        // v-calendar v3 has internal bug where it sometimes sorts YYYY-MM-DD and not HH-MM-SS
        this.getTicketsHistory({
          dateFrom: this.formatDate(startOfDay(this.dateFrom)),
          dateTo: this.formatDate(endOfDay(this.dateTo)),
        }).then((response) => {
          if (response.code) {
            this.notification = {
              value: response.message,
              status: 'negative',
            };
          }
          if (response.length <= 0) {
            this.notification = {
              value: this.translations.general_warning_no_data,
              status: 'neutral',
            };
          }
        });
      } else {
        this.notification = {
          value: this.translations.general_warning_no_data,
          status: 'neutral',
        };
      }
    },
  },
  watch: {
    dateRange() {
      this.notification = {
        value: '',
        status: '',
      };
      this.loadTicketsHistory();
    },
  },
  mounted() {
    // Best to be called upon cancel success (i.e. from the game);
    try {
      this.$root.$on('TicketCancelled', this.loadTicketsHistory);
    } catch (error) {
      eventBus.$on('TicketCancelled', this.loadTicketsHistory);
    }
  },
  beforeMount() {
    this.setTicketsHistory([]);
    this.loadTicketsHistory();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vc-pane-container {
  font-family: 'Roboto' !important;
}
.tickets-history {
  position: relative;
  color: var(--text-primary-1);
  font-size: var(--font-size-medium, 14px);
  height: 100%;
  min-height: 290px;
  z-index: 1;

  .tickets-list-wrapper {
    clear: both;

    .scrollable > * {
      max-height: calc(100vh - 500px);
    }
  }
}
.notification-wrapper {
  width: 100%;
  margin-top: 7px;
  clear: both;
  position: sticky;
  bottom: 0;
}
@media all and (max-width: 420px) {
  .tickets-history {
    .tickets-list-wrapper {
      .scrollable > * {
        max-height: calc(100vh - 200px);
      }
    }
  }
}
</style>
