<template>
    <div
      class="arrows-wrapper"
      :class="{ three: toTopButton }"
      :style="{
        color: $store.getters.primary_text,
        backgroundColor: $store.getters.palette_card,
      }"
    >
      <button
        v-if="toTopButton"
        class="to-top"
        :style="{ backgroundColor: $store.getters.palette_button }"
        @click.stop="scrollTo('scrollToTop')"
      >
        <em class="icon n-i-arrow-up-double"></em>
      </button>
      <button :style="{ backgroundColor: $store.getters.palette_button }" @click.stop="scrollTo('scrollUp')">
        <em class="icon n-i-arrow-up-a"></em>
      </button>
      <button :style="{ backgroundColor: $store.getters.palette_button }" @click.stop="scrollTo('scrollDown')">
        <em class="icon n-i-arrow-down-a"></em>
      </button>
    </div>
  </template>

<script>
import EventBus from '../utility/eventBus';

export default {
  name: 'Arrows',
  data() {
    return {
      y: 0,
    };
  },
  props: {
    toTopButton: {
      type: Boolean,
      default: false,
    },
    scrollRef: {
      type: String,
      required: true,
    },
    scrollDistance: {
      type: Number,
      default: 200,
    },
    activeId: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollTo(side) {
      let el;
      let elHeight;
      let viewChildHeight;

      if (!this.activeId) {
        el = this.$parent.$refs[this.scrollRef];
        elHeight = el.clientHeight;
        viewChildHeight = el.getElementsByClassName('scroll');
      } else {
        el = document.getElementById(this.scrollRef);
        elHeight = el.clientHeight;
        viewChildHeight = el.getElementsByClassName('scroll');
      }
      // eslint-disable-next-line default-case
      switch (side) {
        case 'scrollDown': {
          const sum = viewChildHeight[0].clientHeight - elHeight;
          this.y = sum > this.y ? this.y + this.scrollDistance : sum;
          break;
        }
        case 'scrollUp':
          this.y = this.y > this.scrollDistance ? this.y - this.scrollDistance : 0;
          break;
        case 'scrollToTop':
          this.y = 0;
          break;
      }
      el.scrollTop = this.y;

      if (this.activeId) {
        EventBus.$emit('ScrollTo', this.y);
      }
    },
    handleScroll() {
      this.y = this.$parent.$refs[this.scrollRef].scrollTop;
    },
  },
  mounted() {
    if (!this.activeId) {
      this.$parent.$refs[this.scrollRef].addEventListener('scroll', this.handleScroll);
    } else {
      document.getElementById(this.scrollRef).addEventListener('scroll', this.handleScroll);
    }
  },
  beforeUnmount() {
    if (!this.activeId) {
      this.$parent.$refs[this.scrollRef].removeEventListener('scroll', this.handleScroll);
    } else {
      document.getElementById(this.scrollRef).removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

  <style lang="scss" scoped>
  .arrows-wrapper {
    bottom: 0;
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: 2fr 2fr;
    font-size: 20px;
    height: 64px;
    padding: 8px;
    position: absolute;
    width: 100%;
    background-color: var(--background-3);
    &.three {
      grid-template-columns: 1fr 2fr 2fr;
    }
    button {
      align-items: center;
      border-radius: 2px;
      display: flex;
      height: 100%;
      justify-content: center;
      background-color: var(--background-light);
      color: var(--text-primary-1);
      border: 0;
    }
  }
  </style>
