<template>
  <div class="header">
    <div class="product-title">
      <span>{{ showTicketType }} {{ productName }}</span>
    </div>
    <div class="ticket-id">
      <span>
        {{ `${translations.general_ticket} ID: `}}
      </span>
      <span :class="{ 'scale': isClicked }">
        {{ ticket.id }}
      </span>
      <i class="icon icon-copy"
         @click="copyTicketIdToClipboard(ticket.id)" />
    </div>
    <div class="separator">|</div>
    <div class="ticket-date">
      <span>{{ getDateTime(ticket.createdAt) }}</span>
    </div>
  </div>
</template>

<script>
import {
  startCase,
  isNil,
  toLower,
  upperFirst,
} from 'lodash';
import { mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';

export default {
  name: 'LastTicketsDetailsHeader',
  data() {
    return {
      isClicked: false,
    };
  },
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    productNameLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      config: 'config',
      translations: 'translations',
    }),
    productName() {
      if (this.productNameLabel) {
        return this.productNameLabel;
      }
      if (this.config.productNameLabel) {
        return this.translations[this.config.productNameLabel];
      }
      return startCase(this.config.productName);
    },
    showTicketType() {
      if (!isNil(this.ticket.ticketType)) return `${upperFirst(toLower(this.ticket.ticketType))} ${toLower(this.translations.general_ticket)} |`;

      return null;
    },
  },
  methods: {
    copyTicketIdToClipboard(ticketId) {
      if (this.isClicked) return;
      this.isClicked = true;
      const input = document.createElement('input');
      input.value = ticketId;
      input.style.opacity = '0';
      input.inputMode = 'none';
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      setTimeout(() => {
        this.isClicked = false;
      }, 500);
    },
    getDateTime(value) {
      return format(parseISO(value), 'dd.MM.yyyy HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes scaleText {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.header {
  .product-title {
    text-align: left;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;
    color: var(--text-primary-1, #efefef);
  }

  .separator {
    float: left;
    margin: 0 5px;
    color: var(--text-primary-2, rgba(239, 239, 239, 0.6));
  }

  .ticket-id {
    display: flex;
    align-items: center;
  }

  .ticket-id,
  .ticket-date {
    line-height: 21px;
    float: left;
    color: var(--text-primary-2, rgba(239, 239, 239, 0.6));
    font-size: 14px;
    margin-bottom: 2px;
    display: flex;
    gap: 3px;

    .scale {
      animation-name: scaleText;
      animation-duration: 0.6s;
      transition: transform 0.6s cubic-bezier(0.32, 0, 0.67, 0);
    }
  }

  .icon-copy {
    cursor: pointer;
  }
}
@media (max-width: 660px) {
  .header {
    .product-title {
      font-size: 14px;
      line-height: 1.17;
    }

    .ticket-id,
    .ticket-date {
      font-size: 12px;
      line-height: 1.17;
    }
  }
}
</style>
