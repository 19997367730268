<template>
  <div class="betslip-input-wrapper">
    <label class="betslip-label">{{ label }}</label>
    <input class="betslip-input"
           type="float"
           min="String(minPaymentPerBet)"
           @keydown="handleKeyDown"
           @keyup="handleKeyUp"
           @paste="handlePaste"
           @focus="handleFocus"
           @change="handleChange"
           @click="handleClick"
           v-model="value"
           ref="input"
           inputmode="none" />
    <i v-if="showDelete"
       class="icon icon-backspace-a"
       @click="handleDelete" />
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { each } from 'lodash';
import GamesBetslipInput from '@/components/BetslipArea/GamesBetslipInput';
import eventBus from '../../utility/eventBus';

export default {
  name: 'GamesBetslipInput',
  extends: GamesBetslipInput,
  computed: {
    ...mapGetters({
      ticket: 'betslip/tickets',
      minPaymentPerBet: 'betslip/minPaymentPerBet',
      paymentPerBet: 'betslip/paymentPerBet',
      rules: 'rules',
    }),
  },
  methods: {
    ...mapActions({
      updateStake: 'betslip/updateStake',
      setPaymentPerBetValue: 'betslip/setPaymentPerBetValue',
      setResetStakeAfterBetRemove: 'betslip/setResetStakeAfterBetRemove',
      clearNotification: 'notifications/clearNotification',
    }),
    handleDelete() {
      this.$emit('delete');
      this.clearNotification();
    },
    isStakeEqual(ticket) {
      const ticketStakes = [];
      each(ticket, (bet) => {
        ticketStakes.push(bet.payment);
      });
      const isEqual = ticketStakes.every(stake => stake === ticketStakes[0]);
      return isEqual;
    },
    areValidBets(ticket) {
      const validStakes = [];
      const unvalidStakes = [];
      each(ticket, (bet) => {
        if (bet.payment >= bet.minPayment) {
          validStakes.push(true);
        }
        if (bet.payment < bet.minPayment || bet.payment > this.rules.maxBetAmount.value) {
          unvalidStakes.push(true);
        }
      });
      return validStakes.length > 0 && unvalidStakes.length === 0;
    },
  },
  watch: {
    parentValue(value) {
      if (String(value) === 'null') {
        this.value = this.formatNumber(this.minPaymentPerBet);
      } else {
        this.value = this.formatNumber(this.validateValue(value));
      }
    },
    ticket(value) {
      if (value.length === 0) {
        this.setPaymentPerBetValue(this.minPaymentPerBet);
        this.value = this.formatNumber(this.validateValue(this.minPaymentPerBet));
      }
      if (value.length > 0) {
        if (this.areValidBets(value)) {
          this.clearNotification();
        }
      }
      if (value.length > 1) {
        if (!this.isStakeEqual(value)) {
          eventBus.$emit('HideStakeBet');
        }
      }
    },
  },
  mounted() {
    this.setResetStakeAfterBetRemove(false);
    if (this.ticket.length === 0) {
      this.setPaymentPerBetValue(this.minPaymentPerBet);
      this.value = this.formatNumber(this.validateValue(this.minPaymentPerBet));
    }
    this.value = this.formatNumber(this.validateValue(this.parentValue));
    this.$root.$on('StakeModified', () => {
      this.updateStake(this.minPaymentPerBet).then(() => {
        this.value = '';
      });
    });
    eventBus.$on('StakeModified', () => {
      this.updateStake(this.minPaymentPerBet).then(() => {
        this.value = '';
      });
    });
    eventBus.$on('HideStakeBet', () => {
      this.value = '';
    });
    this.$root.$on('TicketRebet', () => {
      if (this.isStakeEqual(this.ticket)) {
        this.updateStake(this.ticket[0].payment).then(() => {
          this.value = this.ticket[0].payment.toFixed(2);
        });
      } else {
        this.updateStake(this.ticket[0].payment).then(() => {
          this.value = '';
        });
      }
    });
    eventBus.$on('TicketRebet', () => {
      if (this.isStakeEqual(this.ticket)) {
        this.updateStake(this.ticket[0].payment).then(() => {
          this.value = this.ticket[0].payment.toFixed(2);
        });
      } else {
        this.updateStake(this.ticket[0].payment).then(() => {
          this.value = '';
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.betslip-input-wrapper {
  width: 100%;
  position: relative;

  .betslip-label {
    position: absolute;
    top: 5px;
    left: 10px;
    color: var(--text-primary-2);
    font-size: 12px;
  }

  .betslip-input {
    font-family: 'Roboto';
    font-size: 14px;
    color: var(--text-primary-1);
    background-color: var(--input);
    border: 1px solid var(--input-border-color);
    width: 100%;
    height: 40px;
    padding: 15px 0 0 10px;
    //border: 0;
    border-radius: 2px;
    outline: none;
  }

  .icon {
    position: absolute;
    right: 8px;
    top: calc((40px / 2) - 6px);
    cursor: pointer;
    color: var(--text-primary-1);
  }
}
</style>
