<template>
  <div>
    <Transition name="notification">
        <div
          class="betslipNotification"
          v-if="isNotificationActive"
          :style="{ '--footerHeight': footerHeight }"
        >
          <Notification v-if="isA" />
        </div>
    </Transition>
    <div
      class="comboFooterWrapper"
      :class="isA ? 'comboFooterWrapperA' : 'comboFooterWrapperB'"
      ref="footer">
      <BetslipDeleteButton v-if="!isA" class="deleteButtonB" />
      <BetslipInput v-if="isA"
        class="totalPayment"
        @change="handleTotalPaymentChange"
        @fieldFocus="setActiveTotalStakeInput"
        :label="translations.general_total_stake"
        labelPosition="right"
        :parentValue="totalPayment"
      />
      <BetslipPossibleWinCombo />
      <div v-if="isA" class="footer">
        <BetslipDeleteButton class="deleteButton" />
        <BetslipButton class="payinButton" :disabled="isPayinDisabled" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import BetslipButton from './BetslipButton';
import BetslipDeleteButton from './BetslipDeleteButton';
import BetslipPossibleWinCombo from './BetslipPossibleWinCombo';
import Notification from '../../Notifications/Notification';

export default {
  name: 'BetslipFooterCombo',
  props: {
    isA: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      stakeBoxTimeoutId: null,
    };
  },
  components: {
    BetslipInput,
    BetslipButton,
    BetslipDeleteButton,
    BetslipPossibleWinCombo,
    Notification,
  },
  computed: {
    ...mapGetters('gamesBetslip', [
      'totalPayment',
      'isPayinButtonDisabled',
      'isPayinInProgress',
      'isTotalPaymentValid',
      'isUserBalanceInvalid',
      'numberOfBets',
    ]),
    ...mapGetters('notifications', ['isNotificationActive']),
    ...mapGetters(['translations']),
    isPayinDisabled() {
      return this.isPayinButtonDisabled
        || this.numberOfBets <= 0
        || this.isPayinInProgress
        || !this.isTotalPaymentValid
        || this.isUserBalanceInvalid;
    },
    footerHeight() {
      return `${this.$refs.footer?.clientHeight}px`;
    },
    footerHeightWithoutUnit() {
      return this.$refs.footer?.clientHeight;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'setTotalPayment',
      'setActiveBetslipInput',
      'setIsStakeBoxVisible',
    ]),
    handleTotalPaymentChange(totalPayment) {
      if (Number(totalPayment) === Number(this.totalPayment)) return;

      this.setTotalPayment(totalPayment);
    },
    openStakeBox() {
      if (this.stakeBoxTimeoutId) clearTimeout(this.stakeBoxTimeoutId);

      this.stakeBoxTimeoutId = setTimeout(() => {
        this.setIsStakeBoxVisible(true);
      }, 200);
    },
    setActiveTotalStakeInput() {
      this.openStakeBox();
      this.setActiveBetslipInput('_totalPayment');
    },
  },
  mounted() {
    document.body.style.setProperty('--footer-combo-height', this.footerHeightWithoutUnit);
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    clearTimeout(this.stakeBoxTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.stakeBoxTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
.comboFooterWrapper {
  background-color: var(--background-2);
  padding: 8px;
  position: relative;
  z-index: 98;

  &.comboFooterWrapperA {
    height: auto;
  }

  &.comboFooterWrapperB {
    height: auto;
  }

  .deleteButtonB {
    width: 100%;
  }

  .footer {
    display: flex;
    gap: 8px;

    .deleteButton {
      width: 66px;
    }

    .payinButton {
      width: 210px;
    }
  }
}

.betslipNotification {
  position: absolute;
  width: 100%;
  bottom: var(--footerHeight);
  z-index: 10;
}

.notification-enter-active {
  animation-name: notificationSlideUp;
  animation-duration: 0.33s;
  transition-timing-function: ease-out;
}

.notification-leave-active {
  animation-name: notificationSlideDown;
  animation-duration: 0.33s;
  transition-timing-function: ease-out;
}

@keyframes notificationSlideUp {
  from {
    bottom: calc(var(--footerHeight) - 40px);
  }

  to {
    bottom: var(--footerHeight);
  }
}

@keyframes notificationSlideDown {
   from {
    bottom: var(--footerHeight);
  }

  to {
    bottom: calc(var(--footerHeight) - 40px);
  }
}
</style>
