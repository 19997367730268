<template>
  <div class="betslip-header"
       ref="betslipheader">
    <div class="title">{{ betslipLabel }}</div>
    <div
      class="counter"
      v-if="showBetsCounter">
      <span>{{ betsCounter }}</span>
    </div>
    <div class="minimize-betslip" v-if="showMinimizeButton"
      @click="minimizeBetslip">
      <i class="icon icon-arrow-right">
      </i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipHeader',
  props: {
    numberOfBets: {
      type: Boolean,
      default: true,
    },
    betsCounter: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      'translations',
    ]),
    betslipLabel() {
      return this.translations.general_betslip.toUpperCase();
    },
    showBetsCounter() {
      return this.numberOfBets && this.betsCounter > 0;
    },
    showMinimizeButton() {
      return window.innerWidth <= 1024;
    },
  },
  methods: {
    minimizeBetslip() {
      this.$root.$emit('MinimizeBetslip');
      eventBus.$emit('MinimizeBetslip');
    },
  },
};
</script>

<style scoped lang="scss">
.betslip-header {
  display: flex;
  background-color: var(--button);
  height: 72px;
  padding-left: 16px;

  .title, .counter {
    align-self: center;
  }

  .title {
    align-self: center;
    font-size: 24px;
    color: var(--text-primary-1);
  }
  .counter {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: var(--secondary);
    border-radius: 2px;
    color: var(--text-secondary-1);
    font-weight: bold;

    span {
      font-size: 18px;
    }
  }
  .minimize-betslip {
    text-align: right;
    padding-right: 10px;
    height: 72px;
    line-height: 72px;
    width: 100%;
    color: var(--text-primary-1);
    font-size: 24px;
    cursor: pointer;
  }
}
</style>
