<template>
    <table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.value">{{ header.text }}</th>
        </tr>
      </thead>
      <tbody>
        <div ref="body-items-wrapper" class="body-items-wrapper">
          <div class="scroll">
            <div
              v-for="item in listItems"
              :key="item.ticketId"
              class="row"
              :class="{ expanded: item.expanded }"
            >
              <div class="list-items-wrapper" @click="setActiveItem(item.id)">
                <div class="cell first-cell">
                  <i class="icon" :class="icon(item.id)"></i>
                  <div class="ticket-id">
                    {{ item.ticketId }}
                  </div>
                </div>
                <div class="cell">{{ item.ticketDateTime }}</div>
                <div class="cell">{{ item.payin }}</div>
                <div class="cell">{{ item.status }}</div>
              </div>
              <div
                v-if="item.expanded"
                class="button-wrapper"
                @click.self="setActiveItem(item.ticket)">
                <button
                  @click="printCopy(item.ticketId)"
                  :disabled="item.disabled"
                  :class="{disabled: item.disabled}"
                >
                  {{ translations.general_print_copy }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <TerminalScrollArrows
          v-if="showArrows"
          :toTopButton="true"
          scroll-ref="body-items-wrapper" />
      </tbody>
    </table>
  </template>
<script>
import { mapGetters } from 'vuex';
import { eventBus } from '../utility';
import TerminalScrollArrows from './TerminalScrollArrows';

export default {
  name: 'TerminalTicketHistory',
  components: {
    TerminalScrollArrows,
  },
  props: {
    headers: {
      type: Array,
      default: () => [
        {
          text: 'Ticket id',
          value: 'ticketId',
        },
        {
          text: 'Date and time',
          value: 'ticketDateTime',
        },
        {
          text: 'Payin',
          value: 'payin',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
    },
    items: {
      type: Array,
      default: () => [],
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listItems: [],
    };
  },
  computed: {
    ...mapGetters(['translations']),
  },
  mounted() {
    this.prepItems();
  },
  methods: {
    icon(index) {
      return this.listItems[index].expanded ? 'n-i-void' : 'n-i-more-sports';
    },
    prepItems() {
      this.listItems = this.items.map(item => ({
        ...item,
        expanded: false,
      }));
    },
    printCopy(ticketId) {
      eventBus.$emit('printCopy', ticketId);
    },
    setActiveItem(index) {
      this.listItems = this.listItems.map((item, i) => ({
        ...item,
        expanded: i === index ? !item.expanded : false,
      }));
    },
  },
};
</script>
  <style lang="scss" scoped>
  table {
    width: 100%;
    height: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  th {
    padding: 1.5rem 1.5rem 0.625rem;
    color: var(--text-primary-1);
    font-size: 0.75rem;
    text-transform: uppercase;
    word-break: break-word;
    text-align: start;
    height: 52px;
    line-height: 18px;
  }
  .row {
    background-color: var(--background-3);
    &:not(:last-child) {
      border-bottom: 1px solid var(--background-light);
    }
  }
  .list-items-wrapper,
  tr {
    width: 100%;
    display: flex;
    :nth-child(1) {
      flex: 2.8; // 1.4
    }
    :nth-child(2) {
      flex: 3.22; // 1.61
    }
    :nth-child(3) {
      flex: 1.28; // 0.64
    }
    :nth-child(4) {
      flex: 1.4; // 0.7
    }
    .cell {
      text-align: start;
      padding: 0 1.5rem 0 1.5rem;
      height: 3rem;
      color: var(--text-primary-1);
      display: flex;
      align-items: center;
    }
  }
  .icon {
    margin-right: 1rem;
    max-width: 18px;
    &::before {
      display: inline-block;
      height: 18px;
      width: 18px;
      font-size: 18px;
    }
  }

  .expanded {
    &.row {
      border: 2px solid var(--secondary);
    }
    .button-wrapper {
      padding: 14px 24px 14px 60px;
      button {
        padding: 12px 24px;
        font-size: 1rem;
        font-weight: 500;
        height: 48px;
        background-color: var(--primary);
        color: var(--text-primary-1);
        border: 0;
        cursor: pointer;
        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }
  tbody {
    display: block;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    padding-bottom: 64px;
    position: relative;
    .body-items-wrapper {
      height: 100%;
      width: 100%;
      padding: 0px;
      position: relative;
    }
    > * {
      overflow: auto;
      scrollbar-width: none;
      scrollbar-color: var(--text-primary-3) var(--card);
    }
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: var(--card);
    }
    ::-webkit-scrollbar-thumb {
      background: var(--button);
      border-radius: 3px;
    }
  }
  </style>
